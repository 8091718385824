import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import FoodCategory from '../api/services/FoodCategory';
import CheckBoxSelect from './forms/CheckBoxSelect';

const ProfileForm9 = () => {
  const navigate = useNavigate();

  const [foodCategoryIds, setFoodCategoryIds] = useState([]);
  const [foodCategoryOptions, setFoodCategoryOptions] = useState([]);

  const handleClick = (e) => {
    const data = JSON.parse(localStorage.getItem('register_data'));
    data.food_category_ids = foodCategoryIds;
    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/ProfileFormPremiumPlan');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setFoodCategoryIds(data.food_category_ids ?? []);
    }
    loadAllergies();
  }, []);

  const onSelectionChange = (selection) => {
    setFoodCategoryIds(selection);
  };

  async function loadAllergies() {
    try {
      const response = await FoodCategory.list();
      var options = [];
      response.data.forEach((foodCategory) => {
        options.push({
          value: foodCategory.id,
          label: foodCategory.name,
          param: foodCategory.api_param,
        });
      });
      setFoodCategoryOptions(options);
    } catch (error) {
      console.error(error);
    }
  }

  return foodCategoryOptions.length > 0 ? (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col bg-red items-center gap-3">
          <Toolbar to={'/profileForm7'} replace={true}>
            <span>Paso 4</span> <span className="text-primary">de 4</span>
          </Toolbar>
          <h2 className="w-full text-white text-xl font-semibold text-center">
            ¿Qué cocinas te interesan más?
          </h2>
          <p className="text-center text-body mb-3 mt-2">
            Esto nos ayudará a seleccionar más experiencias de recetas para ti.
            ¿Cuáles son las que más te interesan?
          </p>
          <CheckBoxSelect
            name="allergies_ids"
            onChange={onSelectionChange}
            selection={foodCategoryIds}
            options={foodCategoryOptions}
          />
          <FooterToolbar>
            <PrimaryButton type="button" onClick={handleClick}>
              Siguiente
            </PrimaryButton>
          </FooterToolbar>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-screen">
      <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
    </div>
  );
};

export default ProfileForm9;
