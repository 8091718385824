import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import PaymentInputField from './PaymentInputField';
import CheckIcon from './checkIcon';
import TickIcon from '../img/tick.svg';

const ProfileForm28 = () => {
  const [plan, setPlan] = useState();
  const navigate = useNavigate();
  const [cardDetails, setCardDetails] = useState({
    cardNumber: '',
    ccv: '',
    date: '',
  });
  const [promotionalCode, setPromotionalCode] = useState('');
  const [codeIsValid, setCodeIsValid] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const { cardNumber, ccv, date } = cardDetails;

  const handleValidateCoupon = () => {
    if (promotionalCode === 'MARIA-25') {
      setCodeIsValid(true);
      setPercentage(20);
    }
  };

  const handleClick = (e) => {
    navigate('/signUpConfirm');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setPlan(JSON.parse(localStorage.getItem('plan')));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Limit card number to 16 digits
    if (name === 'cardNumber' && value.length > 16) return;

    // Limit ccv to 3 digits
    if (name === 'ccv' && value.length > 3) return;

    // Limit date to 4 digits
    if (name === 'date' && value.length > 4) return;

    // Update state
    setCardDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const checkCardFilled = () => {
    return cardNumber.length === 16 && ccv.length === 3 && date.length === 4;
  };

  return (
    <div className="relative min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={'/ProfileFormPremiumPlan'} replace={true}>
              <div className="text-sm capitalize">
                <span>Plan {plan?.type}</span>
              </div>
            </Toolbar>

            <div className="w-full flex flex-col gap-4">
              <div className="flex flex-col bg-buddy-bg-2 text-white p-4 rounded-2xl gap-4">
                <div className="flex justify-between items-center">
                  <div className="text-primary font-medium">
                    Plan {plan?.type}
                  </div>
                  <div className="capitalize text-sm">
                    {plan?.price}€
                    <span className="text-body">/{plan?.type}</span>
                  </div>
                </div>
                <div className="border border-buddy-bg-3" />
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <CheckIcon className="w-6 h-6" />
                    <div>Experiencia sin publicidad</div>
                  </div>
                  <div className="flex items-center gap-2">
                    <CheckIcon className="w-6 h-6" />
                    <div>Soporte prioritario 24/7</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col gap-4">
              <div className="flex flex-col bg-buddy-bg-2 text-white p-4 rounded-2xl gap-4">
                <div className="flex justify-between items-center">
                  <p className="font-medium text-white text-xl">
                    Agregar tarjeta de pago
                  </p>
                </div>
                <div className="flex flex-col gap-4">
                  <PaymentInputField
                    label="Número de tarjeta"
                    value={cardNumber}
                    placeHolder="Número de tarjeta"
                    name="cardNumber"
                    onChange={handleChange}
                  />
                  <div className="flex w-full gap-4">
                    <div className="flex-1 min-w-0">
                      <PaymentInputField
                        label="Código CVV"
                        value={ccv}
                        placeHolder="CCV"
                        name="ccv"
                        maxLength="3"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <PaymentInputField
                        label="Fecha de expiración"
                        value={date}
                        placeHolder="MM/AA"
                        name="date"
                        maxLength="4"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col gap-4">
              <div className="flex flex-col bg-buddy-bg-2 text-white p-4 rounded-2xl gap-4">
                <p className="font-bold text-white text-center">
                  ¿Tienes un código descuento?
                </p>
                <div className="flex justify-between gap-6">
                  <div className="flex flex-col gap-4 text-xs font-medium">
                    <PaymentInputField
                      label="Código promocional"
                      value={promotionalCode}
                      type="text"
                      onChange={(e) => setPromotionalCode(e.target.value)}
                    />
                    <PrimaryButton
                      className="rounded-xl bg-primary text-white h-8"
                      type="button"
                      onClick={handleValidateCoupon}
                    >
                      Validar código
                    </PrimaryButton>
                  </div>
                  {codeIsValid && (
                    <div className="flex flex-col items-center w-full gap-4">
                      <div className="flex items-center gap-4">
                        <img
                          className="w-4 h-4"
                          src={TickIcon}
                          alt="tick-icon"
                        />
                        <div>
                          ¡El código {promotionalCode} se añadió con exito!
                        </div>
                      </div>
                      <div>
                        Este código te proporciona un {percentage}% de descuento
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <FooterToolbar>
              <PrimaryButton
                type="button"
                onClick={handleClick}
                disabled={!checkCardFilled()}
              >
                Pagar
              </PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm28;
