import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SecondaryToolbar from './ui/SecondaryToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Input from './forms/Input';
import FooterToolbar from './ui/FooterToolbar';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import PasswordWithValidationInput from './forms/PasswordWithValidationInput';
import passwordChangedImage from '../img/password_changed.png';
import MeasureInput from './forms/MeasureInput';
import ResetPasswordBuddy from '../img/buddy-reset-password.png';
import ConfirmationAction from './ConfirmationAction.js';
import Auth from '../api/services/Auth.js';

const RESEND_CODE_TIMEOUT = 60;

const ResetPassword = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [code1, setCode1] = useState('');
  const [code2, setCode2] = useState('');
  const [code3, setCode3] = useState('');
  const [code4, setCode4] = useState('');
  const [timer, setTimer] = useState(RESEND_CODE_TIMEOUT);

  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const handleSendMail = async () => {
    try {
      await Auth.forgotPassword({ email });
      setCurrentStep(2);
      setTimeout(() => {
        const el = document.querySelector('input[name="code1"]');
        if (el) {
          el.focus();
        }
      }, 200);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await Auth.resetForgotPassword({ email: email });
      if (response.status === 200) {
        setCurrentStep(2);
      }
    } catch (error) {
      console.error('Error reseting password:', error);
    }
  };

  const handleCheckCode = () => {
    if (isCodeComplete()) {
      setCurrentStep(3);
    }
  };

  const handleChangePassword = async () => {
    if (isPasswordValid && newPassword === verifyPassword) {
      try {
        const code = `${code1}${code2}${code3}${code4}`;
        await Auth.updatePassword({ code, password: newPassword, email });
        setCurrentStep(4);
      } catch (error) {
        console.error('Error changing password:', error);
        setCurrentStep(5);
      }
    }
  };

  const isCodeComplete = () => {
    return [code1, code2, code3, code4].every((code) => code.length === 1);
  };

  const handleGoToLogin = () => {
    navigate('/login');
  };

  const handleOnChangeCode1 = (value) => {
    setCode1(value);
    if (value && value.length === 1) {
      const nextInput = document.querySelector('input[name="code2"]');
      if (nextInput) nextInput.focus();
    }
  };

  const handleOnChangeCode2 = (value) => {
    setCode2(value);
    if (value && value.length === 1) {
      const nextInput = document.querySelector('input[name="code3"]');
      if (nextInput) nextInput.focus();
    }
  };

  const handleOnChangeCode3 = (value) => {
    setCode3(value);
    if (value && value.length === 1) {
      const nextInput = document.querySelector('input[name="code4"]');
      if (nextInput) nextInput.focus();
    }
  };

  const handleOnChangeCode4 = (value) => {
    setCode4(value);
  };

  useEffect(() => {
    if (currentStep === 2) {
      setTimer(RESEND_CODE_TIMEOUT);
      const interval = setInterval(() => {
        setTimer((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [currentStep]);

  return (
    <div className="h-full bg-no-repeat bg-cover bg-center overflow-scroll">
      {currentStep === 1 && (
        <div className="h-full flex justify-between flex-col min-h-screen bg-secondary-bg bg-opacity-60 p-6">
          <SecondaryToolbar to="/login"></SecondaryToolbar>
          <form className="w-full">
            <div className="flex flex-col bg-red items-center gap-6">
              <h2 className="w-full text-center text-white text-2xl font-bold">
                Restablecer contraseña
              </h2>
              <p className="text-buddy-text-4 font-medium text-center">
                ¡No te preocupes! Introduce tu correo electrónico y te
                enviaremos un código para restablecer la contraseña.
              </p>
              <Input
                name="email"
                type="email"
                placeholder=""
                icon={faEnvelope}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Input>
              <p className="text-buddy-text-4 font-medium text-center">
                ¡Ojo! Puede que el correo haya llegado a tu carpeta de SPAM.
                ¡Revísala por si acaso!
              </p>
            </div>
          </form>
          <FooterToolbar>
            <PrimaryButton type="button" onClick={handleSendMail}>
              Restablecer Contraseña
            </PrimaryButton>
          </FooterToolbar>
        </div>
      )}
      {currentStep === 2 && (
        <div className="h-full flex flex-col min-h-screen bg-secondary-bg bg-opacity-60 p-6">
          <SecondaryToolbar to="/login"></SecondaryToolbar>
          <form className="w-full mt-16">
            <div className="flex flex-col bg-red items-center gap-4">
              <h2 className="w-full text-center text-white text-2xl font-bold">
                Revisa tu correo electrónico
              </h2>
              <p className="text-buddy-text-4 font-medium text-center">
                Hemos enviado el código a tu correo electrónico.
              </p>
              <h2 className="w-full text-white text-base font-medium mt-8">
                Introduce el código
              </h2>
              <div className="flex justify-evenly w-100 gap-3 mb-5">
                <div className="w-[74px] h-[72px]">
                  <MeasureInput
                    id="code1"
                    value={code1}
                    name="code1"
                    type="number"
                    onChange={handleOnChangeCode1}
                  />
                </div>
                <div className="w-[74px] h-[72px]">
                  <MeasureInput
                    id="code2"
                    value={code2}
                    name="code2"
                    type="number"
                    onChange={handleOnChangeCode2}
                  />
                </div>
                <div className="w-[74px] h-[72px]">
                  <MeasureInput
                    id="code3"
                    value={code3}
                    name="code3"
                    type="number"
                    onChange={handleOnChangeCode3}
                  />
                </div>
                <div className="w-[74px] h-[72px]">
                  <MeasureInput
                    id="code4"
                    value={code4}
                    name="code4"
                    type="number"
                    onChange={handleOnChangeCode4}
                  />
                </div>
              </div>
              <p className="text-buddy-text-4 font-medium text-center">
                En caso de que no te haya llegado el correo electrónico solicita
                un nuevo código.
              </p>
              <div className="flex flex-col gap-4">
                <p className="text-white text-center font-medium text-lg">
                  ¿No recibiste el correo electrónico?
                </p>
                {timer !== 0 && (
                  <p className="text-white text-center font-medium text-lg">
                    Puedes reenviar el código en{' '}
                    <span className="text-primary">{timer}</span> s
                  </p>
                )}
              </div>
            </div>
          </form>
          <FooterToolbar>
            <div className="flex flex-col gap-3 mt-8">
              {timer === 0 && (
                <PrimaryButton
                  type="button"
                  onClick={() => {
                    handleResetPassword();
                    setTimer(RESEND_CODE_TIMEOUT);
                  }}
                >
                  Reenviar código
                </PrimaryButton>
              )}
              <PrimaryButton
                type="button"
                onClick={handleCheckCode}
                disabled={!isCodeComplete()}
              >
                Restablecer contraseña
              </PrimaryButton>
            </div>
          </FooterToolbar>
        </div>
      )}
      {currentStep === 3 && (
        <div className="h-full flex justify-between flex-col min-h-screen bg-secondary-bg bg-opacity-60 p-6">
          <SecondaryToolbar to="/login"></SecondaryToolbar>
          <form className="w-full">
            <div className="flex flex-col bg-red items-center gap-3">
              <h2 className="w-full text-center text-white text-2xl font-bold">
                No compartas tu contraseña con nadie
              </h2>
              <p className="text-buddy-text-4 font-medium text-center mb-5">
                Por favor introduzca su nueva contraseña.
              </p>
              <PasswordWithValidationInput
                name="password"
                label="Introduce tu nueva contraseña"
                placeholder=""
                onValidityChange={setIsPasswordValid}
                onChange={setNewPassword}
              />
              <div className="w-full -mt-4">
                <Input
                  name="password_repeat"
                  type="password"
                  label="Repita su nueva contraseña"
                  placeholder=""
                  value={verifyPassword}
                  onChange={(e) => setVerifyPassword(e.target.value)}
                />
              </div>
            </div>
          </form>
          <FooterToolbar>
            <PrimaryButton
              type="button"
              onClick={handleChangePassword}
              disabled={!isPasswordValid || newPassword !== verifyPassword}
            >
              Cambiar contraseña
            </PrimaryButton>
          </FooterToolbar>
        </div>
      )}
      {currentStep === 4 && (
        <ConfirmationAction
          title="¡ Contraseña cambiada !"
          description="Tu contraseña ha sido cambiada exitosamente"
          buttonText="Iniciar sesión"
          handleClick={handleGoToLogin}
          imageUrl={ResetPasswordBuddy}
        />
      )}
      {currentStep === 5 && (
        <div className="h-full flex justify-between flex-col min-h-screen bg-secondary-bg bg-opacity-60 p-6">
          <SecondaryToolbar to="/login"></SecondaryToolbar>
          <form className="w-full">
            <div className="flex flex-col bg-red items-center gap-3">
              <img
                style={{ width: '200px' }}
                src={passwordChangedImage}
                alt="Password Changed"
              />
              <h2 className="w-full text-center text-white text-2xl font-semibold">
                ERROR
              </h2>
              <p className="text-buddy-text-3 text-center font-semibold">
                Ha ocurrido un error al resetear la contraseña, vuelve a
                intentarlo
              </p>
            </div>
          </form>
          <FooterToolbar>
            <PrimaryButton type="button" onClick={handleGoToLogin}>
              Iniciar sesión
            </PrimaryButton>
          </FooterToolbar>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
