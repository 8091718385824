import React from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SelectRounded = ({
  name,
  value,
  label = '',
  includeBorder = true,
  action=false,
  size='medium',
  placeholder,
  placeholderValue = '',
  onChange = null,
  disabled = false,
  options = [],
}) => {
  return (
    <div>
      {label != null ? (
        <p className="text-white font-semibold text-sm mb-2" htmlFor="email">
          {label}
        </p>
      ) : (
        ''
      )}

      <div className={`w-full relative  ${includeBorder && "ring-2 ring-white"} rounded-lg`}>
        <select
          disabled={disabled}
          name={name}
          onChange={onChange}
          value={value}
          className={`
            w-full h-11 text-sm 
            ${action ? "bg-primary-button-bg" : 'bg-secondary-bg'} 
            ${size === 'small' ? 'h-8' : 'h-11'}
            text-white text-center rounded-lg ps-1 pe-6 appearance-none`
          }
        >
          {placeholder ? (
            <option value={placeholderValue}>{placeholder}</option>
          ) : (
            ''
          )}
          {options.map((option, idx) => {
            const { value, label } = option;
            return (
              <option type="button" key={idx} value={value}>
                {label}
              </option>
            );
          })}
        </select>
        <div className={`absolute 
          ${size === 'small' ? 'top-[2px]': 'top-2'} 
          rig right-2 pointer-events-none`}>
          <FontAwesomeIcon
            className="text-white pointer-events-none size-3"
            icon={faChevronDown}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectRounded;
