import React, { useEffect, useState } from "react";
import Toolbar from "./ui/Toolbar";
import FooterToolbar from "./ui/FooterToolbar";
import PrimaryButton from "./ui/PrimaryButton";
import clsx from "clsx";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";

const ProfileForm28 = () => {
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [registerData, setRegisterData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const storedData = localStorage.getItem("register_data");
    if (storedData) {
      setRegisterData(JSON.parse(storedData));
    } else {
      alert("No user data found. Please complete registration.");
    }
  }, []);

  const handlePlan = (selectedPlan) => {
    setPlan(selectedPlan);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!registerData || !plan) {
      alert("Error: Missing registration data or plan selection.");
      setLoading(false);
      return;
    }

    try {
      const requestData = {
        ...registerData,
        plan: plan.type,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}create-checkout-session`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestData),
        }
      );

      const data = await response.json();
      console.log("API Response:", data);
      setLoading(false);

      if (data.url) {
        if (Capacitor.isPluginAvailable("Browser")) {
          await Browser.open({ url: data.url, presentationStyle: "popover" });
        }
      } else {
        alert("Error: " + data.error);
      }
    } catch (error) {
      console.error("API Error:", error);
      alert("An unexpected error occurred. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col justify-between gap-3">
          <Toolbar to={"/profileForm9"} replace={true}>
            <div className="text-sm">
              <span>Elegir plan de BuddyMarket</span>
            </div>
          </Toolbar>

          <div>
            <div className="w-full flex flex-col gap-4">
              {[
                {
                  type: "mensual",
                  price: "5,99",
                  description: "Después 5,99 € / mes",
                },
                {
                  type: "trimestral",
                  price: "17,97",
                  description: "Después 17,97 € / trimestre",
                  discount: "Ahorra 10%",
                },
                {
                  type: "anual",
                  price: "71,88",
                  description: "Después 71,88 € / año",
                  discount: "Ahorra 20%",
                },
              ].map((p) => (
                <div
                  key={p.type}
                  className={clsx(
                    "w-full flex flex-col justify-between bg-buddy-bg-2 rounded-2xl text-white p-4 gap-2 cursor-pointer border-2",
                    {
                      "border-primary": plan?.type === p.type,
                      "border-transparent": plan?.type !== p.type,
                    }
                  )}
                  onClick={() => handlePlan({ type: p.type, price: p.price })}
                >
                  <div className="text-primary font-medium">
                    {p.type.charAt(0).toUpperCase() + p.type.slice(1)}
                  </div>
                  <div className="flex justify-between font-normal">
                    <div>7 días de prueba</div>
                    {p.discount && (
                      <div className="bg-primary px-2 py-1 rounded">
                        {p.discount}
                      </div>
                    )}
                  </div>
                  <div className="text-body text-sm">{p.description}</div>
                </div>
              ))}
            </div>

            <form onSubmit={handleSubmit}>
              <input type="hidden" name="plan" value={plan?.type} readOnly />
              <FooterToolbar>
                <PrimaryButton
                  type="submit"
                  disabled={!plan?.type || loading || !registerData}
                >
                  {loading ? "Redirigiendo..." : "Subscribirse"}
                </PrimaryButton>
              </FooterToolbar>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm28;
