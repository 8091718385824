import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import Recipe from '../api/services/Recipe.js';
import RecipeTile from './ui/RecipeTile.js';
import Card from './ui/Card.js';
import Toolbar from './ui/Toolbar.js';

const LIMIT = 5;

const RecomendedRecipes = () => {
  const [searchParams] = useSearchParams();
  const observerRef = useRef(null);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [recipes, setRecipes] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const search = searchParams.get('search') ?? '';
  const userId = searchParams.get('user_id') ?? '';
  const foodCategoryId = searchParams.get('food_category_id') ?? '';

  const getRecipes = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await Recipe.list({
        search,
        user_id: userId,
        food_category_id: foodCategoryId,
        recomended: true,
        limit: LIMIT,
        offset,
      });

      if (response.data.length === 0 && offset === 0) {
        setNoResults(true);
      }

      setRecipes((prev) => [...prev, ...response.data]); // ⬅️ Acumula recetas
      setOffset((prev) => prev + response.data.length);
      setHasMore(response.data.length === LIMIT);
    } catch (error) {
      console.error('Error fetching recipes:', error);
    }
    setLoading(false);
  }, [search, userId, foodCategoryId, offset, loading, hasMore]);

  useEffect(() => {
    getRecipes();
  }, [getRecipes]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          getRecipes();
        }
      },
      { threshold: 1.0 },
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => observer.disconnect();
  }, [getRecipes]);

  return (
    <div className="h-full bg-no-repeat bg-cover bg-center">
      <div className="h-full bg-secondary-bg bg-opacity-60 p-6">
        <Toolbar to={'/recipes'}>
          <span>Te podría gustar</span>
        </Toolbar>

        {noResults && search.length > 0 && (
          <p className="text-buddy-text-1">No se encontraron recetas.</p>
        )}

        <div className="mx-auto w-full">
          <div className="grid grid-cols-1 gap-3 mt-2">
            {recipes.map((recipe) => (
              <Card key={recipe.id} className="bg-buddy-bg-2">
                <RecipeTile recipe={recipe} to={`/recipe?id=${recipe.id}`} />
              </Card>
            ))}
          </div>
          <div ref={observerRef} className="py-4 text-center">
            {loading ? 'Cargando más...' : hasMore && ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecomendedRecipes;
