import React from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar.js';
import FooterToolbar from './ui/FooterToolbar.js';
import PrimaryButton from './ui/PrimaryButton.js';
import subscriptionImage from '../img/subscription_image.png';
import { useLocation } from 'react-router-dom';

const Suscripcion1 = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const status = query.get('status') || 'success';

  const handleGoToIndex = (e) => {
    window.location.href = 'buddymarket://close';
  };

  return (
    <div className="relative h-full bg-no-repeat bg-cover bg-center overflow-scroll">
      <div className="h-full flex justify-between flex-col min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <Toolbar to={'/sidebarMenu'}>Subscripción BuddyMarket</Toolbar>
        <div
          style={{ backgroundColor: '#314650' }}
          className="flex flex-col bg-red items-center gap-3 py-8 px-4 mt-5 rounded-lg"
        >
          <img
            style={{ width: '200px' }}
            src={subscriptionImage}
            alt="Password Changed"
          />
          {status === 'success' && (
            <h2
              style={{ fontSize: '30px' }}
              className="w-full text-center text-white text-xl font-semibold"
            >
              ¡Enhorabuena! Ya estás suscrito en BuddyMarket!
            </h2>
          )}
          {status === 'cancelled' && (
            <h2
              style={{ fontSize: '30px' }}
              className="w-full text-center text-white text-xl font-semibold"
            >
              Ha ocurrido un error en el pago
            </h2>
          )}
          {status === 'success' && (
            <span className="text-buddy-text-2 mt-4 text-center grow-0">
              Gracias por actualizar a la Versión BuddyMarket de pago. Ahora
              estás listo para disfrutar de todos los fantásticos beneficios y
              características que lo acompañan. Si tienes alguna pregunta o
              necesitas ayuda, no dudes en comunicarte con nosotros. ¡Que
              disfrutes de la APP!
            </span>
          )}
          {status === 'cancelled' && (
            <span className="text-buddy-text-2 mt-4 text-center grow-0">
              Prueba a realizar el pago de nuevo o ponte en contacto con
              nosotros
            </span>
          )}
        </div>
        <FooterToolbar>
          <PrimaryButton type="button" onClick={handleGoToIndex}>
            Empezar a usar BuddyMarket
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default Suscripcion1;
