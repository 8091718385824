export default function SendIcon({ className }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3014 13.6946L20.1025 3.89705M10.58 14.1351L12.8024 18.58C13.3395 19.6541 13.608 20.1912 13.9463 20.3352C14.2399 20.4602 14.5755 20.4376 14.8496 20.2743C15.1656 20.0862 15.3596 19.518 15.7477 18.3815L19.9468 6.08398C20.2849 5.09372 20.454 4.59859 20.3383 4.27106C20.2376 3.98611 20.0135 3.76198 19.7285 3.6613C19.401 3.54558 18.9059 3.71465 17.9156 4.05279L5.61812 8.25193C4.48163 8.64 3.91338 8.83404 3.72527 9.14995C3.56202 9.4241 3.5394 9.7597 3.66438 10.0533C3.8084 10.3916 4.34547 10.6601 5.41961 11.1972L9.86445 13.4196C10.0414 13.5081 10.1299 13.5523 10.2066 13.6115C10.2747 13.6639 10.3357 13.7249 10.3881 13.793C10.4473 13.8697 10.4915 13.9581 10.58 14.1351Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  );
}
