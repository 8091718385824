import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { faChevronLeft, faL } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuOrganizerDayPart from '../api/services/MenuOrganizerDayPart.js';
import {
  ChevronRightIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import InputState from './forms/InputState.js';
import PrimaryButton from './ui/PrimaryButton.js';

const MenuOrganizerDayShow = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [menuOrganizerDayParts, setMenuOrganizerDayParts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);

  const id = searchParams.get('id') ?? '';
  const date = searchParams.get('date') ?? '';
  const dateLabel = searchParams.get('date_label') ?? 'LABEL';
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    getMenuOrganizerDayParts();
  }, []);

  const getMenuOrganizerDayParts = async () => {
    setLoading(true);
    try {
      const response = await MenuOrganizerDayPart.list(id, {
        date: date,
      });
      setMenuOrganizerDayParts(response.data);
    } catch (error) {
      console.error('Error fetching organizerdayparts:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnBack = () => {
    if (showMenu) {
      setShowMenu(false);
    } else {
      navigate(-1, { replace: true });
    }
  };

  const handleOnCreate = async () => {
    try {
      const body = new FormData();
      body.append('name', document.querySelector('[name="name"]').value);
      body.append('date', date);
      const response = await MenuOrganizerDayPart.create(id, body);
      setShowCreateForm(false);
      getMenuOrganizerDayParts();
    } catch (error) {
      console.error('Error updating organizer:', error);
    }
  };

  return (
    <div className="h-full bg-no-repeat bg-cover bg-center">
      <div className="h-full bg-secondary-bg bg-opacity-60 p-6">
        {showMenu && (
          <div className="absolute top-20 left-0 w-full min-h-screen text-white bg-black bg-opacity-60">
            <div className="mx-0 border-t flex flex-col space-y-3 border-buddy-bg-2 bg-secondary-bg p-4">
              <button
                onClick={(e) => {
                  setShowCreateForm(true);
                  setShowMenu(false);
                }}
                className="w-full bg-buddy-bg-5 flex justify-between items-center px-3 py-2 rounded-lg space-x-3"
              >
                <div className="pointer-events-none">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_563_3353)">
                      <path
                        d="M22.1667 15.1667H10.5001V12.8333H22.1667V15.1667ZM22.1667 8.16667H14.0001V10.5H22.1667V8.16667ZM10.5001 19.8333H15.1667V17.5H10.5001V19.8333ZM28.0001 3.5V19.1497L19.1497 28H4.66675V12.8333H7.00008V25.6667H17.5001V17.5H25.6667V3.5C25.6667 2.856 25.1441 2.33333 24.5001 2.33333H16.3334V0H24.5001C26.4332 0 28.0001 1.56683 28.0001 3.5ZM19.8334 24.017L24.0171 19.8333H19.8334V24.017Z"
                        fill="#FF8C00"
                      />
                      <path
                        d="M7.66667 3.79167V0.25H6.33333V3.79167H3V5.20833H6.33333V8.75H7.66667V5.20833H11V3.79167H7.66667Z"
                        fill="#FF8C00"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_563_3353">
                        <rect width="28" height="28" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <span className="text-buddy-text-1 text-start font-medium text-sm grow pointer-events-none">
                  Añadir Comida
                </span>
                <ChevronRightIcon className="size-4 pointer-events-none"></ChevronRightIcon>
              </button>
            </div>
          </div>
        )}

        {showCreateForm && (
          <div className="absolute top-0 left-0 w-full min-h-screen text-white bg-black bg-opacity-60">
            <div className="mt-40 mx-8 rounded-lg my-auto  border-t flex flex-col space-y-3 border-buddy-bg-2 bg-secondary-bg p-4">
              <div className="flex justify-end">
                <button
                  onClick={(e) => {
                    setShowCreateForm(false);
                  }}
                  className="p-1"
                >
                  <XMarkIcon className="size-5" />
                </button>
              </div>
              <InputState name="name" label="Nombre de la Comida" />
              <PrimaryButton onClick={handleOnCreate}>Guardar</PrimaryButton>
            </div>
          </div>
        )}

        <div className="w-full h-12  flex justify-start text-title items-center text-lg mb-2">
          <div className="w-10 h-10 rounded-full bg-white bg-opacity-20 flex flex-col justify-center items-center">
            <button onClick={handleOnBack} className="text-title">
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          </div>
          <div className="grow text-center">
            <div className="font-bold ">{dateLabel}</div>
          </div>

          <button
            onClick={(e) => {
              setShowMenu(!showMenu);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_543_3313)">
                <rect x="6" width="4" height="4" rx="2" fill="white" />
                <rect x="6" y="6" width="4" height="4" rx="2" fill="white" />
                <rect x="6" y="12" width="4" height="4" rx="2" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_543_3313">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>

        <div className="mt-8">
          {menuOrganizerDayParts && (
            <div>
              {menuOrganizerDayParts.map((dayPart, i) => {
                return (
                  <div key={i} className="mb-6">
                    <div
                      onClick={(e) => {
                        navigate(
                          '/menuOrganizerDayPartShow?id=' +
                            id +
                            '&part_id=' +
                            dayPart.id +
                            '&part_label=' +
                            dayPart.name,
                        );
                      }}
                      className="w-full text-start bg-form-control-bg px-6 py-4 flex justify-between items-center rounded-lg mb-2"
                    >
                      <div className="flex flex-col pointer-events-none">
                        <span className="text-buddy-text-1 font-medium pointer-events-none">
                          {dayPart.name}
                        </span>
                      </div>
                      <button>
                        <ChevronRightIcon className="size-5 text-buddy-text-6"></ChevronRightIcon>
                      </button>
                    </div>
                    <div className="flex flex-col space-y-2 mt-2 px-2">
                      {dayPart.recipes.map((recipe) => {
                        return (
                          <Link
                            to={'/recipe?id=' + recipe.id}
                            key={recipe.id}
                            className="flex bg-buddy-bg-2 justify-between items-center space-x-3 rounded-xl px-4 py-3 text-buddy-text-1 font-medium"
                          >
                            <div className="overflow-hidden rounded-lg bg-transparent">
                              <img
                                src={recipe.image_url}
                                alt=""
                                className="bg-transparent h-12 w-12 object-cover object-center"
                              />
                            </div>
                            <div className="grow ">{recipe.name}</div>
                            <div>
                              <ChevronRightIcon className="size-4"></ChevronRightIcon>
                            </div>
                          </Link>
                        );
                      })}
                      {/* Mostrar Complementos */}
                      {dayPart.complements &&
                        dayPart.complements.map((complement) => (
                          <div
                            key={complement.id}
                            className="flex bg-buddy-bg-2 justify-between items-center space-x-3 rounded-xl px-4 py-3 text-buddy-text-1 font-medium"
                          >
                            <div className="grow">
                              {complement.complement_text}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuOrganizerDayShow;
