import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';

const PaymentProcessing = () => {
  const [status, setStatus] = useState('checking');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const userId = searchParams.get('user_id');
  const sessionId = searchParams.get('session_id');

  // We'll track how many times we've retried
  const attemptRef = useRef(0);
  console.log('PaymentProcessing.js: userId:', userId);
  useEffect(() => {
    if (!userId) {
      setStatus('failed');
      return;
    }
    console.log('PaymentProcessing.js: useEffect:');
    const checkSubscription = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}check-subscription-status?user_id=${userId}`,
        );
        const data = await response.json();

        console.log('Subscription status:', data);

        if (data.status === 'active' || data.status === 'trial') {
          setStatus('active');
          navigate('/subscription-result');
        } else if (
          data.status === 'expired' ||
          data.status === 'no_subscription'
        ) {
          // If it's already expired or no subscription found, user likely canceled or something else is wrong
          setStatus('failed');
        } else {
          // e.g. "checking", "pending", "still processing"
          setStatus('processing');
        }
      } catch (error) {
        console.error('Error checking subscription:', error);
        setStatus('failed');
      }
    };

    const pollSubscription = async () => {
      attemptRef.current += 1;
      await checkSubscription();

      // If still "processing" after check, we can retry up to X times
      if (status === 'processing' && attemptRef.current < 6) {
        // Retry after a delay (e.g., 3 seconds)
        setTimeout(pollSubscription, 3000);
      } else if (status === 'processing') {
        // Enough tries, give up
        setStatus('failed');
      }
    };

    // Start the subscription check (and polling if needed)
    pollSubscription();
    // eslint-disable-next-line
  }, []);

  if (status === 'checking' || status === 'processing') {
    return <div>Payment is still processing. Please wait...</div>;
  }

  if (status === 'active') {
    return <div>Subscripción Activa! Redirigiendo al login...</div>;
  }

  if (status === 'failed') {
    return (
      <div className="text-white flex flex-col gap-4 py-6">
        Something went wrong. Your payment might still be processing or it
        failed.
        <br />
        You can refresh this page or head back to the app’s main page.
        <Link to="/">Go back</Link>
      </div>
    );
  }

  return <div className="text-white">Unhandled status: {status}</div>;
};

export default PaymentProcessing;
