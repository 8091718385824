import client from '../client';

export default {
  login: (data) => client.post('login', { json: data }).json(),
  loginGoogle: (data) => client.post('logingoogle', { json: data }).json(),
  loginApple: (data) => client.post('loginapple', { json: data }).json(),
  register: (data) => client.post('register', { json: data }).json(),
  profile: () => client.get('profile').json(),
  forgotPassword: (data) =>
    client.post('forgotPassword', { json: data }).json(),
  resendForgotPassword: (data) =>
    client.post('resendForgotPassword', { json: data }).json(),
  updatePassword: (data) =>
    client.post('updatePassword', { json: data }).json(),
};