import React, { useState, useEffect } from 'react';
import Toolbar from './ui/Toolbar';
import HealthPreference from '../api/services/HealthPreference';
import { useNavigate } from 'react-router-dom';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';

const HealthPreferencesHydration = () => {
  const navigate = useNavigate();
  const [hydrationLevels, setHydrationLevels] = useState([]);
  const [hydrationLevel, setHydrationLevel] = useState(1);

  const handleClick = (e) => {
    const user = JSON.parse(localStorage.getItem('user'));
    user.hydration_level_id = hydrationLevel;
    localStorage.setItem('user', JSON.stringify(user));
    navigate('/healthPreferencesShopping');
  };

  const getHydrationLevelAmount = (id) => {
    switch (id) {
      case 1:
        return '<1.5L';
      case 2:
        return '1.5L-2.5L';
      default:
        return 'Excelent > 2.5L';
    }
  };

  const loadHydrationLevels = async () => {
    try {
      const response = await HealthPreference.getHydrationLevels();
      const adaptedOptions = response.data.map((spendRange) => {
        return {
          value: spendRange.id,
          label: spendRange.name,
        };
      });
      setHydrationLevels(adaptedOptions);
    } catch (error) {
      console.log(error);
    }
  };

  const loadUserData = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setHydrationLevel(user.hydration_level_id ?? 1);
    }
  };

  useEffect(() => {
    loadHydrationLevels();
    loadUserData();
  }, []);

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="flex flex-col justify-between min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col bg-red items-center gap-3 mb-6">
          <Toolbar to={'/healthPreferencesRestTime'}>
            <span>Paso 5</span> <span className="text-primary">de 7</span>
          </Toolbar>
          <div className="flex flex-col bg-red items-center gap-6">
            <h2 className="w-full text-white text-xl text-center font-semibold">
              ¿Como evaluarías tu nivel de hidratación diaria?
            </h2>
            <div className="flex flex-col justify-between items-center w-full gap-2">
              {hydrationLevels.map((hyLevel, _) => {
                return (
                  <button
                    type="button"
                    onClick={() => {
                      setHydrationLevel(hyLevel.value);
                    }}
                    className={`w-full text-center px-4 text-md rounded-xl font-semibold
                                                h-[79px] text-xl
                                                ${
                                                  hyLevel.value ===
                                                  hydrationLevel
                                                    ? 'bg-primary'
                                                    : 'bg-white'
                                                }
                                            `}
                  >
                    <div className="flex flex-col">
                      <span className="text-lg">{hyLevel.label}</span>
                      <span className="text-lg">
                        {getHydrationLevelAmount(hyLevel.value)}
                      </span>
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        <FooterToolbar>
          <PrimaryButton type="button" onClick={handleClick}>
            Siguiente
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default HealthPreferencesHydration;
