import PrimaryButton from './ui/PrimaryButton';
import React from 'react'

const ConfirmationAction = ({ title, description, buttonText, handleClick, imageUrl }) => {
  return (
    <div className="relative min-h-screen bg-buddy-bg-6 bg-opacity-90 px-8 pt-16">
        <div className='h-full w-full blur-[1px]'/>
        <div className="flex flex-col items-center gap-6">
            <img src={imageUrl} alt='' className="w-1/2 aspect-square mb-6" />
            <h1 className="text-3xl font-bold text-white">{title}</h1>
            <span className='text-base text-buddy-text-4'>{description}</span>
            <PrimaryButton type="button" onClick={handleClick}>{buttonText}</PrimaryButton>
        </div>
    </div>
)
}

export default ConfirmationAction