import client from '../client';

export default {
  create: (shoppingList, body) =>
    client
      .post(`shoppingLists/${shoppingList}/shoppingListItems`, {
        json: body,
        headers: { 'Content-Type': 'application/json' },
      })
      .json(),
  update: (shoppingList, item, body) =>
    client
      .post(`shoppingLists/${shoppingList}/shoppingListItems/${item}`, {
        json: body,
        headers: { 'Content-Type': 'application/json' },
      })
      .json(),
  delete: (shoppingList, item, body) =>
    client
      .delete(`shoppingLists/${shoppingList}/shoppingListItems/${item}`)
      .json(),
};
