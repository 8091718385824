import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UserShoppingList from '../../api/services/UserShoppingList.js';
import { XMarkIcon } from '@heroicons/react/24/outline';
import ShoppingCartDoneIcon from './assets/shoppingCartDoneIcon.js';
import ShoppingCartPendingIcon from './assets/shoppingCartPendingIcon.js';
import { BsArrowLeft, BsCheck } from 'react-icons/bs';
import ShoppingListItem from '../../api/services/ShoppingListItem.js';
import MenuOptionNavigator from '../MenuOptionNavigator.js';
import { generatePDF } from './../../utils.js';
import ShoppingGenericImg from '../../img/shopping-list-item-generic.png';
import clsx from 'clsx';
import BasicInputField from '../BasicInputField.js';

const ShoppingListShow = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [shoppingListData, setShoppingListData] = useState();
  const [loading, setLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [formValue, setFormValue] = useState('');
  const [shoppingListItems, setShoppingListItems] = useState([]);

  const id = searchParams.get('id') ?? '';
  const user = JSON.parse(localStorage.getItem('user'));

  const getShoppingListData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await UserShoppingList.get(user.id, id);
      setShoppingListData(response.data);
      setShoppingListItems(response.data.items);
    } catch (error) {
      console.error('Error fetching organizer:', error);
    } finally {
      setLoading(false);
    }
  }, [user.id, id]);

  useEffect(() => {
    getShoppingListData();
  }, [getShoppingListData]);

  const handleOnBack = () => {
    if (showMenu) {
      setShowMenu(false);
    } else {
      navigate('/shoppingLists', { replace: true });
    }
  };

  const handleSubmitSearch = async (e) => {
    e.preventDefault();
    const formValue = e.target[0].value;

    const newShoppingList = {
      name: formValue,
      status: 'pending',
      slug: generateSlug(formValue),
    };

    if (
      shoppingListItems.find((item) => item.slug === generateSlug(formValue))
    ) {
      alert('El item ya existe');
      setFormValue('');
      return;
    }

    try {
      const response = await ShoppingListItem.create(id, newShoppingList);

      if (response.data) {
        setShoppingListItems((prevShoppingList) => [
          ...prevShoppingList,
          newShoppingList,
        ]);

        setFormValue('');
      } else {
        alert('Error al añadir el item');
        console.error('Failed to save item. Unexpected response:', response);
      }
    } catch (error) {
      alert('Error al añadir el item');
      console.error('Error saving item:', error);
    }
  };

  const handleChangeStatus = async (item) => {
    const newShoppingItem = {
      ...item,
      status: item.status === 'done' ? 'pending' : 'done',
    };

    try {
      const response = await ShoppingListItem.update(
        id,
        item.slug,
        newShoppingItem,
      );

      if (response.data) {
        const newShoppingList = shoppingListItems.map((items) => {
          return items.name === item.name ? newShoppingItem : items;
        });

        setShoppingListItems(newShoppingList);
      } else {
        alert('Error al actualizar el item');
        console.error('Failed to update item. Unexpected response:', response);
      }
    } catch (error) {
      alert('Error al actualizar el item');
      console.error('Error updating item:', error);
    }
  };

  const handleDeleteItem = async (item) => {
    const response = await ShoppingListItem.delete(id, item.slug);

    if (response.data) {
      const newShoppingList = shoppingListItems.filter(
        (items) => items.name !== item.name,
      );

      setShoppingListItems(newShoppingList);
    } else {
      alert('Error al eliminar el item');
      console.error('Failed to delete item. Unexpected response:', response);
    }
  };

  return (
    <div className="flex flex-col w-full relative gap-8">
      {loading && (
        <div className="absolute inset-0 flex justify-center items-center h-screen">
          <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
        </div>
      )}
      {showMenu && (
        <div
          onClick={() => {
            setShowMenu(false);
          }}
          className="absolute z-30 top-24 left-0 w-full min-h-screen text-white bg-black bg-opacity-60"
        >
          <div className="mx-0 border-t flex flex-col space-y-3 border-buddy-bg-2 bg-secondary-bg p-4">
            <MenuOptionNavigator
              onClick={(e) => {
                generatePDF('list-items', 'lista-de-la-compra.pdf');
              }}
              label="Compartir lista"
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_545_3355)">
                  <path
                    d="M22.1667 15.1667H10.5001V12.8333H22.1667V15.1667ZM22.1667 8.16667H14.0001V10.5H22.1667V8.16667ZM10.5001 19.8333H15.1667V17.5H10.5001V19.8333ZM28.0001 3.5V19.1497L19.1497 28H4.66675V12.8333H7.00008V25.6667H17.5001V17.5H25.6667V3.5C25.6667 2.856 25.1441 2.33333 24.5001 2.33333H16.3334V0H24.5001C26.4332 0 28.0001 1.56683 28.0001 3.5ZM19.8334 24.017L24.0171 19.8333H19.8334V24.017Z"
                    fill="#FF8C00"
                  />
                  <g clipPath="url(#clip1_545_3355)">
                    <path
                      d="M11.6337 2.56292L9.43708 0.365833C9.20083 0.13 8.88667 0 8.55333 0H5.44667C5.11292 0 4.79917 0.13 4.56292 0.36625L2.36625 2.56292C2.13 2.79917 2 3.11292 2 3.44667V6.55333C2 6.88708 2.13 7.20083 2.36625 7.43708L4.56292 9.63375C4.79917 9.87 5.11292 10 5.44667 10H8.55333C8.88667 10 9.20083 9.87 9.4375 9.63375L11.6337 7.43708C11.8696 7.20083 12 6.88708 12 6.55333V3.44667C12 3.11292 11.8696 2.79917 11.6337 2.56292ZM11.1667 6.55333C11.1667 6.66292 11.1225 6.77042 11.0446 6.84792L8.84833 9.04417C8.76917 9.12292 8.66417 9.16667 8.55292 9.16667H5.44625C5.33667 9.16667 5.22917 9.12208 5.15167 9.04458L2.955 6.84792C2.8775 6.77 2.83292 6.66292 2.83292 6.55333V3.44667C2.83292 3.33708 2.8775 3.22958 2.955 3.15208L5.15208 0.955417C5.23 0.877917 5.33708 0.833333 5.44667 0.833333H8.55333C8.66458 0.833333 8.76958 0.876667 8.84833 0.955417L11.0446 3.15208C11.1225 3.23 11.1667 3.33708 11.1667 3.44667V6.55333ZM8.84958 3.73958L7.58167 5.0075L8.84958 6.27542L8.26042 6.86458L6.9925 5.59667L5.72458 6.86458L5.13542 6.27542L6.40333 5.0075L5.13542 3.73958L5.72458 3.15042L6.9925 4.41833L8.26042 3.15042L8.84958 3.73958Z"
                      fill="#FF8C00"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_545_3355">
                    <rect width="28" height="28" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_545_3355">
                    <rect
                      width="10"
                      height="10"
                      fill="white"
                      transform="translate(2)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </MenuOptionNavigator>
          </div>
        </div>
      )}

      <Header
        handleOnBack={handleOnBack}
        setShowMenu={setShowMenu}
        shoppingListData={shoppingListData}
        loading={loading}
      />

      <form
        className="relative flex items-center w-full px-6"
        onSubmit={handleSubmitSearch}
      >
        <BasicInputField
          value={formValue}
          type="text"
          placeHolder="Necesito..."
          onChange={(e) =>
            setFormValue(
              e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
            )
          }
        />
      </form>

      {shoppingListItems && (
        <div id="list-items" className="flex flex-col text-white gap-16 px-6">
          <div className="flex flex-col gap-4">
            <div className="text-xl font-medium flex items-center gap-2">
              <div className="w-10 h-10 flex justify-center items-center rounded-full bg-[#FF8C00]">
                <ShoppingCartPendingIcon className="w-6 h-6 text-[#292D32]" />
              </div>
              <span>En lista</span>
            </div>
            <div className="flex flex-col gap-2">
              {shoppingListItems
                .filter((item) => item.status === 'pending')
                .map((item) => {
                  return (
                    <ListItem
                      key={item.id}
                      item={item}
                      handleChangeStatus={handleChangeStatus}
                      handleDeleteItem={handleDeleteItem}
                    />
                  );
                })}
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-xl font-medium flex items-center gap-2">
              <div className="w-10 h-10 flex justify-center items-center rounded-full bg-[#FF8C00]">
                <ShoppingCartDoneIcon className="w-6 h-6 text-[#292D32]" />{' '}
              </div>
              <span>Añadido a la cesta</span>
            </div>
            <div className="flex flex-col gap-2">
              {shoppingListItems
                .filter((item) => item.status === 'done')
                .map((item) => {
                  return (
                    <ListItem
                      key={item.id}
                      item={item}
                      handleChangeStatus={handleChangeStatus}
                      handleDeleteItem={handleDeleteItem}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShoppingListShow;

const Header = ({ handleOnBack, setShowMenu, shoppingListData, loading }) => {
  const image =
    shoppingListData?.image_url && !loading
      ? shoppingListData?.image_url
      : ShoppingGenericImg;
  return (
    <div className="relative w-full h-32">
      <div
        className="absolute inset-0 bg-cover bg-center blur-[1px]"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-t from-black/90 to-transparent"></div>
      </div>
      <div className="relative top-11 w-full h-12 flex justify-between items-center px-4 text-title text-lg">
        <button
          onClick={handleOnBack}
          className="flex justify-center items-center w-10 h-10 text-white"
        >
          <BsArrowLeft className="w-6 h-6" />
        </button>
        <button
          onClick={() => setShowMenu((prevMenu) => !prevMenu)}
          className="text-white"
        >
          <EditIcon />
        </button>
      </div>
      <div className="absolute left-1/2 bottom-4 transform -translate-x-1/2 text-center">
        <h1 className="font-bold text-xl text-white">
          {shoppingListData?.name}
        </h1>
      </div>
    </div>
  );
};

const EditIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="6" width="4" height="4" rx="2" fill="white" />
    <rect x="6" y="6" width="4" height="4" rx="2" fill="white" />
    <rect x="6" y="12" width="4" height="4" rx="2" fill="white" />
  </svg>
);

const ListItem = ({ item, handleChangeStatus, handleDeleteItem }) => {
  return (
    <div className=" flex gap-4 items-center justify-between text-white">
      <div className="flex gap-4 items-center">
        <div
          className={clsx(
            'w-5 h-5 border rounded-full relative flex justify-center items-center cursor-pointer',
            {
              'bg-primary border-primary': item.status === 'done',
              'bg-transparent border-white': item.status === 'pending',
            },
          )}
          onClick={() => handleChangeStatus(item)}
        >
          {item.status === 'done' && <BsCheck className="w-5 h-5 text-white" />}
        </div>
        <label>{item.name}</label>
      </div>
      <button
        onClick={() => handleDeleteItem(item)}
        className="w-10 h-10 flex justify-center items-center"
      >
        <XMarkIcon className="w-5 h-5" />
      </button>
    </div>
  );
};

const generateSlug = (text) => {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9\s-]/g, '')
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase();
};
