import React, { useState } from 'react';
import Toolbar from './ui/Toolbar';
import { ChevronRightIcon } from '@heroicons/react/24/solid';

const UserFaqs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleOpenIndex = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const paymentsFaqs = [
    { question: '¿Cómo puedo cambiar de tarjeta?', answer: 'Para cambiar de tarjeta, ve a la sección de métodos de pago en tu cuenta.', id: 1 },
    { question: '¿Cómo puedo cambiar de tarjeta?', answer: 'Para cambiar de tarjeta, ve a la sección de métodos de pago en tu cuenta.', id: 2 },
  ];

  const iaFaqs = [
    { question: '¿Cómo puedo cambiar de tarjeta?', answer: 'Para cambiar de tarjeta, ve a la sección de métodos de pago en tu cuenta.', id: 6 },
  ]

  const deleteAccountFaqs = [
    { question: '¿Cómo puedo eliminar mi cuenta?', answer: 'Para eliminar tu cuenta, ve a la sección de cuenta en tu cuenta.', id: 7 },
  ];

  return (
    <div className='relative h-full bg-no-repeat bg-cover bg-center overflow-scroll'>
      <div className='h-full flex flex-col min-h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <Toolbar to={"/sidebarMenu"}>Preguntas frecuentes</Toolbar>
        <form className='h-full w-full mt-10'>
          <div className='flex flex-col items-center gap-3 mb-6'>
            <h2 className='w-full text-left text-white text-base font-medium ml-6'>Pagos</h2>
            {paymentsFaqs.map((faq, index) => (
              <div key={index} className='w-full'>
                <div
                  className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl cursor-pointer'
                  onClick={() => toggleOpenIndex(index)}
                >
                  <div className='grow px-2 text-start'>
                    <span className='text-buddy-text-1 font-semibold grow-0'>{faq.question}</span>
                  </div>
                  <ChevronRightIcon
                    className={`size-6 text-buddy-text-3 grow-0 transition-transform ${openIndex === index ? 'rotate-90' : ''}`}
                  />
                </div>
                {openIndex === index && (
                  <div className='bg-buddy-bg-3 mx-3 my-2 p-3 rounded-2xl'>
                    <p className='text-buddy-text-2'>{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className='flex flex-col items-center gap-3 mb-6'>
            <h2 className='w-full text-left text-white text-base font-medium ml-6'>IA</h2>
            {iaFaqs.map((faq, index) => (
              <div key={index} className='w-full'>
                <div
                  className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl cursor-pointer'
                  onClick={() => toggleOpenIndex(index)}
                >
                  <div className='grow px-2 text-start'>
                    <span className='text-buddy-text-1 font-semibold grow-0'>{faq.question}</span>
                  </div>
                  <ChevronRightIcon
                    className={`size-6 text-buddy-text-3 grow-0 transition-transform ${openIndex === index ? 'rotate-90' : ''}`}
                  />
                </div>
                {openIndex === index && (
                  <div className='bg-buddy-bg-3 mx-3 my-2 p-3 rounded-2xl'>
                    <p className='text-buddy-text-2'>{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className='flex flex-col items-center gap-3'>
            <h2 className='w-full text-left text-white text-base font-medium ml-6'>Eliminar cuenta</h2>
            {deleteAccountFaqs.map((faq, index) => (
              <div key={index} className='w-full'>
                <div
                  className="w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl cursor-pointer"
                  onClick={() => toggleOpenIndex(index)}
                >
                  <div className="grow px-2 text-start">
                    <span className="text-buddy-text-1 font-semibold grow-0">
                      {faq.question}
                    </span>
                  </div>
                  <ChevronRightIcon
                    className={`size-6 text-buddy-text-3 grow-0 transition-transform ${
                      openIndex === index ? 'rotate-90' : ''
                    }`}
                  />
                </div>
                {openIndex === index && (
                  <div className="bg-buddy-bg-3 mx-3 my-2 p-3 rounded-2xl">
                    <p className="text-buddy-text-2">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserFaqs;
