import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import SelectList from './forms/SelectList';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import PreCookedFoodOpinion from '../api/services/PreCookedFoodOpinion';

const ProfileForm13 = () => {
  const navigate = useNavigate();

  const [receiveNutritionalAdvices, setReceiveNutritionalAdvices] =
    useState('');
  const [preCookedFoodOpinionId, setPreCookedFoodOpinionId] = useState('');
  const [preCookedFoodOpinionOptions, setPreCookedFoodOpinionOptions] =
    useState([]);
  const receiveNutritionalAdvicesOptions = [
    {
      value: 1,
      label: 'Sí',
    },
    {
      value: 0,
      label: 'No',
    },
  ];

  const handleClick = (e) => {
    if (receiveNutritionalAdvices !== '' && preCookedFoodOpinionId !== '') {
      const data = JSON.parse(localStorage.getItem('register_data'));
      data.interested_in_nutritional_advices = receiveNutritionalAdvices;
      data.pre_cooked_food_opinion_id = preCookedFoodOpinionId;
      localStorage.setItem('register_data', JSON.stringify(data));
      navigate('/profileForm14');
    } else {
      alert('Responda a las preguntas no opcionales para continuar.');
    }
  };

  const handleOnChangeReceiveNutritionalAdvices = (value) => {
    setReceiveNutritionalAdvices(value);
  };

  const handleOnChangePreCookedFoodOpinionId = (value) => {
    setPreCookedFoodOpinionId(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    loadPreCookedFoodOptionId();

    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setReceiveNutritionalAdvices(
        data.interested_in_nutritional_advices ?? '',
      );
      setPreCookedFoodOpinionId(data.pre_cooked_food_opinion_id ?? '');
    }
  }, []);

  async function loadPreCookedFoodOptionId() {
    try {
      const response = await PreCookedFoodOpinion.list();
      var options = [];
      response.data.map((opinion) => {
        options.push({
          value: opinion.id,
          label: opinion.name,
        });
      });
      setPreCookedFoodOpinionOptions(options);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={'/profileForm12'} replace={true}>
              <div className="text-sm">
                <span>Paso 13</span> <span className="text-primary">de 28</span>
              </div>
            </Toolbar>
            <h2 className="w-full text-white text-xl font-semibold">
              ¿Te interesa aprender sobre nutrición y cómo tus elecciones
              alimenticias afectan a tu salud?
            </h2>
            <SelectList
              onChange={handleOnChangeReceiveNutritionalAdvices}
              selected={receiveNutritionalAdvices}
              options={receiveNutritionalAdvicesOptions}
            />
            <div className="my-2"></div>
            <h2 className="w-full text-white text-xl font-semibold">
              ¿Cuál es tu actitud hacia las comidas congeladas o preparadas?
            </h2>
            <SelectList
              onChange={handleOnChangePreCookedFoodOpinionId}
              selected={preCookedFoodOpinionId}
              options={preCookedFoodOpinionOptions}
            />
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick}>
                Siguiente
              </PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm13;
