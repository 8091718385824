import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import RecipeIngredient from '../api/services/RecipeIngredient.js';
import Measure from '../api/services/Measure.js';
import Toolbar from './ui/Toolbar.js';
import FooterToolbar from './ui/FooterToolbar.js';
import PrimaryButton from './ui/PrimaryButton.js';

import Select from './forms/Select.js';
import InputState from './forms/InputState.js';

const RecipeIngredientEdit = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [recipeIngredient, setRecipeIngredient] = useState(null);
  const recipeId = searchParams.get('recipe_id') ?? '';
  const ingredientId = searchParams.get('ingredient_id') ?? '';
  const [measureId, setMeasureId] = useState();
  const [measureOptions, setMeasureOptions] = useState([]);
  const [ingredientName, setIngredientName] = useState();

  useEffect(() => {
    const getRecipeIngredient = async () => {
      try {
        const response = await RecipeIngredient.get(recipeId, ingredientId);
        const recipeIngredient = response.data;
        document.querySelector('[name="quantity"]').value =
          recipeIngredient?.quantity;
        setRecipeIngredient(recipeIngredient);
        setMeasureId(recipeIngredient.measure.id);
        setIngredientName(recipeIngredient.ingredient.name);
      } catch (error) {
        console.error('Error fetching recipe ingredient:', error);
      }
    };

    getRecipeIngredient();
    getMeasures();
  }, [recipeId, ingredientId]);

  const getMeasures = async () => {
    try {
      const response = await Measure.list();
      var options = [];
      response.data.forEach((type) => {
        options.push({
          value: type.id,
          label: type.name,
        });
      });
      setMeasureOptions(options);
    } catch (error) {
      console.error('Error fetching FoodCategory:', error);
    }
  };

  const handleUpdate = async () => {
    try {
      await RecipeIngredient.update(recipeId, ingredientId, {
        ingredient_id: recipeIngredient.ingredient.id,
        quantity: document.querySelector('[name="quantity"]').value,
        measure_id: measureId,
      });
      navigate(-1);
    } catch (error) {
      alert(
        error.response.payload.message ??
          'Error inesperado. Inténtalo de nuevo.',
      );
      console.error('Error creating recipe:', error);
    }
  };

  const handleOnChangeMeasureId = (e) => {
    setMeasureId(e.target.value);
  };

  return (
    <div className="h-full bg-no-repeat bg-cover bg-center">
      <div className="h-full bg-secondary-bg bg-opacity-60 p-6">
        <Toolbar to={-1} replace={true}>
          <div className="text-md">
            <span>Editar ingredientes</span>
          </div>
        </Toolbar>

        <div className="flex flex-col py-2 space-y-3">
          <InputState
            readOnly={true}
            value={ingredientName}
            name="ingredient_name"
            label={'Ingrediente'}
          />

          <Select
            selected={measureId}
            name="measure_id"
            label="Medida"
            onChange={handleOnChangeMeasureId}
            options={measureOptions}
          />

          <InputState name="quantity" label={'Cantidad'} type="number" />
        </div>

        <FooterToolbar>
          <PrimaryButton type="button" onClick={handleUpdate}>
            Guardar
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default RecipeIngredientEdit;
