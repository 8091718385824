import { useEffect } from 'react';
import { App as CapacitorApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { useNavigate } from 'react-router-dom';

const useDeepLinkListener = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let listenerRef;

    const init = async () => {
      listenerRef = await CapacitorApp.addListener(
        'appUrlOpen',
        async ({ url }) => {
          console.log('🔗 Deep link recibido:', url);

          if (!url) return;

          try {
            const urlObj = new URL(url);

            if (url.startsWith('buddymarket://close')) {
              console.log('🔗 Deep link de close recibido');
              Browser.close();
              window.location.href = '/';
            }

            if (url.startsWith('buddymarket://payment-success')) {
              const userId = urlObj.searchParams.get('user_id');
              const sessionId = urlObj.searchParams.get('session_id');

              console.log(
                '✅ Pago exitoso para user:',
                userId,
                'session:',
                sessionId,
              );

              if (Capacitor.isPluginAvailable('Browser')) {
                await Browser.close();
                console.log('🧨 Navegador cerrado');
              }

              navigate('/payment-processing');
            }

            if (url.startsWith('buddymarket://payment-cancel')) {
              const userId = urlObj.searchParams.get('user_id');
              console.warn('❌ Pago cancelado para user:', userId);

              if (Capacitor.isPluginAvailable('Browser')) {
                await Browser.close();
                console.log('🧨 Navegador cerrado (cancel)');
              }

              navigate('/');
            }
          } catch (error) {
            console.error('❗ Error procesando deep link:', error);
          }
        },
      );
    };

    init();

    return () => {
      if (listenerRef && typeof listenerRef.remove === 'function') {
        listenerRef.remove();
      }
    };
  }, [navigate]);
};

export default useDeepLinkListener;
