import clsx from 'clsx';

export default function ButtonWithImage({ image, text, selected, onClick }) {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'w-fit h-fit flex items-center rounded-lg px-2 py-1 gap-2',
        {
          'bg-primary': selected,
          'bg-white': !selected,
        },
      )}
    >
      <img src={image} alt={text} className="w-6 h-auto rounded-2xl" />
      <span>{text}</span>
    </button>
  );
}
