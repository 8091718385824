import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { SubscriptionContext } from '../contexts/subscriptionContext';
import SubscriptionExpired from './SuscriptionExpired.js';

function ProtectedRoute({ children }) {
  const { subStatus, loading } = useContext(SubscriptionContext);
  const isAuth = JSON.parse(localStorage.getItem('user'));

  if (!isAuth?.id) {
    return <Navigate to="/login" replace />;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
      </div>
    );
  }

  if (subStatus === 'active' || subStatus === 'trial') {
    return children;
  }

  if (!subStatus === null) {
    return (
      <div className="text-center text-white flex justify-center items-center h-screen">
        Hay un problema al cargar tu suscripción. Por favor, intenta más tarde.
      </div>
    );
  }

  console.log(isAuth, subStatus);

  return <SubscriptionExpired />;
}

export default ProtectedRoute;
