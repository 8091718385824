import React, { useState, useEffect } from 'react';
import Toolbar from './ui/Toolbar';
import { useNavigate } from 'react-router-dom';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';

const HealthPreferencesMedicalCondition = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    sleep_recomended_hours: 0,
    sleep_hours: null,
    use_nutritional_supplements: 0,
    nutritional_supplements: null,
  });

  const handleClick = (e) => {
    const user = JSON.parse(localStorage.getItem('user'));
    user.sleep_recomended_hours = data.sleep_recomended_hours;
    user.sleep_hours = data.sleep_hours;
    user.use_nutritional_supplements = data.use_nutritional_supplements;
    user.nutritional_supplements = data.nutritional_supplements;
    localStorage.setItem('user', JSON.stringify(user));
    navigate('/healthPreferencesHydration');
  };

  const loadUserData = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setData({
        sleep_recomended_hours: user.sleep_recomended_hours ?? 0,
        sleep_hours: user.sleep_hours ?? null,
        use_nutritional_supplements: user.use_nutritional_supplements ?? 0,
        nutritional_supplements: user.nutritional_supplements ?? null,
      });
    }
  };

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="flex flex-col justify-between min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col bg-red  gap-3 mb-6">
          <Toolbar to={'/healthPreferencesMedicalCondition'}>
            <span>Paso 4</span> <span className="text-primary">de 7</span>
          </Toolbar>
          <div className="flex flex-col bg-red items-center gap-6 mb-8">
            <h2 className="w-full text-white text-xl text-center font-semibold">
              ¿Duermes las horas recomendadas por noche?
            </h2>
            <div className="flex justify-between items-center w-2/3 gap-8">
              <button
                type="button"
                onClick={() => setData({ ...data, sleep_recomended_hours: 1 })}
                className={`w-full text-center px-4 text-md rounded-xl font-semibold
                            w-[80px] h-[59px] text-xl
                            ${
                              data.sleep_recomended_hours === 1
                                ? 'bg-primary'
                                : 'bg-white'
                            }
                            ${
                              data.sleep_recomended_hours === 1
                                ? 'text-white'
                                : 'text-back'
                            }
                        `}
              >
                Sí
              </button>
              <button
                type="button"
                onClick={() => setData({ ...data, sleep_recomended_hours: 0 })}
                className={`w-full text-center px-4 text-md rounded-xl font-semibold
                            w-[80px] h-[59px] text-xl
                            ${
                              data.sleep_recomended_hours === 0
                                ? 'bg-primary'
                                : 'bg-white'
                            }
                            ${
                              data.sleep_recomended_hours === 0
                                ? 'text-white'
                                : 'text-back'
                            }
                        `}
              >
                No
              </button>
            </div>
            <div className="flex flex-col gap-1 mb-16">
              <h3 className="text-md text-white text-center mb-1">
                Si la respuesta es “no”, por favor especifica el número de
                horas:
              </h3>
              <div className="w-full shrink-0 grow h-[44px] bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-transparent ring-1">
                <input
                  onChange={(e) =>
                    setData({ ...data, sleep_hours: parseInt(e.target.value) })
                  }
                  value={data.sleep_hours}
                  type="number"
                  aria-autocomplete="none"
                  list="autocompleteOff"
                  className="appearance-none border-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl mb-2"
                  name="recipe_step"
                  placeholder=""
                />
                <span className="text-buddy-text-7 text-xs ml-3">Ej: 7</span>
              </div>
            </div>

            <h2 className="w-full text-white text-xl text-center font-semibold">
              ¿Tomas suplementos nutricionales regularmente?
            </h2>
            <div className="flex justify-between items-center w-2/3 gap-8">
              <button
                type="button"
                onClick={() =>
                  setData({ ...data, use_nutritional_supplements: 1 })
                }
                className={`w-full text-center px-4 text-md rounded-xl font-semibold
                            w-[80px] h-[59px] text-xl
                            ${
                              data.use_nutritional_supplements === 1
                                ? 'bg-primary'
                                : 'bg-white'
                            }
                            ${
                              data.use_nutritional_supplements === 1
                                ? 'text-white'
                                : 'text-back'
                            }
                        `}
              >
                Sí
              </button>
              <button
                type="button"
                onClick={() =>
                  setData({ ...data, use_nutritional_supplements: 0 })
                }
                className={`w-full text-center px-4 text-md rounded-xl font-semibold
                            w-[80px] h-[59px] text-xl
                            ${
                              data.use_nutritional_supplements === 0
                                ? 'bg-primary'
                                : 'bg-white'
                            }
                            ${
                              data.use_nutritional_supplements === 0
                                ? 'text-white'
                                : 'text-back'
                            }
                        `}
              >
                No
              </button>
            </div>
            <div className="flex flex-col gap-1 mb-16">
              <h3 className="text-md text-white text-center mb-1">
                Si la respuesta es “no”, por favor especifica que suplementos y
                su cantidad:
              </h3>
              <div className="w-full shrink-0 grow h-[44px] bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-transparent ring-1">
                <input
                  onChange={(e) =>
                    setData({
                      ...data,
                      nutritional_supplements: e.target.value,
                    })
                  }
                  value={data.nutritional_supplements}
                  type="text"
                  aria-autocomplete="none"
                  list="autocompleteOff"
                  className="appearance-none border-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl mb-2"
                  name="recipe_step"
                  placeholder=""
                />
                <span className="text-buddy-text-7 text-xs ml-3">
                  Ej: 1 cup de proteína en polvo{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
        <FooterToolbar>
          <PrimaryButton type="button" onClick={handleClick}>
            Siguiente
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default HealthPreferencesMedicalCondition;
