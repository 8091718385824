import React from 'react';

const Test = () => {
  return (
    <div onClick={() => (window.location.href = 'buddymarket://test')}>
      TEST
    </div>
  );
};

export default Test;
