import ky from 'ky';

const client = ky.extend({
  prefixUrl: process.env.REACT_APP_API_BASE_URL,

  hooks: {
    beforeRequest: [
      (request) => {
        const token = localStorage.getItem('token');
        request.headers.set('Authorization', `Bearer ${token}`);
      },
    ],
    beforeError: [
      (error) => {
        const { response } = error;
        if (response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          window.location.href = '/login';
        }

        if (response && response.body) {
          error.name = 'Error';
          error.code = response.status;
        }
        return error;
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (!response.ok) {
          response.payload = await response.json();
          return response;
        }
      },
    ],
  },
});

export default client;
