import React from 'react';
import buddysus from '../img/buddysus.png';

const Suscripcion = () => {
  return (
    <div className="contenedor">
      <div className="rectangulo centrado h140"></div>
      <div className="rectangulo centrado fondodos round">
        <img
          style={{ marginTop: '-140px' }}
          className=""
          src={buddysus}
          alt="Gracias por registrarte"
        />

        <div className="rectangulo centrado round mb20">
          <h1 className="f20">
            ¡Enhorabuena! Ya estás suscrito en BuddyMarket!
          </h1>
          <p>
            Gracias por actualizar a la Versión BuddyMarket de pago. Ahora estás
            listo para disfrutar de todos los fantásticos beneficios y
            características que lo acompañan. Si tienes alguna pregunta o
            necesitas ayuda, no dudes en comunicarte con nosotros. ¡Que
            disfrutes de la APP!
          </p>
        </div>
        <form action="dashboard">
          <div className="">
            <button className="w100 submit fondonaranja" type="submit">
              Ir a BuddyMarket
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Suscripcion;
