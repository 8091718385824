import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from './ui/PrimaryButton';
import Auth from '../api/services/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Card from './ui/Card';
import Buddy from '../img/buddy-welcome.png';

const SignUpConfirm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [failMessage, setFailMessage] = useState('');

  useEffect(() => {
    setLoading(false);
    postRegister();
  }, []);

  async function goLogin() {
    navigate('/login');
  }

  async function postRegister() {
    setLoading(true);
    try {
      const data = JSON.parse(localStorage.getItem('register_data'));
      await Auth.register(data);
    } catch (error) {
      setFailMessage(error.response.payload.message ?? '');
      setFailed(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <form className="w-full">
          <div className="flex flex-col bg-red items-center gap-3">
            {loading ? (
              <div className="absolute inset-0 flex justify-center items-center h-screen">
                <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
              </div>
            ) : !failed ? (
              <Card className="bg-primary-bg bg-opacity-100 text-white mt-28">
                <div className="w-full text-center">
                  <FontAwesomeIcon
                    size="8x"
                    className="inline text-red-700 z-10 -mt-28 mb-8"
                    icon={faTimesCircle}
                  />
                </div>
                <h2 className="text-center font-semibold text-2xl">
                  Algo ha salido mal
                </h2>
                <p className="text-center text-body my-6">
                  No se ha podido completar el registro
                </p>
                {failMessage ? (
                  <div className="mb-4 mx-4">
                    <p>Error: {failMessage}</p>
                  </div>
                ) : (
                  ''
                )}
                <PrimaryButton type="button" onClick={postRegister}>
                  Reintentar
                </PrimaryButton>
              </Card>
            ) : (
              <div className="relative min-h-screen">
                <div className="min-h-screen bg-primary-dark-bg bg-opacity-60 p-6 flex flex-col items-center">
                  <img src={Buddy} alt="" className="w-1/2 aspect-square" />
                  <div
                    image={Buddy}
                    description=""
                    className="flex flex-col gap-7 relative text-white -m-4"
                  >
                    <div className="flex flex-col gap-7 bg-primary-bg p-6 rounded-2xl">
                      <div className="text-3xl font-bold text-center">
                        ¡Bienvenido a BuddyMarket!
                      </div>
                      <span className="text-center">
                        Gracias por unirte a nuestra comunidad. Disfruta de 7
                        días de prueba gratuita para explorar todo lo que
                        ofrecemos. A partir del día 7, se activará tu
                        suscripción automáticamente, y se te cobrará según el
                        plan elegido. Puedes cancelarla en cualquier momento
                        antes de que termine el periodo de prueba. ¡Empieza
                        ahora y sácale el máximo partido a BuddyMarket!
                      </span>
                    </div>
                    <PrimaryButton type="button" onClick={goLogin}>
                      Empezar a usar BuddyMarket
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpConfirm;
