import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import Input from './forms/Input';
import PrimaryButton from './ui/PrimaryButton';
import Textarea from './forms/Textarea';
import User from '../api/services/User.js';
import instagramIcon from '../icons/instagram.png';
import linkedinIcon from '../icons/linkedin.png';
import youtubeIcon from '../icons/youtube.png';

const BuddymakerProfile = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    phone: '',
    description: '',
    gender: 'Hombre',
    instagram: '',
    linkedin: '',
    youtube: '',
  });
  const [editingField, setEditingField] = useState(null);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const response = await User.profile();
      setProfile({
        name: response.data.name,
        email: response.data.email,
        phone: response.data.phone,
        description: response.data.description || '',
        gender: response.data.gender.name,
        instagram: response.data.instagram || '',
        linkedin: response.data.linkedin || '',
        youtube: response.data.youtube || '',
      });
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const handleSaveProfile = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const genderId = profile.gender === 'Hombre' ? 1 : 2;
      const updatedProfile = {
        ...profile,
        gender_id: genderId,
      };

      const body = new FormData();
      for (const key in updatedProfile) {
        body.append(key, updatedProfile[key]);
      }
      await User.update(user.id, body);
      const updatedUser = { ...user, name: updatedProfile.name };
      localStorage.setItem('user', JSON.stringify(updatedUser));
      navigate('/sidebarMenu');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Ha ocurrido un error al editar');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProfile({
      ...profile,
      [name]: value,
    });
  };

  const handleEditField = (field) => {
    setEditingField(field);
  };

  const renderSocialInputOrText = (name, placeholder, icon) => (
    <div
      className="w-full flex justify-start gap-3 bg-buddy-bg-2 p-3 rounded-2xl"
      style={{ alignItems: 'center' }}
      onClick={() => handleEditField(name)}
    >
      <img src={icon} alt={name} style={{ width: '24px', height: '24px' }} />
      {editingField === name ? (
        <Input
          name={name}
          type="text"
          placeholder={placeholder}
          value={profile[name] || ''}
          onChange={handleChange}
          onBlur={() => setEditingField(null)}
        />
      ) : (
        <div className="px-2 text-start">
          <span className="text-buddy-text-1 mt-auto mb-auto font-semibold">
            {profile[name] ?? ''}
          </span>
        </div>
      )}
    </div>
  );

  return (
    <div className="relative h-full bg-no-repeat bg-cover bg-center overflow-scroll">
      <div className="flex flex-col min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <Toolbar to={'/sidebarMenu'}>Edición de perfil</Toolbar>
        <div className="flex flex-col items-center gap-3 mt-5">
          <Textarea
            name="description"
            label={'Biografía'}
            value={profile.description}
            onChange={handleChange}
          />
          <h2 className="w-full text-left text-white text-sm font-medium mt-4">
            Redes Sociales
          </h2>
          <div className="w-full flex flex-col gap-2">
            {renderSocialInputOrText(
              'instagram',
              'www.instagram.com',
              instagramIcon,
            )}
            {renderSocialInputOrText(
              'linkedin',
              'www.linkedin.com',
              linkedinIcon,
            )}
            {renderSocialInputOrText('youtube', 'www.youtube.com', youtubeIcon)}
          </div>
        </div>
        <FooterToolbar>
          <PrimaryButton type="button" onClick={handleSaveProfile}>
            Guardar cambios
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default BuddymakerProfile;
