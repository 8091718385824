import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar.js';
import PrimaryButton from './ui/PrimaryButton.js';
import FooterToolbar from './ui/FooterToolbar.js';
import UserShoppingList from '../api/services/UserShoppingList.js';
import BasicInputField from './BasicInputField.js';

const ShoppingListCreate = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileInputRef = useRef(null);
  const [data, setData] = useState({
    name: '',
    file: null,
  });

  const handleStore = async () => {
    if (isSubmitting) return;

    if (!data.name) {
      alert('Debes introducir un nombre para la lista de la compra.');
      return;
    }

    try {
      setIsSubmitting(true);
      const body = new FormData();
      body.append('name', data.name);
      body.append('image_file', data.file);
      body.append('shopping_list_type_id', 1);
      body.append('date', '2025-02-02');
      await UserShoppingList.create(user.id, body);
      navigate('/shoppingLists', { replace: true });
    } catch (error) {
      alert(
        error.response?.data?.message ??
          'Error inesperado. Inténtalo de nuevo.',
      );
      console.error('Error creating shopping list:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="relative h-full bg-no-repeat bg-cover bg-center overflow-scroll">
      <div className="h-screen bg-secondary-bg bg-opacity-60 p-6 flex flex-col justify-between">
        <div className="flex flex-col bg-red gap-3">
          <Toolbar to="/shoppingLists">
            <h1 className="text-bold text-2xl">Nueva lista de la compra</h1>
          </Toolbar>
          <h1 className="mt-8 text-white text-xl font-bold mb-4">
            ¿Cómo quieres que se llame tu lista de la compra?
          </h1>
          <BasicInputField
            value={data.name}
            onChange={(event) => setData({ ...data, name: event.target.value })}
            placeHolder="Nombre de la lista"
            type="text"
          />
          <h1 className="mt-8 text-white text-xl font-bold mb-4">
            Elegir una imagen de portada
          </h1>
          <PrimaryButton
            className="rounded-xl bg-primary text-white h-16 w-48"
            type="button"
            onClick={handleClick}
          >
            <label className="font-bold text-base">Elegir imagen</label>
          </PrimaryButton>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={(event) => {
              const file = event.target.files?.[0];
              if (file) setData({ ...data, file });
            }}
          />
        </div>
        <FooterToolbar>
          <PrimaryButton
            className="flex rounded-xl bg-primary text-white h-16 w-full cursor-pointer justify-center items-center"
            type="button"
            onClick={handleStore}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-300 border-l-neutral-300 animate-spin rounded-full" />
            ) : (
              <label className="font-bold text-lg">Guardar Lista</label>
            )}
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default ShoppingListCreate;
