import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import SelectList from './forms/SelectList';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import DailyPhysicalActivityLevel from '../api/services/DailyPhysicalActivityLevel';

const ProfileForm21 = () => {
  const navigate = useNavigate();

  const [physicalActivityLevelId, setPhysicalActivityLevelId] = useState('');
  const [physicalActivityLevelOptions, setPhysicalActivityLevelOptions] =
    useState([]);

  const handleClick = (e) => {
    if (physicalActivityLevelId !== '') {
      const data = JSON.parse(localStorage.getItem('register_data'));
      data.daily_physical_activity_level_id = physicalActivityLevelId;
      localStorage.setItem('register_data', JSON.stringify(data));
      navigate('/profileForm22');
    } else {
      alert('Responda a las preguntas no opcionales para continuar.');
    }
  };

  const handleOnChangePhysicalActivityLevelId = (value) => {
    setPhysicalActivityLevelId(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    loadPhysicalActivityLevelOptions();

    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setPhysicalActivityLevelId(data.daily_physical_activity_level_id ?? '');
    }
  }, []);

  async function loadPhysicalActivityLevelOptions() {
    try {
      const response = await DailyPhysicalActivityLevel.list();
      var options = [];
      response.data.map((level) => {
        options.push({
          value: level.id,
          label: level.name,
        });
      });
      setPhysicalActivityLevelOptions(options);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={'/profileForm20'} replace={true}>
              <div className="text-sm">
                <span>Paso 21</span> <span className="text-primary">de 28</span>
              </div>
            </Toolbar>
            <h2 className="w-full text-white text-xl font-semibold">
              Nivel de actividad física diaria
            </h2>
            <SelectList
              onChange={handleOnChangePhysicalActivityLevelId}
              selected={physicalActivityLevelId}
              options={physicalActivityLevelOptions}
            />
            <div className="my-2"></div>
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick}>
                Siguiente
              </PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm21;
