import React from 'react';
import { useState, useEffect } from 'react';
import { getUserInfo } from '../auth';
import { Link } from 'react-router-dom';

const RecetasGuardadas = () => {
  const [usuario, setUsuario] = useState([]);
  const [recetas, setRecetas] = useState([]);
  const userinfo = getUserInfo();

  const placeholder = 'nouser.png';

  useEffect(() => {
    fetch(
      `https://buddymarket.io/dummy/apibm/recipes/readlistfavorites.php?id=${userinfo.id}`,
    )
      .then((response) => response.json())
      .then((data) => {
        setRecetas(data.data);
      })
      .catch((error) => console.error('Error fetching recipes:', error));
  }, []);

  useEffect(() => {
    fetch(
      `https://buddymarket.io/dummy/apibm/users/read.php/?id=${userinfo.id}`,
    )
      .then((response) => response.json())
      .then((data) => {
        setUsuario(data.data);
      })
      .catch((error) => console.error('Error fetching usuario:', error));
  }, []);

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Mis Recetas guardadas</h2>
      </div>

      <div className="rectangulo round relative fondouno">
        {usuario?.map((usu) => (
          <div key={usu.id} className="w100">
            <div className="flex wrap equiespaciado rgap20 vtop">
              <div key={usu.id} className="w20 vtop textoblanco">
                <img
                  className="w90"
                  src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/users/${
                    usu.foto || placeholder
                  }`}
                  alt={usu.username}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <div className="redes centrado textoblanco w80">
                <h2 className="blanconegrita centrado f18">{usu.name}</h2>
                <span className="centrado">{usu.email}</span>
                <br />
                <br />
                <div className="flex equiespaciado gap10">
                  <button className="bordeblanco mb20 f08 h42">
                    <Link
                      className="textoblanco"
                      to={`../perfil/${userinfo.id}`}
                    >
                      Editar perfil
                    </Link>
                  </button>
                  <button className="bordeblanco f08 h42">
                    <Link
                      className="textoblanco"
                      to={`../preferencias/${userinfo.id}`}
                    >
                      Editar preferencias
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <h2 className="izquierdo">Mis recetas guardadas</h2>
      {recetas?.map((receta) => (
        <div key={receta.id} className="rectangulo round relative fondoblanco">
          <div className="flex wrap izquierda rgap20 vtop">
            <div className="w40">
              <a href={`./receta/${receta.id}`}>
                <img
                  className="w100"
                  src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${receta.photo}`}
                  alt={receta.name}
                />
              </a>
            </div>
            <div className="w60 pl20">
              <a href={`./receta/${receta.id}`}>
                <h2 className="textooscuro">{receta.name}</h2>
              </a>
              <br />
              <span>{receta.description}</span>
            </div>
          </div>
        </div>
      ))}
      <br />
      <br />
    </div>
  );
};

export default RecetasGuardadas;
