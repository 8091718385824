import React from "react";
import useDeepLinkListener from "./hooks/useDeepLinkListener";

const AppLayout = ({ children }) => {
  useDeepLinkListener();

  return <>{children}</>;
};

export default AppLayout;
