import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import RecipeStep from '../api/services/RecipeStep.js';
import Toolbar from './ui/Toolbar.js';
import FooterToolbar from './ui/FooterToolbar.js';
import PrimaryButton from './ui/PrimaryButton.js';
import Textarea from './forms/Textarea.js';

const RecipeStepCreate = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get('recipe_id') ?? '';
  const [description, setDescription] = useState('');

  useEffect(() => {}, []);

  const handleStore = async () => {
    try {
      await RecipeStep.create(recipeId, {
        description: description,
      });
      navigate(-1);
    } catch (error) {
      alert(
        error.response.payload.message ??
          'Error inesperado. Inténtalo de nuevo.',
      );
      console.error('Error creating recipe:', error);
    }
  };

  const handleOnChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  return (
    <div className="h-full bg-no-repeat bg-cover bg-center">
      <div className="h-full bg-secondary-bg bg-opacity-60 p-6">
        <Toolbar to={-1} replace={true}>
          <div className="text-md">
            <span>Añadir instrucciones</span>
          </div>
        </Toolbar>
        <div className="flex flex-col py-2 space-y-3">
          <Textarea
            value={description}
            onChange={handleOnChangeDescription}
            name="description"
            label="Descripción del paso"
          />
        </div>
        <FooterToolbar>
          <PrimaryButton type="button" onClick={handleStore}>
            Guardar
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default RecipeStepCreate;
