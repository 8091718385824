import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import PrimaryButton from './ui/PrimaryButton';
import FooterToolbar from './ui/FooterToolbar';
import Select from './forms/Select';
import Input from './forms/Input';
import UserMenuOrganizer from '../api/services/UserMenuOrganizer.js';

const MenuOrganizerCreate = () => {
  const navigate = useNavigate();
  const [menuOrganizserTypeId, setMenuOrganizserTypeId] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false); // Nuevo estado para controlar el submit

  const user = JSON.parse(localStorage.getItem('user'));

  const organizerTypes = [
    {
      value: 1,
      label: 'Semanal',
    },
  ];

  const handleStore = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      var fileInput = document.querySelector('input[type="file"]');
      const body = new FormData();
      body.append('name', document.querySelector('[name="name"]').value);
      body.append(
        'start_date',
        document.querySelector('[name="start_date"]').value,
      );
      body.append(
        'end_date',
        document.querySelector('[name="end_date"]').value,
      );
      body.append('menu_organizer_type_id', menuOrganizserTypeId);
      const response = await UserMenuOrganizer.create(user.id, body);
      navigate(-1, { replace: true });
    } catch (error) {
      alert(
        error.response.payload.message ??
          'Error inesperado. Inténtalo de nuevo.',
      );
      console.error('Error creating organizer:', error);
    }

    setIsSubmitting(false); // Volver a habilitar el botón después de procesar
  };

  useEffect(() => {
    setMenuOrganizserTypeId(1);
  }, []);

  return (
    <div className="relative h-full bg-no-repeat bg-cover bg-center overflow-scroll">
      <div className="h-full bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col bg-red items-center gap-3 mb-8">
          <Toolbar to={-1} replace={true}>
            Crear Organizador de menú
          </Toolbar>
          <Input name="name" label="Nombre del menú" />
          <div className="w-full">
            <p className="text-white font-semibold text-md mb-2">
              Fecha inicio
            </p>
            <div className="w-full h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
              <input
                name="start_date"
                type="date"
                aria-autocomplete="none"
                list="autocompleteOff"
                className="[color-scheme:dark] appearance-none w-full h-full bg-transparent text-white color-sch placeholder:text-white px-4 placeholder:font-light rounded-xl"
              />
            </div>
          </div>
          <div className="w-full">
            <p className="text-white font-semibold text-md mb-2">Fecha fin</p>
            <div className="w-full h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
              <input
                name="end_date"
                type="date"
                aria-autocomplete="none"
                list="autocompleteOff"
                className="[color-scheme:dark] appearance-none w-full h-full bg-transparent text-white color-sch placeholder:text-white px-4 placeholder:font-light rounded-xl"
              />
            </div>
          </div>
          <div className="w-full">
            <Select
              name="menu_organizer_type_id"
              label="Tipo de menú"
              onChange={(e) => {
                setMenuOrganizserTypeId(e.target.value);
              }}
              options={organizerTypes}
            />
          </div>
        </div>
        <FooterToolbar>
          <PrimaryButton
            type="button"
            onClick={handleStore}
            disabled={isSubmitting}
          >
            Guardar Organizador
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default MenuOrganizerCreate;
