import React from 'react';

const Badge = ({ children, type = 'default', style = 'outline' }) => {
  let classes = 'inline-block rounded text-xs ring-1 py-1 px-2 mb-2 mr-2 ';

  switch (type) {
    case 'primary':
      classes +=
        style === 'outline'
          ? ' text-primary ring-primary'
          : ' text-buddy-text-1 ring-primary bg-primary';
      break;
    case 'success':
      break;
    case 'danger':
      break;
    case 'default':
    default:
      classes +=
        style === 'outline'
          ? ' text-buddy-text-1 ring-buddy-text-1'
          : ' text-buddy-text-5 ring-buddy-text-1 bg-buddy-text-1';
      break;
  }

  return (
    <>
      <div className={classes}>{children}</div>
    </>
  );
};

export default Badge;
