import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserShoppingList from '../api/services/UserShoppingList.js';
import ShoppingListItem from '../api/services/ShoppingListItem.js';
import Measure from '../api/services/Measure.js';
import {
  ChevronRightIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import InputState from './forms/InputState.js';
import PrimaryButton from './ui/PrimaryButton.js';
import SelectRounded from './forms/SelectRounded.js';
import { useDebounce } from '../hooks/useDebounce.js';

const ShoppingListShowMercadonaDB = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [shoppingList, setShoppingList] = useState();
  const [shoppingListItems, setShoppingListItems] = useState();
  const [loading, setLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [ingredients, setIngredients] = useState([]);
  const [ingredientSearch, setIngredientSearch] = useState('');
  const [ingredientSearchEnabled, setIngredientSearchEnabled] = useState(true);
  const [showAddIngredientForm, setShowAddIngredientForm] = useState(false);
  const [ingredient, setIngredient] = useState(null);
  const [measureId, setMeasureId] = useState(null);
  const [measureOptions, setMeasureOptions] = useState([]);
  const debouncedSearch = useDebounce(ingredientSearch, 800);
  const id = searchParams.get('id') ?? '';
  const user = JSON.parse(localStorage.getItem('user'));

  const getShoppingList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await UserShoppingList.get(user.id, id);
      setShoppingList(response.data);
      setShoppingListItems(response.data.items);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [id, user.id]);

  useEffect(() => {
    getShoppingList();
    getMeasures();
  }, [getShoppingList]);

  useEffect(() => {
    if (debouncedSearch.length >= 3) {
      getIngredients(debouncedSearch);
    } else {
      setIngredients([]);
    }
  }, [debouncedSearch]);

  const handleOnBack = () => {
    if (showMenu) {
      setShowMenu(false);
    } else {
      navigate('/shoppingLists', { replace: true });
    }
  };

  const handleOnDelete = async () => {
    if (window.confirm('Eliminar Lista de la compra') === true) {
      try {
        await UserShoppingList.delete(user.id, id);
        navigate('/mercadona/products');
      } catch (error) {}
    }
  };

  const handleOnUpdate = async () => {
    try {
      const body = new FormData();
      body.append('name', document.querySelector('[name="name"]').value);
      await UserShoppingList.update(user.id, id, body);
      setShowEditForm(false);
      getShoppingList();
    } catch (error) {}
  };

  const getIngredients = async (search) => {
    try {
      const response = await fetch(
        `https://app.buddymarket.io/api/mercadona/products?search=${search}`,
      );
      const { data } = await response.json();
      setIngredients(data);
    } catch (error) {}
  };

  const getMeasures = async () => {
    try {
      const response = await Measure.list();
      const options = response.data.map((type) => ({
        value: type.id,
        label: type.name,
      }));
      setMeasureOptions(options);
      setMeasureId(options[0].value);
    } catch (error) {}
  };

  const handleOnAddIngredient = async () => {
    if (!document.querySelector('[name="quantity"]').value) {
      alert('Es necesario que indiques la cantidad');
    } else {
      try {
        const body = new FormData();
        body.append(
          'quantity',
          document.querySelector('[name="quantity"]').value,
        );
        body.append('measure_id', measureId);
        body.append('ingredient', JSON.stringify(ingredient));
        await ShoppingListItem.create(id, body);
        handleResetIngredient();
        getShoppingList();
        setShowAddIngredientForm(false);
        document.querySelector('[name="quantity"]').value = '';
      } catch (error) {}
    }
  };

  const handleResetIngredient = () => {
    setIngredientSearch('');
    document.querySelector('[name="ingredient_search"]').value = '';
    setIngredients([]);
    setIngredient(null);
    setIngredientSearchEnabled(true);
  };

  const handleOnFocusIngredientSearch = (e) => {
    if (ingredientSearchEnabled) {
      const val = e.target.value;
      if (val.length < 3) {
        setIngredients([]);
      } else {
        getIngredients(val);
      }
    }
  };

  const handleOnChangeIngredientSearch = (e) => {
    setIngredientSearch(e.target.value);
  };

  const handleOnChangeMeasureId = (e) => {
    setMeasureId(e.target.value);
  };

  const handleOnIngredientSelected = (product) => {
    const selectedIngredient = {
      id: product.nombre,
      name: product.nombre,
      description: product.descripción_del_producto,
      price: product.precio,
      image_url: product.imagen,
      quantity: 1,
    };
    handleAddIngredientWithDefaults(selectedIngredient);
  };

  const handleAddIngredientWithDefaults = async (ingredient) => {
    try {
      const body = new FormData();
      body.append('quantity', 1);
      body.append('measure_id', 1);
      body.append('ingredient', JSON.stringify(ingredient));
      await ShoppingListItem.create(id, body);
      handleResetIngredient();
      getShoppingList();
    } catch (error) {}
  };

  const handleToggleChecked = async (index) => {
    const currentItems = [...shoppingListItems];
    currentItems[index].checked = !currentItems[index].checked;
    setShoppingListItems(currentItems);
    try {
      const body = new FormData();
      body.append('quantity', currentItems[index].quantity);
      body.append('checked', currentItems[index].checked ? 1 : 0);
      await ShoppingListItem.update(id, currentItems[index].id, body);
    } catch (error) {}
  };

  return loading ? (
    <div className="flex justify-center items-center h-screen">
      <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
    </div>
  ) : (
    <div className="flex flex-col w-full p-6 relative gap-4">
      {showMenu && (
        <div
          onClick={() => {
            setShowMenu(false);
          }}
          className="absolute z-30 top-20 left-0 w-full min-h-screen text-white bg-black bg-opacity-60"
        >
          <div className="mx-0 border-t flex flex-col space-y-3 border-buddy-bg-2 bg-secondary-bg p-4">
            <button
              onClick={handleOnDelete}
              className="w-full bg-buddy-bg-5 flex justify-between items-center px-3 py-2 rounded-lg space-x-3"
            >
              <div>
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_545_3355)">
                    <path
                      d="M22.1667 15.1667H10.5001V12.8333H22.1667V15.1667ZM22.1667 8.16667H14.0001V10.5H22.1667V8.16667ZM10.5001 19.8333H15.1667V17.5H10.5001V19.8333ZM28.0001 3.5V19.1497L19.1497 28H4.66675V12.8333H7.00008V25.6667H17.5001V17.5H25.6667V3.5C25.6667 2.856 25.1441 2.33333 24.5001 2.33333H16.3334V0H24.5001C26.4332 0 28.0001 1.56683 28.0001 3.5ZM19.8334 24.017L24.0171 19.8333H19.8334V24.017Z"
                      fill="#FF8C00"
                    />
                    <g clipPath="url(#clip1_545_3355)">
                      <path
                        d="M11.6337 2.56292L9.43708 0.365833C9.20083 0.13 8.88667 0 8.55333 0H5.44667C5.11292 0 4.79917 0.13 4.56292 0.36625L2.36625 2.56292C2.13 2.79917 2 3.11292 2 3.44667V6.55333C2 6.88708 2.13 7.20083 2.36625 7.43708L4.56292 9.63375C4.79917 9.87 5.11292 10 5.44667 10H8.55333C8.88667 10 9.20083 9.87 9.4375 9.63375L11.6337 7.43708C11.8696 7.20083 12 6.88708 12 6.55333V3.44667C12 3.11292 11.8696 2.79917 11.6337 2.56292Z"
                        fill="#FF8C00"
                      />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_545_3355">
                      <rect width="28" height="28" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_545_3355">
                      <rect
                        width="10"
                        height="10"
                        fill="white"
                        transform="translate(2)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <span className="text-buddy-text-1 text-start font-medium text-sm grow">
                Eliminar Lista
              </span>
              <ChevronRightIcon className="size-4"></ChevronRightIcon>
            </button>
            <button
              onClick={() => {
                setShowMenu(false);
                setShowEditForm(true);
              }}
              className="w-full bg-buddy-bg-5 flex justify-between items-center px-3 py-2 rounded-lg space-x-3"
            >
              <div>
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_545_3381)">
                    <path
                      d="M22.1667 15.1667H10.5001V12.8333H22.1667V15.1667ZM22.1667 8.16667H14.0001V10.5H22.1667V8.16667ZM10.5001 19.8333H15.1667V17.5H10.5001V19.8333ZM28.0001 3.5V19.1497L19.1497 28H4.66675V12.8333H7.00008V25.6667H17.5001V17.5H25.6667V3.5C25.6667 2.856 25.1441 2.33333 24.5001 2.33333H16.3334V0H24.5001C26.4332 0 28.0001 1.56683 28.0001 3.5ZM19.8334 24.017L24.0171 19.8333H19.8334V24.017Z"
                      fill="#FF8C00"
                    />
                    <g clipPath="url(#clip1_545_3381)">
                      <path
                        d="M11.8387 4.16667C11.9675 4.16667 12.0892 4.10708 12.1679 4.00542C12.2467 3.90375 12.2742 3.77125 12.2425 3.64667C12.1129 3.13958 11.8487 2.67625 11.4787 2.30625L10.0267 0.854167C9.47583 0.303333 8.74333 0 7.96417 0H6.08292C4.93458 0 4 0.934583 4 2.08333V7.91667C4 9.06542 4.93458 10 6.08333 10H7.33333C7.56333 10 7.75 9.81333 7.75 9.58333C7.75 9.35333 7.56333 9.16667 7.33333 9.16667H6.08333C5.39417 9.16667 4.83333 8.60583 4.83333 7.91667V2.08333C4.83333 1.39417 5.39417 0.833333 6.08333 0.833333H7.96458C8.0325 0.833333 8.1 0.836667 8.16667 0.842917V2.91667C8.16667 3.60583 8.7275 4.16667 9.41667 4.16667H11.8387ZM9 2.91667V1.10792C9.15792 1.19875 9.305 1.31125 9.4375 1.44375L10.8896 2.89583C11.0204 3.02667 11.1325 3.17417 11.2242 3.33333H9.41667C9.18708 3.33333 9 3.14625 9 2.91667Z"
                        fill="#FF8C00"
                      />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_545_3381">
                      <rect width="28" height="28" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_545_3381">
                      <rect
                        width="10"
                        height="10"
                        fill="white"
                        transform="translate(4)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <span className="text-buddy-text-1 text-start font-medium text-sm grow">
                Editar nombre
              </span>
              <ChevronRightIcon className="size-4"></ChevronRightIcon>
            </button>
          </div>
        </div>
      )}
      {showEditForm && (
        <div className="absolute top-0 z-20 left-0 w-full min-h-screen text-white bg-black bg-opacity-60">
          <div className="mt-40 mx-8 rounded-lg my-auto  border-t flex flex-col space-y-3 border-buddy-bg-2 bg-secondary-bg p-4">
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setShowEditForm(false);
                }}
                className="p-1"
              >
                <XMarkIcon className="size-5" />
              </button>
            </div>
            <InputState
              name="name"
              label="Nombre de la lista"
              value={shoppingList?.name}
            />
            <PrimaryButton onClick={handleOnUpdate}>Guardar</PrimaryButton>
          </div>
        </div>
      )}
      {showAddIngredientForm && (
        <div className="absolute top-0 z-20 left-0 w-full min-h-screen text-white bg-black bg-opacity-60">
          <div className="mt-40 mx-8 rounded-lg my-auto  border-t border-buddy-bg-2 bg-secondary-bg p-4">
            <div className="flex justify-between items-center">
              <div className="font-semibold">Añadir {ingredient?.label}</div>
              <button
                onClick={() => {
                  setShowAddIngredientForm(false);
                }}
                className="p-1"
              >
                <XMarkIcon className="size-5" />
              </button>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-6 mt-6">
              <div className="flex flex-col">
                <span className="text-xs text-center">Unidad</span>
                <SelectRounded
                  name="measure_id"
                  onChange={handleOnChangeMeasureId}
                  options={measureOptions}
                />
              </div>
              <div className="flex flex-col text-buddy-text-1">
                <span className="text-xs mb-2 text-center">Cantidad</span>
                <div className="w-full">
                  <div className="w-full h-11 bg-secondary-bg rounded-lg relative shadow-inner ring-white ring-2">
                    <input
                      aria-autocomplete="none"
                      list="autocompleteOff"
                      className="appearance-none w-full h-full bg-transparent text-center text-sm text-white placeholder:font-light rounded-lg"
                      name="quantity"
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </div>
            <PrimaryButton onClick={handleOnAddIngredient}>
              Guardar
            </PrimaryButton>
          </div>
        </div>
      )}
      <Header
        handleOnBack={handleOnBack}
        setShowMenu={setShowMenu}
        shoppingList={shoppingList}
      />
      <div className="w-full h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
        {ingredientSearch.length > 0 ? (
          <div className="absolute right-0 bottom-0 top-0">
            <button
              type="button"
              onClick={handleResetIngredient}
              className="flex items-center justify-center w-8 me-2 h-full"
            >
              <div className="w-8 h-8 rounded-full bg-form-button-bg flex flex-col justify-center items-center">
                <FontAwesomeIcon className="text-white" icon={faTimes} />
              </div>
            </button>
          </div>
        ) : (
          ''
        )}
        <input
          readOnly={!ingredientSearchEnabled}
          onFocus={handleOnFocusIngredientSearch}
          onChange={handleOnChangeIngredientSearch}
          autoComplete="new-password"
          aria-autocomplete="none"
          list="autocompleteOff"
          className="appearance-none w-full h-full bg-transparent text-white ps-9 pe-4 rounded-xl"
          name="ingredient_search"
          type="text"
          placeholder="Buscador de alimentos"
        />
        <div className="absolute left-2 top-4">
          <MagnifyingGlassIcon className="size-5 text-buddy-text-3" />
        </div>
        {ingredients.length > 0 && (
          <div
            id="ingredientsList"
            className="w-full z-10 absolute flex flex-col bg-buddy-bg-5 border border-neutral-500 overflow-y-auto max-h-80 top-14 rounded-lg overflow-hidden"
          >
            {ingredients.map((product, i) => (
              <button
                key={i}
                onClick={() => handleOnIngredientSelected(product)}
                className="w-full text-start text-buddy-text-2 py-2 px-3"
              >
                {product.nombre} - {product.descripción_del_producto} -{' '}
                {product.precio}
              </button>
            ))}
          </div>
        )}
      </div>
      {shoppingListItems && (
        <div>
          <div className="py-4 px-2">
            <div className="text-primary font-medium">
              {shoppingListItems.filter((e) => !e.checked).length} artículos
              pendientes
            </div>
          </div>
          {shoppingListItems.filter((e) => !e.checked).length > 0 ? (
            <div className="flex flex-col bg-buddy-bg-2 rounded-xl px-2 py-1 first:border-b">
              {shoppingListItems.map((item, i) => {
                if (item.checked) return null;
                return (
                  <div
                    key={i}
                    className="flex space-x-4 justify-between items-center px-1 py-3 border-b border-buddy-bg-4 last:border-b-0"
                  >
                    <div className="rounded-full bg-transparent">
                      {item.image_url ? (
                        <img
                          src={item.image_url}
                          alt=""
                          className="bg-transparent h-10 w-10 object-cover object-center"
                        />
                      ) : (
                        <div className="h-10 w-10 bg-gray-200 flex items-center justify-center">
                          No image
                        </div>
                      )}
                    </div>
                    <div className="text-buddy-text-1 grow font-medium">
                      {item.description ? item.description : 'Ingredient name'}
                    </div>
                    <button
                      onClick={() => {
                        handleToggleChecked(i);
                      }}
                      className="p-3"
                    >
                      {item.checked ? (
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 8.29369C16.5 12.5865 12.9299 16.0874 8.5 16.0874C4.07007 16.0874 0.5 12.5865 0.5 8.29369C0.5 4.00086 4.07007 0.5 8.5 0.5C12.9299 0.5 16.5 4.00086 16.5 8.29369Z"
                            stroke="#C9CDC9"
                          />
                          <path
                            d="M7.45 11.95L12.7375 6.6625L11.6875 5.6125L7.45 9.85L5.3125 7.7125L4.2625 8.7625L7.45 11.95ZM8.5 16C7.4625 16 6.4875 15.803 5.575 15.409C4.6625 15.015 3.86875 14.4808 3.19375 13.8063C2.51875 13.1313 1.9845 12.3375 1.591 11.425C1.1975 10.5125 1.0005 9.5375 1 8.5C1 7.4625 1.197 6.4875 1.591 5.575C1.985 4.6625 2.51925 3.86875 3.19375 3.19375C3.86875 2.51875 4.6625 1.9845 5.575 1.591C6.4875 1.1975 7.4625 1.0005 8.5 1C9.5375 1 10.5125 1.197 11.425 1.591C12.3375 1.985 13.1312 2.51925 13.8062 3.19375C14.4812 3.86875 15.0157 4.6625 15.4097 5.575C15.8037 6.4875 16.0005 7.4625 16 8.5C16 9.5375 15.803 10.5125 15.409 11.425C15.015 12.3375 14.4807 13.1313 13.8062 13.8063C13.1312 14.4813 12.3375 15.0157 11.425 15.4097C10.5125 15.8038 9.5375 16.0005 8.5 16Z"
                            fill="#FF8C00"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 8.29369C16.5 12.5865 12.9299 16.0874 8.5 16.0874C4.07007 16.0874 0.5 12.5865 0.5 8.29369C0.5 4.00086 4.07007 0.5 8.5 0.5C12.9299 0.5 16.5 4.00086 16.5 8.29369Z"
                            stroke="#C9CDC9"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-buddy-text-2 text-sm px-2">
              No hay artículos pendientes en esta lista
            </div>
          )}
        </div>
      )}
      {shoppingListItems && (
        <div className="mt-4">
          <div className="py-4 px-2">
            <div className="text-primary font-medium">Ya en la cesta</div>
          </div>
          {shoppingListItems.filter((e) => e.checked).length > 0 ? (
            <div className="flex flex-col bg-buddy-bg-2 rounded-xl px-2 py-1 first:border-b">
              {shoppingListItems.map((item, i) => {
                if (!item.checked) return null;
                return (
                  <div
                    key={i}
                    className="flex space-x-4 justify-between items-center px-1 py-3 border-b border-buddy-bg-4 last:border-b-0"
                  >
                    <div className="overflow-hidden rounded-full bg-transparent">
                      {item.image_url ? (
                        <img
                          src={item.image_url}
                          alt=""
                          className="bg-transparent opacity-70 h-10 w-10 object-cover object-center"
                        />
                      ) : (
                        <div className="h-10 w-10 bg-gray-200 flex items-center justify-center">
                          No image
                        </div>
                      )}
                    </div>
                    <div className="text-buddy-text-3 grow line-through font-medium">
                      {item.description ? item.description : 'Ingredient name'}
                    </div>
                    <button
                      onClick={() => {
                        handleToggleChecked(i);
                      }}
                      className="p-3"
                    >
                      {item.checked ? (
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 8.29369C16.5 12.5865 12.9299 16.0874 8.5 16.0874C4.07007 16.0874 0.5 12.5865 0.5 8.29369C0.5 4.00086 4.07007 0.5 8.5 0.5C12.9299 0.5 16.5 4.00086 16.5 8.29369Z"
                            stroke="#C9CDC9"
                          />
                          <path
                            d="M7.45 11.95L12.7375 6.6625L11.6875 5.6125L7.45 9.85L5.3125 7.7125L4.2625 8.7625L7.45 11.95ZM8.5 16C7.4625 16 6.4875 15.803 5.575 15.409C4.6625 15.015 3.86875 14.4808 3.19375 13.8063C2.51875 13.1313 1.9845 12.3375 1.591 11.425C1.1975 10.5125 1.0005 9.5375 1 8.5C1 7.4625 1.197 6.4875 1.591 5.575C1.985 4.6625 2.51925 3.86875 3.19375 3.19375C3.86875 2.51875 4.6625 1.9845 5.575 1.591C6.4875 1.1975 7.4625 1.0005 8.5 1C9.5375 1 10.5125 1.197 11.425 1.591C12.3375 1.985 13.1312 2.51925 13.8062 3.19375C14.4812 3.86875 15.0157 4.6625 15.4097 5.575C15.8037 6.4875 16.0005 7.4625 16 8.5C16 9.5375 15.803 10.5125 15.409 11.425C15.015 12.3375 14.4807 13.1313 13.8062 13.8063C13.1312 14.4813 12.3375 15.0157 11.425 15.4097C10.5125 15.8038 9.5375 16.0005 8.5 16Z"
                            fill="#FF8C00"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 8.29369C16.5 12.5865 12.9299 16.0874 8.5 16.0874C4.07007 16.0874 0.5 12.5865 0.5 8.29369C0.5 4.00086 4.07007 0.5 8.5 0.5C12.9299 0.5 16.5 4.00086 16.5 8.29369Z"
                            stroke="#C9CDC9"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-buddy-text-2 text-sm px-2">
              Aquí aparecen los artículos marcados como completados
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ShoppingListShowMercadonaDB;

const Header = ({ handleOnBack, setShowMenu, shoppingList }) => {
  return (
    <div className="w-full h-12 flex justify-between items-center text-title text-lg">
      <button
        onClick={handleOnBack}
        className="flex justify-center items-center w-10 h-10 rounded-full bg-white bg-opacity-20"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <div className="grow text-center">
        <div className="font-bold">{shoppingList?.name}</div>
      </div>
      <button
        onClick={() => {
          setShowMenu((prevMenu) => !prevMenu);
        }}
      >
        <EditIcon />
      </button>
    </div>
  );
};

const EditIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="6" width="4" height="4" rx="2" fill="white" />
    <rect x="6" y="6" width="4" height="4" rx="2" fill="white" />
    <rect x="6" y="12" width="4" height="4" rx="2" fill="white" />
  </svg>
);
