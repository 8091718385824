import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const RecipeSmallTile = ({
  recipe,
  to = null,
  showStatus = false,
  showAuthor = true,
}) => {
  return (
    <Link
      to={to}
      className="w-full flex-none mb-2 rounded-lg h-28 overflow-hidden relative"
    >
      <div className="w-full overflow-hidden rounded-lg bg-black">
        <LazyLoadImage
          src={recipe.image_url}
          alt="Imagen de receta"
          className="bg-black brightness-50 w-full h-full object-cover object-center"
          effect="blur"
        />
      </div>
      <div className="absolute z-50 bottom-3 w-full text-center text-buddy-text-1 text-xs font-semibold">
        {recipe.name}
      </div>
      <div
        className="h-1/3 bg-gradient-to-t from-black to-transparent
         absolute bottom-0 left-0 right-0"
      />
    </Link>
  );
};

export default RecipeSmallTile;
