import React, { useCallback } from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserShoppingList from '../../api/services/UserShoppingList.js';
import AdImage from './assets/ad.png';
import ShoppingListItem from '../ShoppingListItem.js';
import MenuOptionNavigator from '../MenuOptionNavigator.js';

const ShoppingListList = () => {
  const navigate = useNavigate();
  const [shoppingLists, setShoppingLists] = useState([]);
  const [pastShoppingLists, setPastShoppingLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMenu, setShowMenu] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));

  const getShoppingLists = useCallback(async () => {
    setShoppingLists([]);
    try {
      const response = await UserShoppingList.list(user.id, {
        completed: false,
      });
      setShoppingLists(response.data);
    } catch (error) {
      console.error('Error fetching shoppinglists:', error);
    } finally {
      setLoading(false);
    }
  }, [user.id]);

  const handleDelete = async (id) => {
    if (window.confirm('Eliminar Lista de la compra') === true) {
      try {
        await UserShoppingList.delete(user.id, id);
        getShoppingLists();
      } catch (error) {
        console.error('Error deleting shoppinglist:', error);
      }
    }
  };

  const handleUpdate = async (id, name) => {
    try {
      const body = new FormData();
      body.append('name', name);
      await UserShoppingList.update(user.id, id, body);
      getShoppingLists();
    } catch (error) {
      console.error('Error updating shoppinglist:', error);
    }
  };

  const handleDuplicate = async (id) => {
    try {
      await UserShoppingList.duplicate(user.id, id);
      getShoppingLists();
    } catch (error) {
      console.error('Error duplicating shoppinglist:', error);
    }
  };

  useEffect(() => {
    const getPastShoppingLists = async () => {
      setPastShoppingLists([]);
      try {
        const response = await UserShoppingList.list(user.id, {
          completed: true,
        });
        setPastShoppingLists(response.data);
      } catch (error) {
        console.error('Error fetching past shoppinglists:', error);
      } finally {
        setLoading(false);
      }
    };
    getShoppingLists();
    getPastShoppingLists();
  }, [getShoppingLists, user.id]);

  return !user?.id || loading ? (
    <div className="flex justify-center items-center h-screen">
      <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
    </div>
  ) : (
    <div className="relative h-full bg-no-repeat bg-cover bg-center">
      <div className="flex flex-col gap-6 h-full bg-secondary-bg bg-opacity-60 p-6">
        {showMenu && (
          <div
            onClick={(e) => {
              setShowMenu(false);
            }}
            className="absolute top-20 left-0 w-full min-h-screen text-white bg-black bg-opacity-60"
          >
            <div className="mx-0 border-t border-buddy-bg-2 bg-secondary-bg p-4">
              <MenuOptionNavigator
                label="Crear Lista de la compra"
                onClick={() => navigate('/shoppingListCreate')}
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_129_15152)">
                    <path
                      d="M8.16683 28C9.45549 28 10.5002 26.9554 10.5002 25.6667C10.5002 24.378 9.45549 23.3334 8.16683 23.3334C6.87817 23.3334 5.8335 24.378 5.8335 25.6667C5.8335 26.9554 6.87817 28 8.16683 28Z"
                      fill="#FF8C00"
                    />
                    <path
                      d="M19.8333 28C21.122 28 22.1667 26.9554 22.1667 25.6667C22.1667 24.378 21.122 23.3334 19.8333 23.3334C18.5447 23.3334 17.5 24.378 17.5 25.6667C17.5 26.9554 18.5447 28 19.8333 28Z"
                      fill="#FF8C00"
                    />
                    <path
                      d="M26.8332 3.5H24.4998V1.16667C24.4998 0.857247 24.3769 0.560501 24.1581 0.341709C23.9393 0.122916 23.6426 0 23.3332 0C23.0238 0 22.727 0.122916 22.5082 0.341709C22.2894 0.560501 22.1665 0.857247 22.1665 1.16667V3.5H19.8332C19.5238 3.5 19.227 3.62292 19.0082 3.84171C18.7894 4.0605 18.6665 4.35725 18.6665 4.66667C18.6665 4.97609 18.7894 5.27283 19.0082 5.49162C19.227 5.71042 19.5238 5.83333 19.8332 5.83333H22.1665V8.16667C22.1665 8.47609 22.2894 8.77283 22.5082 8.99162C22.727 9.21042 23.0238 9.33333 23.3332 9.33333C23.6426 9.33333 23.9393 9.21042 24.1581 8.99162C24.3769 8.77283 24.4998 8.47609 24.4998 8.16667V5.83333H26.8332C27.1426 5.83333 27.4393 5.71042 27.6581 5.49162C27.8769 5.27283 27.9998 4.97609 27.9998 4.66667C27.9998 4.35725 27.8769 4.0605 27.6581 3.84171C27.4393 3.62292 27.1426 3.5 26.8332 3.5Z"
                      fill="#FF8C00"
                    />
                    <path
                      d="M25.3995 11.347C25.2487 11.3188 25.0937 11.3208 24.9436 11.3529C24.7936 11.385 24.6514 11.4465 24.5253 11.534C24.3992 11.6215 24.2917 11.7331 24.209 11.8624C24.1264 11.9917 24.0702 12.1362 24.0438 12.2873C23.8983 13.0948 23.4737 13.8256 22.8442 14.3519C22.2147 14.8781 21.4203 15.1665 20.5998 15.1667H6.321L5.22433 5.83333H15.1667C15.4761 5.83333 15.7728 5.71042 15.9916 5.49162C16.2104 5.27283 16.3333 4.97609 16.3333 4.66667C16.3333 4.35725 16.2104 4.0605 15.9916 3.84171C15.7728 3.62292 15.4761 3.5 15.1667 3.5H4.949L4.9 3.08933C4.79946 2.23837 4.39024 1.45385 3.74989 0.884455C3.10953 0.315063 2.28255 0.000364976 1.42567 0L1.16667 0C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667C0 1.47609 0.122916 1.77283 0.341709 1.99162C0.560501 2.21042 0.857247 2.33333 1.16667 2.33333H1.42567C1.71142 2.33337 1.98723 2.43828 2.20077 2.62817C2.41431 2.81805 2.55073 3.07971 2.58417 3.3635L4.1895 17.0135C4.35616 18.4331 5.03822 19.7421 6.10623 20.692C7.17423 21.6419 8.55383 22.1667 9.98317 22.1667H22.1667C22.4761 22.1667 22.7728 22.0438 22.9916 21.825C23.2104 21.6062 23.3333 21.3094 23.3333 21C23.3333 20.6906 23.2104 20.3938 22.9916 20.175C22.7728 19.9562 22.4761 19.8333 22.1667 19.8333H9.98317C9.25927 19.8335 8.55314 19.6091 7.96205 19.1913C7.37095 18.7734 6.92396 18.1825 6.68267 17.5H20.5998C21.9673 17.5001 23.2914 17.0198 24.3408 16.143C25.3902 15.2661 26.0981 14.0484 26.341 12.7027C26.3683 12.5518 26.3655 12.3971 26.3329 12.2473C26.3004 12.0975 26.2386 11.9556 26.1512 11.8297C26.0637 11.7038 25.9523 11.5964 25.8234 11.5136C25.6944 11.4307 25.5504 11.3741 25.3995 11.347Z"
                      fill="#FF8C00"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_129_15152">
                      <rect width="28" height="28" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </MenuOptionNavigator>
            </div>
          </div>
        )}
        <div className="h-12 flex justify-start text-title items-center text-lg mb-8">
          <button
            onClick={(e) => {
              showMenu ? setShowMenu(false) : navigate('/', { replace: true });
            }}
            className="w-10 h-10 rounded-full bg-white bg-opacity-20 flex flex-col justify-center items-center"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <div className="grow text-center">
            <div className="font-bold ">Mis listas de la compra</div>
          </div>
          <button
            onClick={(e) => {
              setShowMenu((showMenu) => !showMenu);
            }}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_326_4788)">
                <path
                  d="M8.16683 27.9999C9.45549 27.9999 10.5002 26.9553 10.5002 25.6666C10.5002 24.3779 9.45549 23.3333 8.16683 23.3333C6.87817 23.3333 5.8335 24.3779 5.8335 25.6666C5.8335 26.9553 6.87817 27.9999 8.16683 27.9999Z"
                  fill="white"
                />
                <path
                  d="M19.8333 27.9999C21.122 27.9999 22.1667 26.9553 22.1667 25.6666C22.1667 24.3779 21.122 23.3333 19.8333 23.3333C18.5447 23.3333 17.5 24.3779 17.5 25.6666C17.5 26.9553 18.5447 27.9999 19.8333 27.9999Z"
                  fill="white"
                />
                <path
                  d="M26.8332 3.5H24.4998V1.16667C24.4998 0.857247 24.3769 0.560501 24.1581 0.341709C23.9393 0.122916 23.6426 0 23.3332 0C23.0238 0 22.727 0.122916 22.5082 0.341709C22.2894 0.560501 22.1665 0.857247 22.1665 1.16667V3.5H19.8332C19.5238 3.5 19.227 3.62292 19.0082 3.84171C18.7894 4.0605 18.6665 4.35725 18.6665 4.66667C18.6665 4.97609 18.7894 5.27283 19.0082 5.49162C19.227 5.71042 19.5238 5.83333 19.8332 5.83333H22.1665V8.16667C22.1665 8.47609 22.2894 8.77283 22.5082 8.99162C22.727 9.21042 23.0238 9.33333 23.3332 9.33333C23.6426 9.33333 23.9393 9.21042 24.1581 8.99162C24.3769 8.77283 24.4998 8.47609 24.4998 8.16667V5.83333H26.8332C27.1426 5.83333 27.4393 5.71042 27.6581 5.49162C27.8769 5.27283 27.9998 4.97609 27.9998 4.66667C27.9998 4.35725 27.8769 4.0605 27.6581 3.84171C27.4393 3.62292 27.1426 3.5 26.8332 3.5Z"
                  fill="white"
                />
                <path
                  d="M25.3995 11.347C25.2487 11.3188 25.0937 11.3208 24.9436 11.3529C24.7936 11.385 24.6514 11.4465 24.5253 11.534C24.3992 11.6215 24.2917 11.7331 24.209 11.8624C24.1264 11.9917 24.0702 12.1362 24.0438 12.2873C23.8983 13.0948 23.4737 13.8256 22.8442 14.3519C22.2147 14.8781 21.4203 15.1665 20.5998 15.1667H6.321L5.22433 5.83333H15.1667C15.4761 5.83333 15.7728 5.71042 15.9916 5.49162C16.2104 5.27283 16.3333 4.97609 16.3333 4.66667C16.3333 4.35725 16.2104 4.0605 15.9916 3.84171C15.7728 3.62292 15.4761 3.5 15.1667 3.5H4.949L4.9 3.08933C4.79946 2.23837 4.39024 1.45385 3.74989 0.884455C3.10953 0.315063 2.28255 0.000364976 1.42567 0L1.16667 0C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667C0 1.47609 0.122916 1.77283 0.341709 1.99162C0.560501 2.21042 0.857247 2.33333 1.16667 2.33333H1.42567C1.71142 2.33337 1.98723 2.43828 2.20077 2.62817C2.41431 2.81805 2.55073 3.07971 2.58417 3.3635L4.1895 17.0135C4.35616 18.4331 5.03822 19.7421 6.10623 20.692C7.17423 21.6419 8.55383 22.1667 9.98317 22.1667H22.1667C22.4761 22.1667 22.7728 22.0438 22.9916 21.825C23.2104 21.6062 23.3333 21.3094 23.3333 21C23.3333 20.6906 23.2104 20.3938 22.9916 20.175C22.7728 19.9562 22.4761 19.8333 22.1667 19.8333H9.98317C9.25927 19.8335 8.55314 19.6091 7.96205 19.1913C7.37095 18.7734 6.92396 18.1825 6.68267 17.5H20.5998C21.9673 17.5001 23.2914 17.0198 24.3408 16.143C25.3902 15.2661 26.0981 14.0484 26.341 12.7027C26.3683 12.5518 26.3655 12.3971 26.3329 12.2473C26.3004 12.0975 26.2386 11.9556 26.1512 11.8297C26.0637 11.7038 25.9523 11.5964 25.8234 11.5136C25.6944 11.4307 25.5504 11.3741 25.3995 11.347Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_326_4788">
                  <rect width="28" height="28" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>

        <div className="flex flex-col gap-6">
          <Link to="#" target="_blank">
            <img src={AdImage} alt="ad" className="w-full" />
          </Link>

          <div className="font-title text-xl font-bold text-white">
            Listas de la compra en curso
          </div>
          <div className="grid grid-cols-1 gap-3">
            {shoppingLists.map((shoppingList) => (
              <ShoppingListItem
                id={shoppingList.id}
                name={shoppingList.name}
                imageUrl={shoppingList.image_url}
                key={shoppingList.id}
                handleDelete={handleDelete}
                handleUpdate={handleUpdate}
                handleDuplicate={handleDuplicate}
              />
            ))}

            {shoppingLists.length === 0 && !loading && (
              <div className="text-buddy-text-2">
                No se encontraron listas de la compra pendientes
              </div>
            )}
          </div>
          <div className="text-buddy-text-1 text-lg font-semibold mt-6">
            Listas de la compra completas
          </div>
          <div className="grid grid-cols-1 gap-3 mt-2">
            {pastShoppingLists.map((shoppingList) => (
              <ShoppingListItem
                id={shoppingList.id}
                name={shoppingList.name}
                imageUrl={shoppingList.image_url}
                key={shoppingList.id}
                handleDelete={handleDelete}
                handleUpdate={handleUpdate}
                handleDuplicate={handleDuplicate}
              />
            ))}
            {pastShoppingLists.length === 0 && !loading && (
              <div className="text-buddy-text-2">
                No se encontraron listas de la compra completas
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingListList;
