import React from 'react'

const ConfirmationDialog = ({ title, description, children }) => {
  return (
    <div
    style={{ background: "rgba(33, 42, 45, 0.95)", position: "relative" }}
    className="w-full h-full primary-bg flex flex-col justify-end items-center px-8 pb-16"
    >
        <div
            style={{ background: "#314650", position: 'relative', padding: "74px 21px 21px 21px", marginBottom: "25px" }}
            className="flex flex-col justify-between w-11/12 h-96 primary-bg rounded-xl shadow-inner ring-inset ring-gray-700 ring-1"
        >
            <h1 style={{ color: 'white', fontSize: '28px', fontWeight: 'semibold' }} className="text-center">{title}</h1>
            <p style={{ color: '#CCCCCC', fontSize: '15px', fontWeight: 'regular', marginBottom: "25px" }} className="text-center">{description}</p>
            {children}
        </div>
    </div>
  )
}

export default ConfirmationDialog