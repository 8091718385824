import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Allergy from '../api/services/Allergy';
import CheckBoxSelect from './forms/CheckBoxSelect';

const ProfileForm8 = () => {
  const navigate = useNavigate();

  const [allergiesIds, setAllergiesIds] = useState([]);
  const [allergiesOptions, setAllergiesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (e) => {
    const data = JSON.parse(localStorage.getItem('register_data'));
    data.allergies_ids = allergiesIds;
    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/profileForm7');
  };

  const onSelectionChange = (selection) => {
    setAllergiesIds(selection);
  };

  async function loadAllergies() {
    try {
      setIsLoading(true);
      const response = await Allergy.list();
      var options = [];
      response.data.forEach((allergy) => {
        options.push({
          value: allergy.id,
          label: allergy.name,
          param: allergy.api_param,
        });
      });
      setAllergiesOptions(options);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setAllergiesIds(data.allergies_ids ?? []);
    }
    loadAllergies();
  }, []);

  return isLoading ? (
    <div className="flex justify-center items-center h-screen">
      <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
    </div>
  ) : (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="flex flex-col justify-between min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col bg-red items-center gap-3 mb-6">
          <Toolbar to={'/profileForm1'}>
            <span>Paso 2</span> <span className="text-primary">de 4</span>
          </Toolbar>
          <div className="flex flex-col bg-red items-center gap-6">
            <h2 className="w-full text-white text-2xl text-center font-semibold">
              Alergias alimentarias
            </h2>
            <h3 className="text-body text-center text-sm">
              Evita reacciones comunes a alergias o intolerancias
            </h3>
            <CheckBoxSelect
              name="allergies_ids"
              onChange={onSelectionChange}
              selection={allergiesIds}
              options={allergiesOptions}
            />
          </div>
        </div>
        <div className="flex flex-col mb-8">
          <p className="text-[10px] font-medium text-buddy-text-7 text-center">
            Los datos que proporcionas nos permiten optimizar tu experiencia en
            la app mediante inteligencia artificial, ofreciéndote
            recomendaciones personalizadas y contenidos adaptados a tus
            necesidades.
          </p>
          <FooterToolbar>
            <PrimaryButton type="button" onClick={handleClick}>
              Siguiente
            </PrimaryButton>
          </FooterToolbar>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm8;
