import React, { useState } from 'react';
import Buddy from '../img/buddy-welcome.png';

const SuscriptionExpired = () => {
  const [loading, setLoading] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));

  const handlePanel = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}create-portal-session`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: user.id }),
        },
      );
      setLoading(false);
      const data = await response.json();
      if (data.url) {
        window.location.href = data.url;
      } else {
        alert('Error: ' + data.error);
      }
    } catch (error) {
      console.error('Portal error:', error);
    }
  };

  return loading ? (
    <div className="flex justify-center items-center h-screen">
      <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
    </div>
  ) : (
    <div className="relative min-h-screen">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6 flex flex-col items-center">
        <img src={Buddy} alt="" className="w-1/2 aspect-square" />
        <div
          image={Buddy}
          description=""
          className="flex flex-col gap-7 relative text-white -m-4"
        >
          <div className="flex flex-col gap-7 bg-buddy-bg-2 p-6 rounded-2xl">
            <div className="text-3xl font-bold">¡Tu Suscripción Expiró!</div>
            <div>
              ¡Ups! Parece que tu suscripción ha finalizado. No te preocupes,
              puedes seguir disfrutando de BuddyMarket suscribiéndote a un nuevo
              plan.
            </div>
            <div>
              Elige entre nuestras opciones mensuales, anuales o trimestrales
              para continuar aprovechando nuestras increíbles funcionalidades.
            </div>
            <div>
              Haz clic abajo para elegir tu plan y volver a disfrutar de una
              experiencia personalizada en tus compras y menús semanales.
            </div>
            <div>¡Gracias por elegir BuddyMarket!</div>
          </div>
          <div
            onClick={handlePanel}
            className="cursor-pointer py-4 px-6 bg-primary rounded-2xl text-center font-semibold"
          >
            Revisar subscripción
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuscriptionExpired;
