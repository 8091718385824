import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

function RecetaIngrE({ idreceta }) {
  const [ingredients, setIngredients] = useState([]);
  const placeholderIcono = 'carrito-compra.png';
  const [nuevoEncabezadoIVisible, setNuevoEncabezadoIVisible] = useState('');
  const [nuevoIngredienteVisible, setNuevoIngredienteVisible] = useState('');
  const [ingredienteVisible, setIngredienteVisible] = useState('');

  const handleIngredienteClick = (e, ingr) => {
    e.preventDefault();
    setNuevoEncabezadoIVisible(0);
    setNuevoIngredienteVisible(0);
    setIngredienteVisible(ingr);
  };

  const handleIngredienteSave = (id, cadena, idreceta) => {
    setNuevoEncabezadoIVisible('');
    setNuevoIngredienteVisible('');
    setIngredienteVisible('');

    const requestOptions = {
      method: 'POST',
      redirect: 'manual',
      body: JSON.stringify([{ id }, { cadena }, { idreceta }]),
    };
    fetch(
      `https://buddymarket.io/dummy/apibm/recipes/updateingredients.php`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((data) => {
        setIngredients(data.data);
      })
      .catch((error) => console.error('Error updating ingredients:', error));
  };

  const handleIngredienteAdd = (e, tipo, cadena, idreceta) => {
    setNuevoEncabezadoIVisible('');
    setNuevoIngredienteVisible('');
    setIngredienteVisible('');

    if (cadena != '') {
      const requestOptions = {
        method: 'POST',
        redirect: 'manual',
        body: JSON.stringify([{ tipo }, { cadena }, { idreceta }]),
      };
      fetch(
        `https://buddymarket.io/dummy/apibm/recipes/addingredient.php`,
        requestOptions,
      )
        .then((response) => response.json())
        .then((data) => {
          setIngredients(data.data);
        })
        .catch((error) => console.error('Error adding ingredient:', error));
    }
  };

  const setCadena = (e, id) => {
    const updatedIngredients = ingredients?.map((ingredient) => {
      if (ingredient.id === id) {
        return { ...ingredient, cadena: e.target.value };
      }
      return ingredient;
    });
    setIngredients(updatedIngredients);
  };

  const clearTextareaContent = (textareaId) => {
    document.getElementById(textareaId).value = '';
  };

  const handleCloseClick = (id, idreceta) => {
    const requestOptions = {
      method: 'POST',
      redirect: 'manual',
      // headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify([{ id }, { idreceta }]),
    };
    fetch(
      `https://buddymarket.io/dummy/apibm/recipes/removeingredient.php`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((data) => {
        setIngredients(data.data);
      })
      .catch((error) => console.error('Error fetching ingredients:', error));
  };

  useEffect(() => {
    fetch(
      `https://buddymarket.io/dummy/apibm/recipes/readingredients.php?id=${idreceta}`,
    )
      .then((response) => response.json())
      .then((data) => {
        setIngredients(data.data);
      })
      .catch((error) => console.error('Error fetching ingredients:', error));
  }, [idreceta]);

  return (
    <>
      <div className="conborde listareceta">
        {ingredients?.map((ingredient) => (
          <div key={ingredient.id} className={`w100 element${ingredient.id}`}>
            {ingredient.tipo == 1 ? (
              <>
                <h3
                  id={`element${ingredient.id}`}
                  onClick={(e) => handleIngredienteClick(e, ingredient.id)}
                  className={
                    ingredienteVisible === ingredient.id
                      ? 'oculto izquierda block w100 mb10'
                      : 'visible izquierda block w100 mb20'
                  }
                >
                  {ingredient.cadena}
                </h3>
                <div
                  className={
                    ingredienteVisible === ingredient.id
                      ? 'visible input-container w100 derecha'
                      : 'oculto input-container w100 h0'
                  }
                >
                  <textarea
                    rows="1"
                    onChange={(e) => setCadena(e, ingredient.id)}
                    onBlur={(e) =>
                      handleIngredienteSave(
                        ingredient.id,
                        ingredient.cadena,
                        idreceta,
                      )
                    }
                    className="w100 mb0"
                    type="text"
                    id="newingredient"
                    defaultValue={ingredient.cadena}
                  />
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="f18 rojo p10 mt0"
                    onClick={(e) => handleCloseClick(ingredient.id, idreceta)}
                  />
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="f18 verde ml20 p10 mt0 "
                    onClick={(e) =>
                      handleIngredienteSave(
                        ingredient.id,
                        ingredient.cadena,
                        idreceta,
                      )
                    }
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  onClick={(e) => handleIngredienteClick(e, ingredient.id)}
                  className={
                    ingredienteVisible === ingredient.id
                      ? 'oculto flex izquierda vcentrado gap10 rgap20 mb10 mt10'
                      : 'visible flex izquierda vcentrado gap10 rgap20 mb10 mt10'
                  }
                >
                  <img
                    className="iconocomida"
                    src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/iconos/${
                      ingredient.icono || placeholderIcono
                    }`}
                    alt={ingredient.cadena}
                  />
                  <strong>
                    {ingredient.ingrediente != null
                      ? ingredient.cantidad +
                        ' ' +
                        ingredient.medida +
                        ' de ' +
                        ingredient.ingrediente
                      : ingredient.cadena}
                  </strong>
                </div>
                <div
                  className={
                    ingredienteVisible === ingredient.id
                      ? 'visible input-container w100 fondoinicio derecha'
                      : 'oculto input-container w100 h0'
                  }
                >
                  <textarea
                    onChange={(e) => setCadena(e, ingredient.id)}
                    className="w100 mb20"
                    type="text"
                    rows="1"
                    id="newinstruction"
                    defaultValue={ingredient.cadena}
                  />
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="f18 rojo p10 mt0"
                    onClick={(e) => handleCloseClick(ingredient.id, idreceta)}
                  />
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="f18 verde ml20 p10 mt0 "
                    onClick={(e) =>
                      handleIngredienteSave(
                        ingredient.id,
                        ingredient.cadena,
                        idreceta,
                      )
                    }
                  />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
      <div>
        <div className="input-container flex gap10 w100">
          <div className="p0 w80">
            <textarea
              rows="1"
              className={
                nuevoEncabezadoIVisible === 1
                  ? 'visible mt20 w100 mb20'
                  : 'oculto h0 p0 b0'
              }
              onBlur={(e) =>
                handleIngredienteAdd(e, 1, e.target.value, idreceta)
              }
              type="text"
              id="newcabecerai"
              placeholder="Encabezado"
            />
            <textarea
              rows="1"
              className={
                nuevoIngredienteVisible === 1
                  ? 'visible w100 mb20 mt20'
                  : 'oculto h0 p0 b0'
              }
              onBlur={(e) =>
                handleIngredienteAdd(e, 0, e.target.value, idreceta)
              }
              type="text"
              id="newparrafoi"
              placeholder="Escribir el ingrediente"
            />
          </div>
          <button
            type="submit"
            className={
              nuevoEncabezadoIVisible === 1 || nuevoIngredienteVisible === 1
                ? 'visible bordeblanco w10 mt20 mb20 f08 h43'
                : 'oculto h0 p0 b0'
            }
          >
            <Link className="textoblanco">Añadir</Link>
          </button>
          {/*<input className="w100 mb20" type="text" id="newfoto" placeholder="Foto"/>*/}
        </div>
        <div className="flex equiespaciado gap10">
          <button
            type="button"
            onClick={() => {
              setNuevoEncabezadoIVisible(1);
              setNuevoIngredienteVisible(0);
              setIngredienteVisible('');
              clearTextareaContent('newcabecerai');
              clearTextareaContent('newparrafoi');
            }}
            className="w30 action fondonaranja"
          >
            Encabezado
          </button>
          <button
            type="button"
            onClick={() => {
              setNuevoIngredienteVisible(1);
              setNuevoEncabezadoIVisible(0);
              setIngredienteVisible('');
              clearTextareaContent('newparrafoi');
              clearTextareaContent('newcabecerai');
            }}
            className="w30 action fondonaranja"
          >
            Ingrediente
          </button>
          {/*<button className="w30 action fondonaranja f08"><Link to="#" className="textoblanco" id="encabezadoinstrucciones">Foto</Link></button>*/}
          {/*<button><Link to="#" className="textonaranja" id="encabezadoinstrucciones">+ Video</Link></button>*/}
        </div>
      </div>
    </>
  );
}
export default RecetaIngrE;
