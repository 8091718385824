import React, { useState, useEffect } from 'react';
import Toolbar from './ui/Toolbar';
import { useNavigate } from 'react-router-dom';
import CheckBoxSelect from './forms/CheckBoxSelect';
import FooterToolbar from './ui/FooterToolbar';
import DietRestriction from '../api/services/DietRestriction';
import User from '../api/services/User';
import PrimaryButton from './ui/PrimaryButton';

const DietaryPreferencesRestrictions = () => {
  const navigate = useNavigate();

  const [dietRestrictionIds, setDietRestrictionIds] = useState([]);
  const [dietRestrictionOptions, setDietRestrictionOptions] = useState([]);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (e) => {
    try {
      setIsSaveLoading(true);
      const user = JSON.parse(localStorage.getItem('user'));
      await User.updateDietRestrictions(user.id, {
        diet_restriction_ids: dietRestrictionIds,
      });
      setIsSaveLoading(false);
    } catch (error) {
      setIsSaveLoading(false);
      console.log(error);
    }
    navigate('/dietaryPreferences');
  };

  const onSelectionChange = (selection) => {
    setDietRestrictionIds(selection);
  };

  const loadDietRestrictions = async () => {
    try {
      setIsLoading(true);
      const response = await DietRestriction.list();
      var options = [];
      response.data.forEach((restriction) => {
        options.push({
          value: restriction.id,
          label: restriction.name,
          param: restriction.api_param,
        });
      });
      setDietRestrictionOptions(options);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const loadUserRestrictions = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const response = await User.getDietRestrictions(user.id);
      const dietRestrictionIds = response.data.map(
        (restriction) => restriction.id,
      );
      setDietRestrictionIds(dietRestrictionIds);
    } catch (error) {
      console.log(error);
      setIsSaveLoading(false);
    }
  };

  useEffect(() => {
    loadDietRestrictions();
    loadUserRestrictions();
  }, []);

  return isLoading ? (
    <div className="flex justify-center items-center h-screen">
      <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
    </div>
  ) : (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="flex flex-col justify-between min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col bg-red items-center gap-3 mb-6">
          <Toolbar to={'/dietaryPreferencesAllergies'}>
            <span>Paso 2</span> <span className="text-primary">de 2</span>
          </Toolbar>
          <div className="flex flex-col bg-red items-center gap-6">
            <h2 className="w-full text-white text-2xl text-center font-semibold">
              Estilos y Restricciones Dietéticas
            </h2>
            <h3 className="text-body text-center text-sm">
              Personaliza tu experiencia con BuddyMarket según tus necesidades y
              estilo de vidaEvita reacciones comunes a alergias o intolerancias
            </h3>
            <CheckBoxSelect
              name="allergies_ids"
              onChange={onSelectionChange}
              selection={dietRestrictionIds}
              options={dietRestrictionOptions}
            />
          </div>
        </div>
        <div className="flex flex-col mb-8">
          <p className="text-[10px] font-medium text-buddy-text-7 text-center">
            Los datos que proporcionas nos permiten optimizar tu experiencia en
            la app mediante inteligencia artificial, ofreciéndote
            recomendaciones personalizadas y contenidos adaptados a tus
            necesidades.
          </p>
          <FooterToolbar>
            <PrimaryButton type="button" onClick={handleClick}>
              {isSaveLoading ? (
                <div className="w-full flex justify-center items-center">
                  <div className="w-6 h-6 text-center rounded-full border-2 border-t-white border-r-white border-b-gray-500 border-l-gray-500 animate-spin"></div>
                </div>
              ) : (
                'Guardar'
              )}
            </PrimaryButton>
          </FooterToolbar>
        </div>
      </div>
    </div>
  );
};

export default DietaryPreferencesRestrictions;
