import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import FooterMain from './FooterMain';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import nutriscore from '../img/nutriscore.png';

const RecetaSemana = () => {
  const [receta, setReceta] = useState([]);
  const [buddymaker, setBuddymaker] = useState([]);
  const { id } = useParams();
  const [mostrarCapa1, setMostrarCapa1] = useState(true);

  const toggleCapas = () => {
    // Cambiar el estado para alternar entre mostrar la capa 1 y la capa 2
    setMostrarCapa1(!mostrarCapa1);
  };

  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/users/readbuddyrec.php?id=2`)
      .then((response) => response.json())
      .then((data) => {
        setBuddymaker(data.data);
      })
      .catch((error) => console.error('Error fetching buddymaker:', error));
  }, []);

  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/recipes/read.php/?id=2`)
      .then((response) => response.json())
      .then((data) => {
        setReceta(data.data);
      })
      .catch((error) => console.error('Error fetching recipe:', error));
  }, []);

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Receta</h2>
      </div>
      <div className="rectangulo round relative fondooscuro">
        <div className="flex nowrap centrado">
          <h2>La receta de la semana</h2>
        </div>
      </div>
      <div className="rectangulo round relative fondooscuro">
        <div className="flex nowrap derecha vcentrado">
          {buddymaker.map((buddym) => (
            <div key={buddym.id}>
              <a className="block derecha" href={`../buddymaker/${buddym.id}`}>
                <img
                  src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/users/${buddym.foto}`}
                />
                <span className="minititulo">{buddym.username}</span>
              </a>
            </div>
          ))}
        </div>
        <br />
        {receta.map((rec) => (
          <div>
            <h2 className="mb20">{rec.name}</h2>
            <img
              className="w100 round mb20"
              src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${rec.photo}`}
              alt="Carabineros a la plancha"
            />
            <br />
            <div className="w100 mb20">{rec.description}</div>
            <div className="w100">
              <span className="w80 inlineblock izquierda">
                <span className="naranja grande">
                  <a href="#">
                    <FontAwesomeIcon icon={faBookmark} />
                  </a>
                </span>
                &nbsp;&nbsp;
                <span className="pequeno">
                  {' '}
                  <span className="naranja"> 322</span> Personas guardaron esto
                </span>
              </span>
              <span className="w20 inlineblock derecha">
                <a href="#">
                  <span className="naranja grande">
                    <FontAwesomeIcon icon={faShareNodes} />
                  </span>
                </a>
              </span>
            </div>
          </div>
        ))}
      </div>

      <div className="rectangulo round relative fondooscuro">
        <div className="flex nowrap equiespaciado centrado">
          <div className="w30 ladoizqnaranja">
            <a href="#" onClick={toggleCapas}>
              <span className="naranja">2</span>
              <br />
              <span className="textoblanco pequeno">Ingredientes</span>
            </a>
          </div>
          <div className="w30">
            <a href="#">
              <span className="naranja">1h6min</span>
              <br />
              <span className="textoblanco pequeno">Instrucciones</span>
            </a>
          </div>
          <div className="w30">
            <a href="#">
              <img src={nutriscore} alt="nutriscore" />
              <br />
              <span className="textoblanco pequeno">Valores nutricionales</span>
            </a>
          </div>
        </div>
        {receta.map((rec) => (
          <div id="contenidos">
            <div className="botoneraraciones vcentrado gap20 flex">
              <span className="cuadradillo centrado" id="menosracion">
                {' '}
                -{' '}
              </span>{' '}
              <span id="numraciones"> 2 raciones </span>{' '}
              <span className="cuadradillo centrado" id="masracion">
                {' '}
                +{' '}
              </span>
            </div>
            <div
              className="ingredientes mt10"
              dangerouslySetInnerHTML={{ __html: rec.ingredients }}
            ></div>
            <div
              className="instrucciones mt10"
              dangerouslySetInnerHTML={{ __html: rec.istructions }}
            ></div>
          </div>
        ))}
      </div>
      <br />
      <br />
      <FooterMain />
    </div>
  );
};

export default RecetaSemana;
