import React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getUserInfo } from '../auth';

const AgregarRecetaMenu = () => {
  const navigate = useNavigate();
  const [dia, setDia] = useState('');
  const [momento, setMomento] = useState('');
  const userInfo = getUserInfo();
  const usuario = userInfo.id;

  const [searchQuery, setSearchQuery] = useState('');
  const [recipes, setRecipes] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const fetchRecipes = async () => {
    if (!!searchQuery) {
      try {
        const response = await fetch(
          `https://buddymarket.io/dummy/apibm/recipes/searchrecipes.php?query=${searchQuery}`,
        );
        const data = await response.json();
        setRecipes(data.data);
      } catch (error) {
        console.error('Error fetching recipes:', error);
      }
    }
  };

  const handleSearchChange = async (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value.length > 2) {
      await fetchRecipes();
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  };

  const { id } = useParams();
  const hoy = id;

  function formatDateSql(dateString) {
    const [day, month, year] = dateString.split('/');
    const paddedMonth = month.padStart(2, '0');
    const paddedDay = day.padStart(2, '0');

    return `${year}-${paddedMonth}-${paddedDay}`;
  }

  const getWeekData = () => {
    // Obtener la fecha actual
    const today = new Date(Date.parse(hoy));
    const todayFormatted = today.toLocaleDateString();
    const todaySql = formatDateSql(todayFormatted);

    // Obtener el número de la semana del año
    const weekNumber = getWeekNumber(today);

    // Obtener las fechas de cada día de la semana
    const weekDates = getWeekDates(today);

    // Formatear las fechas al formato local
    const formattedWeekDates = weekDates.map((date) =>
      date.toLocaleDateString(),
    );

    // Formatear las fechas al formato sql
    const sqlWeekDates = formattedWeekDates.map((date) => formatDateSql(date));

    // Formatear fechas para los links
    const dashRegex = /-/g;
    const linkWeekDates = sqlWeekDates.map((date) =>
      date.replace(dashRegex, ''),
    );

    // Obtener el índice del día actual en la semana
    const todayIndex = formattedWeekDates.indexOf(todayFormatted);

    // Obtener el nombre del día actual en la semana
    const todayText = getDayOfWeekText(todayIndex);

    // Inicializar dia y momento

    if (!dia) {
      setDia(sqlWeekDates[0]);
    }
    if (!momento) {
      setMomento('desayuno');
    }

    // Devolver un objeto con la información
    return {
      weekNumber,
      weekDates: formattedWeekDates,
      todayIndex,
      todayFormatted,
      todaySql,
      sqlWeekDates,
      linkWeekDates,
      todayText,
    };
  };

  // Función para obtener el número de la semana del año
  function getWeekNumber(date) {
    // Obtener el primer día del año
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);

    // Obtener la diferencia en días entre la fecha actual y el primer día del año
    const daysSinceStartOfYear = Math.floor(
      (date - firstDayOfYear) / (1000 * 60 * 60 * 24),
    );

    // Calcular el número de la semana
    const weekNumber = Math.ceil((daysSinceStartOfYear + 1) / 7);

    // Devolver el número de la semana
    return weekNumber;
  }

  // Función para obtener las fechas de cada día de la semana
  function getWeekDates(date) {
    // Obtener el día de la semana de la fecha actual
    const dayOfWeek = date.getDay();

    // Obtener la fecha del lunes de la semana actual
    const mondayDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - dayOfWeek + 1,
    );

    // Crear un array para almacenar las fechas de la semana
    const weekDates = [];

    // Recorrer los días de la semana
    for (let i = 0; i < 7; i++) {
      // Obtener la fecha del día actual
      const dayDate = new Date(
        mondayDate.getFullYear(),
        mondayDate.getMonth(),
        mondayDate.getDate() + i,
      );

      // Agregar la fecha al array
      weekDates.push(dayDate);
    }

    // Devolver el array de fechas
    return weekDates;
  }

  function getDayOfWeekText(dayNumber) {
    // Array con los nombres de los días de la semana
    const daysOfWeek = [
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
      'Domingo',
    ];
    if (dayNumber == -1) {
      dayNumber = 6;
    }

    // Si el número de día no está entre 0 y 6, devolver "Día no válido"
    if (dayNumber < 0 || dayNumber > 6) {
      return 'Día no válido';
    }

    // Devolver el nombre del día de la semana
    return daysOfWeek[dayNumber];
  }

  const handleRecipeClick = async (recipeId) => {
    try {
      // Envío de la información del ID de la receta y los valores de los desplegables a la API

      const requestOptions = {
        method: 'POST',
        redirect: 'manual',
        //headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ recipeId, dia, momento, usuario }),
      };
      const response = await fetch(
        'https://buddymarket.io/dummy/apibm/menus/addrecipetomenu.php',
        requestOptions,
      );
      const data = await response.json();
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleChangeDia = (e) => {
    setDia(e.target.value);
  };

  const handleChangeMomento = (e) => {
    setMomento(e.target.value);
  };

  const {
    weekNumber,
    weekDates,
    todayIndex,
    todayFormatted,
    todaySql,
    sqlWeekDates,
    linkWeekDates,
    todayText,
  } = getWeekData();

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Agregar al menú</h2>
      </div>

      <div className="centrado">
        <div className="tiempococcion mb10">
          <div className="flex centrado w100">
            <label className="mb10 w50 izquierda">
              Día
              <br />
              <select
                name="dia"
                className="w100"
                defaultValue={dia}
                onChange={handleChangeDia}
              >
                <option value={sqlWeekDates[0]}>lunes</option>
                <option value={sqlWeekDates[1]}>martes</option>
                <option value={sqlWeekDates[2]}>miércoles</option>
                <option value={sqlWeekDates[3]}>jueves</option>
                <option value={sqlWeekDates[4]}>viernes</option>
                <option value={sqlWeekDates[5]}>sábado</option>
                <option value={sqlWeekDates[6]}>domingo</option>
              </select>
            </label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <label className="mb10 w50 izquierda">
              Momento
              <br />
              <select
                name="momento"
                defaultValue={momento}
                className="w100"
                onChange={handleChangeMomento}
              >
                <option value="desayuno">Desayuno</option>
                <option value="aperitivo">Aperitivo</option>
                <option value="almuerzo">Almuerzo</option>
                <option value="merienda">Merienda</option>
                <option value="cena">Cena</option>
              </select>
            </label>
          </div>
        </div>
      </div>

      <div className="rectangulo busqueda">
        <form id="busqueda" action="buscar">
          <label className="izquierda">Buscar receta</label>
          <div className="input-container">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon2" />
            <input
              className="w100"
              type="text"
              name="user"
              autoComplete="off"
              placeholder="Buscar en BuddyMarket"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <br />
          </div>
        </form>
      </div>

      <div className="rectangulo round relative fondooscuro">
        <h2 className="pequeno mb20">Recetas encontradas:</h2>
        <div className="flex nowrap equiespaciado">
          <ul className="m0 p0">
            {recipes?.map((recipe) => (
              <li
                key={recipe.id}
                className="flex spacebetween vcentrado gap20 mb10"
              >
                <span className="w40">
                  <img
                    style={{ verticalAlign: 'middle' }}
                    src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${recipe.photo}`}
                    alt={recipe.name}
                  />
                </span>
                <span className="textoblanco w100">{recipe.name}</span>
                <button
                  onClick={() => handleRecipeClick(recipe.id)}
                  type="button"
                  className="button-aux fondonaranja textoblanco derecha w30"
                >
                  Añadir
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <br />
      <br />
    </div>
  );
};

export default AgregarRecetaMenu;
