import React from 'react';
import ButtonWithImage from '../ButtonWithImage';
import Celery from '../../img/allergies/celery.png';
import Egg from '../../img/allergies/egg.png';
import Fish from '../../img/allergies/fish.png';
import Fructose from '../../img/allergies/fructose.png';
import Gluten from '../../img/allergies/gluten.png';
import Lactose from '../../img/allergies/lactose.png';
import Lupine from '../../img/allergies/lupine.png';
import Mustard from '../../img/allergies/mustard.png';
import Nuts from '../../img/allergies/nuts.png';
import Peanut from '../../img/allergies/peanut.png';
import SeaFood from '../../img/allergies/seafood.png';
import Sesame from '../../img/allergies/sesame.png';
import Soy from '../../img/allergies/soy.png';
import Citrus from '../../img/allergies/citrus.png';
import Sulfites from '../../img/allergies/sulfites.png';
import Sweeteners from '../../img/allergies/sweeteners.png';
import Coloring from '../../img/allergies/coloring.png';

import Ovolactovegeratian from '../../img/diet/ovolactovegetarian.png';
import Vegetarian from '../../img/diet/vegetarian.png';
import Vegan from '../../img/diet/vegan.png';
import Flexitaria from '../../img/diet/flexitaria.png';
import Kosher from '../../img/diet/kosher.png';
import Halal from '../../img/diet/halal.png';
import Hindu from '../../img/diet/hindu.png';
import LowMercury from '../../img/diet/lowMercury.png';
import LowSodium from '../../img/diet/lowSodium.png';
import Keto from '../../img/diet/keto.png';
import LactoseFree from '../../img/diet/lactose-free.png';
import Paleo from '../../img/diet/paleo.png';
import Macrobiota from '../../img/diet/macrobiota.png';
import Pescetarian from '../../img/diet/pescetarian.png';
import Diabetes from '../../img/diet/diabetes.png';
import UricAcid from '../../img/diet/uric-acid.png';
import Hypertensive from '../../img/diet/hypertensive.png';
import Antiinflammatory from '../../img/diet/anti-inflammatory.png';
import Crohn from '../../img/diet/crohn.png';
import Hypothyroidism from '../../img/diet/hypothyroidism.png';
import GlutenFree from '../../img/diet/gluten-free.png';

import AirfryerCategory from '../../img/categories/airfryer.png';
import AmericanCategory from '../../img/categories/american.png';
import BBQCategory from '../../img/categories/bbq.png';
import BreakfastCategory from '../../img/categories/breakfast.png';
import ChildrenCategory from '../../img/categories/children.png';
import ChristmasCategory from '../../img/categories/christmas.png';
import DessertsCategory from '../../img/categories/desserts.png';
import GlutenFreeCategory from '../../img/categories/gluten-free.png';
import International from '../../img/categories/international.png';
import ItalianCategory from '../../img/categories/italian.png';
import KetoCategory from '../../img/categories/keto.png';
import LowCalCategory from '../../img/categories/low-cal.png';
import MediterraneanCategory from '../../img/categories/mediterranean.png';
import SaladsCategory from '../../img/categories/salads.png';
import SnacksCategory from '../../img/categories/snacks.png';
import SpoonCategory from '../../img/categories/spoon.png';
import TeaTimeCategory from '../../img/categories/tea-time.png';
import VeganCategory from '../../img/categories/vegan.png';
import VegetarianCategory from '../../img/categories/vegetarian.png';

const IMAGE_MAP = {
  'celery-free': Celery,
  'peanut-free': Peanut,
  'egg-free': Egg,
  'soy-free': Soy,
  'tree-nut-free': Nuts,
  'fructose-free': Fructose,
  'gluten-free': Gluten,
  'lactose-free': Lactose,
  'lupine-free': Lupine,
  'fish-free': Fish,
  'shellfish-free': SeaFood,
  'sesame-free': Sesame,
  'mustard-free': Mustard,
  'citrus-free': Citrus,
  'sulfites-free': Sulfites,
  'sweeteners-free': Sweeteners,
  'coloring-free': Coloring,

  ovolactovegetarian: Ovolactovegeratian,
  vegetarian: Vegetarian,
  vegan: Vegan,
  flexitaria: Flexitaria,
  kosher: Kosher,
  halal: Halal,
  hindu: Hindu,
  'low-mercury': LowMercury,
  'low-sodium': LowSodium,
  keto: Keto,
  'lactose-free': LactoseFree,
  paleo: Paleo,
  macrobiota: Macrobiota,
  pescetarian: Pescetarian,
  diabetes: Diabetes,
  'uric-acid': UricAcid,
  hypertensive: Hypertensive,
  'anti-inflammatory': Antiinflammatory,
  crohn: Crohn,
  hypothyroidism: Hypothyroidism,
  'gluten-free': GlutenFree,

  'air-fryer-category': AirfryerCategory,
  'american-category': AmericanCategory,
  'bbq-category': BBQCategory,
  'breakfast-category': BreakfastCategory,
  'children-category': ChildrenCategory,
  'christmas-category': ChristmasCategory,
  'desserts-category': DessertsCategory,
  'gluten-free-category': GlutenFreeCategory,
  'international-category': International,
  'italian-category': ItalianCategory,
  'keto-category': KetoCategory,
  'low-cal-category': LowCalCategory,
  'mediterranean-category': MediterraneanCategory,
  'salads-category': SaladsCategory,
  'snacks-category': SnacksCategory,
  'spoon-category': SpoonCategory,
  'tea-time-category': TeaTimeCategory,
  'vegan-category': VeganCategory,
  'vegerarian-category': VegetarianCategory,
};

const CheckBoxSelect = ({ selection, onChange = null, options = [] }) => {
  const handleOptionSelected = (value) => {
    var currentSelection = selection.slice();
    if (!currentSelection.includes(value)) {
      currentSelection.push(value);
    } else {
      currentSelection.splice(currentSelection.indexOf(value), 1);
    }
    if (onChange != null) {
      onChange(currentSelection);
    }
  };

  return (
    <>
      <div className="w-full">
        <div className="flex flex-wrap gap-4">
          {options.map((option) => {
            const { value, label, param } = option;
            return (
              <ButtonWithImage
                key={value}
                text={label}
                image={IMAGE_MAP[param]}
                data-value={value}
                selected={selection.includes(value)}
                onClick={() => handleOptionSelected(value)}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CheckBoxSelect;
