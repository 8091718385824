import React, { useState, useEffect } from 'react';
import Toolbar from './ui/Toolbar';
import { useNavigate } from 'react-router-dom';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';

const HealthPreferencesShopping = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    purchase_frequency: '',
    purchase_location: '',
    average_shopping_time: '',
    cook_in_large_quantities: '',
  });

  const handleClick = (e) => {
    const user = JSON.parse(localStorage.getItem('user'));
    user.purchase_frequency = data.purchase_frequency;
    user.purchase_location = data.purchase_location;
    user.average_shopping_time = data.average_shopping_time;
    user.cook_in_large_quantities = data.cook_in_large_quantities;
    localStorage.setItem('user', JSON.stringify(user));
    navigate('/healthPreferencesFactors');
  };

  const loadUserData = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setData({
        purchase_frequency: user.purchase_frequency ?? '',
        purchase_location: user.purchase_location ?? '',
        average_shopping_time: user.average_shopping_time ?? '',
        cook_in_large_quantities: user.cook_in_large_quantities ?? '',
      });
    }
  };

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="flex flex-col justify-between min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col bg-red  gap-3 mb-6">
          <Toolbar to={'/healthPreferencesHydration'}>
            <span>Paso 6</span> <span className="text-primary">de 7</span>
          </Toolbar>
          <div className="flex flex-col bg-red items-center gap-6 mb-8">
            <h2 className="w-full text-white text-xl text-center font-semibold">
              ¿Con qué frecuencia haces la compra?
            </h2>
            <div className="w-full shrink-0 grow h-[44px] bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-transparent ring-1 mb-8">
              <input
                onChange={(e) =>
                  setData({ ...data, purchase_frequency: e.target.value })
                }
                value={data.purchase_frequency}
                aria-autocomplete="none"
                list="autocompleteOff"
                className="appearance-none border-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl mb-2"
                name="recipe_step"
                type="text"
                placeholder="Frecuencia de compra"
              />
              <span className="text-buddy-text-7 text-xs ml-3">
                Ej: 1 vez al mes{' '}
              </span>
            </div>
            <h2 className="w-full text-white text-xl text-center font-semibold">
              ¿Dónde sueles hacer la compra de alimentos?
            </h2>
            <div className="w-full shrink-0 grow h-[44px] bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-transparent ring-1 mb-8">
              <input
                onChange={(e) =>
                  setData({ ...data, purchase_location: e.target.value })
                }
                value={data.purchase_location}
                aria-autocomplete="none"
                list="autocompleteOff"
                className="appearance-none border-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl mb-2"
                name="recipe_step"
                type="text"
                placeholder="Lugar de compra"
              />
              <span className="text-buddy-text-7 text-xs ml-3">
                Ej: Mercadona, Carrefour, Aldi{' '}
              </span>
            </div>
            <h2 className="w-full text-white text-xl text-center font-semibold">
              ¿Cuánto tiempo dedicas en promedio a hacer la compra?
            </h2>
            <div className="w-full shrink-0 grow h-[44px] bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-transparent ring-1 mb-8">
              <input
                onChange={(e) =>
                  setData({ ...data, average_shopping_time: e.target.value })
                }
                value={data.average_shopping_time}
                aria-autocomplete="none"
                list="autocompleteOff"
                className="appearance-none border-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl mb-2"
                name="recipe_step"
                type="text"
                placeholder="Tiempo promedio de compra"
              />
              <span className="text-buddy-text-7 text-xs ml-3">
                Ej: 20 minutos al dia
              </span>
            </div>
            <h2 className="w-full text-white text-xl text-center font-semibold">
              ¿Sueles cocinar en grandes cantidades para varios días?
            </h2>
            <div className="w-full shrink-0 grow h-[44px] bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-transparent ring-1 mb-8">
              <input
                onChange={(e) =>
                  setData({ ...data, cook_in_large_quantities: e.target.value })
                }
                value={data.cook_in_large_quantities}
                aria-autocomplete="none"
                list="autocompleteOff"
                className="appearance-none border-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl mb-2"
                name="recipe_step"
                type="text"
                placeholder="Cocinas en grandes cantidades"
              />
              <span className="text-buddy-text-7 text-xs ml-3">
                Ej: No muchas
              </span>
            </div>
          </div>
        </div>
        <FooterToolbar>
          <PrimaryButton type="button" onClick={handleClick}>
            Siguiente
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default HealthPreferencesShopping;
