import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login';
import SignUp from './components/SignUp.js';
import ResetPassword from './components/ResetPassword.js';
import ProfileForm1 from './components/ProfileForm1.js';
import ProfileForm2 from './components/ProfileForm2.js';
import ProfileForm3 from './components/ProfileForm3.js';
import ProfileForm4 from './components/ProfileForm4.js';
import ProfileForm5 from './components/ProfileForm5.js';
import ProfileForm6 from './components/ProfileForm6.js';
import ProfileForm7 from './components/ProfileForm7.js';
import ProfileForm8 from './components/ProfileForm8.js';
import ProfileForm9 from './components/ProfileForm9.js';
import ProfileForm10 from './components/ProfileForm10.js';
import ProfileForm11 from './components/ProfileForm11.js';
import ProfileFormPremiumPlan from './components/ProfileFormPremiumPlan.js';
import ProfileFormPremiumPayment from './components/ProfileFormPremiumPayment.js';
import Suscripcion from './components/Suscripcion';
import Suscripcion1 from './components/Suscripcion1';
import Suscripcion2 from './components/Suscripcion2';
import Suscripcion3 from './components/Suscripcion3';
import Suscripcionfin from './components/SuscripcionFin';
import RecCont from './components/RecCont';
import RecCont2 from './components/RecCont2';
import RecCont3 from './components/RecCont3';
import RecContFin from './components/RecContFin';
import Recetas from './components/Recetas';
import RecetasTodas from './components/RecetasTodas';
import Receta from './components/Receta';
import RecetaEdit from './components/RecetaEdit';
import RecetaBorrar from './components/RecetaBorrar';
import Categorias from './components/Categorias';
import FoodCategoryList from './components/FoodCategoryList';
import Categoria from './components/Categoria';
import Dashboard from './components/Dashboard';
import About from './components/About';
import Contact from './components/Contact';
import Buddy from './components/Buddy';
import BuddyResumen from './components/BuddyResumen';
import BuddyChat from './components/BuddyChat';
import Buddymakers from './components/Buddymakers';
import Buddymaker from './components/Buddymaker';
import MisRecetas from './components/MisRecetas';
import RecetasGuardadas from './components/RecetasGuardadas';
import RecetasGuardadasTodas from './components/RecetasGuardadasTodas';
import RecetaSemana from './components/RecetaSemana';
import NuevaReceta from './components/RecetaNueva';
import TerminosyCondiciones from './components/TerminosyCondiciones';
import P400 from './components/P400';
import Perfil from './components/Perfil';
import PerfilEdit from './components/PerfilEdit';
import Preferencias from './components/Preferencias';
import ListasDeLaCompra from './components/ListasDeLaCompra';
import ListaDeLaCompra from './components/ListaDeLaCompra';
import ListaDeLaCompraNueva from './components/ListaDeLaCompraNueva';
import Compartir from './components/Compartir.js';
import Menus from './components/Menus.js';
import MenuDia from './components/MenuDia.js';
import AgregarRecetaMenu from './components/AgregarRecetaMenu.js';
import SidebarMenu from './components/SidebarMenu.js';
import UserProfile from './components/UserProfile.js';
import BuddymakerProfile from './components/BuddymakerProfile.js';
import Security from './components/Security.js';
import UserUpdatePassword from './components/UserUpdatePassword.js';
import DietaryPreferences from './components/DietaryPreferences.js';
import DietaryPreferencesAllergies from './components/DietaryPreferencesAllergies.js';
import DietaryPreferencesRestrictions from './components/DietaryPreferencesRestrictions.js';
import HealthPreferencesSpending from './components/HealthPreferencesSpending.js';
import HealthPreferencesPersonalData from './components/HealthPreferencesPersonalData.js';
import HealthPreferencesMedicalCondition from './components/HealthPreferencesMedicalCondition.js';
import HealthPreferencesHydration from './components/HealthPreferencesHydration.js';
import HealthPreferencesRestTime from './components/HealthPreferencesRestTime.js';
import HealthPreferencesShopping from './components/HealthPreferencesShopping.js';
import HealthPreferencesFactors from './components/HealthPreferencesFactors.js';
import UserUpdateEmail from './components/UserUpdateEmail.js';
import UserRevokeSocialAccess from './components/UserRevokeSocialAccess.js';
import UserLanguageSelect from './components/UserLanguageSelect.js';
import UserNotificationsSelect from './components/UserNotificationsSelect.js';
import UserFaqs from './components/UserFaqs.js';
import Recipes from './components/Recipes.js';
import ProfileForm12 from './components/ProfileForm12.js';
import ProfileForm13 from './components/ProfileForm13.js';
import ProfileForm14 from './components/ProfileForm14.js';
import ProfileForm15 from './components/ProfileForm15.js';
import ProfileForm16 from './components/ProfileForm16.js';
import ProfileForm17 from './components/ProfileForm17.js';
import ProfileForm18 from './components/ProfileForm18.js';
import ProfileForm19 from './components/ProfileForm19.js';
import ProfileForm20 from './components/ProfileForm20.js';
import ProfileForm21 from './components/ProfileForm21.js';
import ProfileForm22 from './components/ProfileForm22.js';
import ProfileForm23 from './components/ProfileForm23.js';
import ProfileForm24 from './components/ProfileForm24.js';
import ProfileForm25 from './components/ProfileForm25.js';
import ProfileForm26 from './components/ProfileForm26.js';
import ProfileForm27 from './components/ProfileForm27.js';
import ProfileForm28 from './components/ProfileForm28.js';
import RecipesSearch from './components/RecipesSearch.js';
import RecipeCreate from './components/RecipeCreate.js';
import RecipeSteps from './components/RecipeSteps.js';
import RecipeList from './components/RecipeList.js';
import RecipeEdit from './components/RecipeEdit.js';
import RecipeEditIngredients from './components/RecipeEditIngredients.js';
import RecipeEditSteps from './components/RecipeEditSteps.js';
import RecipeStepEdit from './components/RecipeStepEdit.js';
import RecipeIngredientEdit from './components/RecipeIngredientEdit.js';
import RecipeIngredientCreate from './components/RecipeIngredientCreate.js';
import RecipeStepCreate from './components/RecipeStepCreate.js';
import RecipeShow from './components/RecipeShow.js';
import RecomendedRecipes from './components/RecomendedRecipes.js';
import BuddyMakerList from './components/BuddyMakerList.js';
import MenuOrganizerList from './components/MenuOrganizerList.js';
import MenuOrganizerShow from './components/MenuOrganizerShow.js';
import MenuOrganizerDayPartShow from './components/MenuOrganizerDayPartShow.js';
import MenuOrganizerCreate from './components/MenuOrganizerCreate.js';
import MenuOrganizerDayShow from './components/MenuOrganizerDayShow.js';
import ShoppingListList from './components/ShoppingListList';
import ShoppingListCreate from './components/ShoppingListCreate.js';
import ShoppingListShow from './components/ShoppingListShow';
import ComingSoon from './components/ComingSoon.js';
import PaymentMethods from './components/PaymentMethods.js';
import ShoppingListShowMercadonaDB from './components/ShoppingListShowMercadonaDB.js';
import SuscriptionSuccess from './components/SuscriptionSuccess.js';
import PaymentProcessing from './components/PaymentProcessing.js';
import SuscriptionExpired from './components/SuscriptionExpired.js';
import SignUpConfirm from './components/SignUpConfirm.js';
import PaymentBridge from './components/PaymentBridge.js';
import Test from './components/Test.js';

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/payment-processing" element={<PaymentProcessing />} />
      <Route path="/suscriptionSuccess" element={<SuscriptionSuccess />} />
      <Route path="/suscriptionExpired" element={<SuscriptionExpired />} />
      <Route path="/test" element={<Test />} />

      {/* Protected Routes */}
      <Route path="/profileForm1" element={<ProfileForm1 />} />
      <Route path="/profileForm2" element={<ProfileForm2 />} />
      <Route path="/profileForm3" element={<ProfileForm3 />} />
      <Route path="/profileForm4" element={<ProfileForm4 />} />
      <Route path="/profileForm5" element={<ProfileForm5 />} />
      <Route path="/profileForm6" element={<ProfileForm6 />} />
      <Route path="/profileForm7" element={<ProfileForm7 />} />
      <Route path="/profileForm8" element={<ProfileForm8 />} />
      <Route path="/profileForm9" element={<ProfileForm9 />} />
      <Route path="/profileForm10" element={<ProfileForm10 />} />
      <Route path="/profileForm11" element={<ProfileForm11 />} />
      <Route path="/profileForm12" element={<ProfileForm12 />} />
      <Route path="/profileForm13" element={<ProfileForm13 />} />
      <Route path="/profileForm14" element={<ProfileForm14 />} />
      <Route path="/profileForm15" element={<ProfileForm15 />} />
      <Route path="/profileForm16" element={<ProfileForm16 />} />
      <Route path="/profileForm17" element={<ProfileForm17 />} />
      <Route path="/profileForm18" element={<ProfileForm18 />} />
      <Route path="/profileForm19" element={<ProfileForm19 />} />
      <Route path="/profileForm20" element={<ProfileForm20 />} />
      <Route path="/profileForm21" element={<ProfileForm21 />} />
      <Route path="/profileForm22" element={<ProfileForm22 />} />
      <Route path="/profileForm23" element={<ProfileForm23 />} />
      <Route path="/profileForm24" element={<ProfileForm24 />} />
      <Route path="/profileForm25" element={<ProfileForm25 />} />
      <Route path="/profileForm26" element={<ProfileForm26 />} />
      <Route path="/profileForm27" element={<ProfileForm27 />} />
      <Route path="/profileForm28" element={<ProfileForm28 />} />
      <Route
        path="/profileFormPremiumPlan"
        element={<ProfileFormPremiumPlan />}
      />
      <Route
        path="/profileFormPremiumPayment"
        element={<ProfileFormPremiumPayment />}
      />
      <Route
        path="/signUpConfirm"
        element={
          <ProtectedRoute>
            <SignUpConfirm />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sidebarMenu"
        element={
          <ProtectedRoute>
            <SidebarMenu />
          </ProtectedRoute>
        }
      />
      <Route
        path="/userProfile"
        element={
          <ProtectedRoute>
            <UserProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/buddyMakerProfile"
        element={
          <ProtectedRoute>
            <BuddymakerProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/security"
        element={
          <ProtectedRoute>
            <Security />
          </ProtectedRoute>
        }
      />
      <Route
        path="/userUpdatePassword"
        element={
          <ProtectedRoute>
            <UserUpdatePassword />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dietaryPreferences"
        element={
          <ProtectedRoute>
            <DietaryPreferences />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dietaryPreferencesAllergies"
        element={
          <ProtectedRoute>
            <DietaryPreferencesAllergies />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dietaryPreferencesRestrictions"
        element={
          <ProtectedRoute>
            <DietaryPreferencesRestrictions />
          </ProtectedRoute>
        }
      />
      <Route
        path="/healthPreferencesSpending"
        element={
          <ProtectedRoute>
            <HealthPreferencesSpending />
          </ProtectedRoute>
        }
      />
      <Route
        path="/healthPreferencesPersonalData"
        element={
          <ProtectedRoute>
            <HealthPreferencesPersonalData />
          </ProtectedRoute>
        }
      />
      <Route
        path="/healthPreferencesMedicalCondition"
        element={
          <ProtectedRoute>
            <HealthPreferencesMedicalCondition />
          </ProtectedRoute>
        }
      />
      <Route
        path="/healthPreferencesRestTime"
        element={
          <ProtectedRoute>
            <HealthPreferencesRestTime />
          </ProtectedRoute>
        }
      />
      <Route
        path="/healthPreferencesHydration"
        element={
          <ProtectedRoute>
            <HealthPreferencesHydration />
          </ProtectedRoute>
        }
      />
      <Route
        path="/healthPreferencesShopping"
        element={
          <ProtectedRoute>
            <HealthPreferencesShopping />
          </ProtectedRoute>
        }
      />
      <Route
        path="/healthPreferencesFactors"
        element={
          <ProtectedRoute>
            <HealthPreferencesFactors />
          </ProtectedRoute>
        }
      />
      <Route
        path="/userUpdateEmail"
        element={
          <ProtectedRoute>
            <UserUpdateEmail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/userRevokeSocialAccess"
        element={
          <ProtectedRoute>
            <UserRevokeSocialAccess />
          </ProtectedRoute>
        }
      />
      <Route
        path="/userLanguageSelect"
        element={
          <ProtectedRoute>
            <UserLanguageSelect />
          </ProtectedRoute>
        }
      />
      <Route
        path="/userNotificationSelect"
        element={
          <ProtectedRoute>
            <UserNotificationsSelect />
          </ProtectedRoute>
        }
      />
      <Route
        path="/userFaqs"
        element={
          <ProtectedRoute>
            <UserFaqs />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recipes"
        element={
          <ProtectedRoute>
            <Recipes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recipe"
        element={
          <ProtectedRoute>
            <RecipeShow />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recipesSearch"
        element={
          <ProtectedRoute>
            <RecipesSearch />
          </ProtectedRoute>
        }
      />

      <Route
        path="/recomendedRecipes"
        element={
          <ProtectedRoute>
            <RecomendedRecipes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/buddyMakerList"
        element={
          <ProtectedRoute>
            <BuddyMakerList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recipeList"
        element={
          <ProtectedRoute>
            <RecipeList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recipeCreate"
        element={
          <ProtectedRoute>
            <RecipeCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recipeEdit"
        element={
          <ProtectedRoute>
            <RecipeEdit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recipeEditIngredients"
        element={
          <ProtectedRoute>
            <RecipeEditIngredients />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recipeEditSteps"
        element={
          <ProtectedRoute>
            <RecipeEditSteps />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recipeStepEdit"
        element={
          <ProtectedRoute>
            <RecipeStepEdit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recipeStepCreate"
        element={
          <ProtectedRoute>
            <RecipeStepCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recipeIngredientEdit"
        element={
          <ProtectedRoute>
            <RecipeIngredientEdit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recipeIngredientCreate"
        element={
          <ProtectedRoute>
            <RecipeIngredientCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recipeSteps"
        element={
          <ProtectedRoute>
            <RecipeSteps />
          </ProtectedRoute>
        }
      />
      <Route
        path="/menuOrganizerList"
        element={
          <ProtectedRoute>
            <MenuOrganizerList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/menuOrganizerShow"
        element={
          <ProtectedRoute>
            <MenuOrganizerShow />
          </ProtectedRoute>
        }
      />
      <Route
        path="/menuOrganizerDayShow"
        element={
          <ProtectedRoute>
            <MenuOrganizerDayShow />
          </ProtectedRoute>
        }
      />
      <Route
        path="/menuOrganizerDayPartShow"
        element={
          <ProtectedRoute>
            <MenuOrganizerDayPartShow />
          </ProtectedRoute>
        }
      />
      <Route
        path="/menuOrganizerCreate"
        element={
          <ProtectedRoute>
            <MenuOrganizerCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/shoppingLists"
        element={
          <ProtectedRoute>
            <ShoppingListList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/shoppingListCreate"
        element={
          <ProtectedRoute>
            <ShoppingListCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/shoppingListShow"
        element={
          <ProtectedRoute>
            <ShoppingListShow />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ShoppingListShowMercadonaDB"
        element={
          <ProtectedRoute>
            <ShoppingListShowMercadonaDB />
          </ProtectedRoute>
        }
      />

      <Route
        path="/comingSoon"
        element={
          <ProtectedRoute>
            <ComingSoon />
          </ProtectedRoute>
        }
      />
      <Route
        path="/categorias"
        element={
          <ProtectedRoute>
            <Categorias />
          </ProtectedRoute>
        }
      />
      <Route
        path="/categories"
        element={
          <ProtectedRoute>
            <FoodCategoryList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/categoria/:id"
        element={
          <ProtectedRoute>
            <Categoria />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recuperar-contrasena"
        element={
          <ProtectedRoute>
            <RecCont />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recuperar-contrasena2"
        element={
          <ProtectedRoute>
            <RecCont2 />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recuperar-contrasena3"
        element={
          <ProtectedRoute>
            <RecCont3 />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recuperar-contrasena-fin"
        element={
          <ProtectedRoute>
            <RecContFin />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recetas"
        element={
          <ProtectedRoute>
            <Recetas />
          </ProtectedRoute>
        }
      />
      <Route
        path="/mis-recetas"
        element={
          <ProtectedRoute>
            <MisRecetas />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recetas-todas"
        element={
          <ProtectedRoute>
            <RecetasTodas />
          </ProtectedRoute>
        }
      />
      <Route
        path="/receta/:id"
        element={
          <ProtectedRoute>
            <Receta />
          </ProtectedRoute>
        }
      />
      <Route
        path="/buddymakers"
        element={
          <ProtectedRoute>
            <Buddymakers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/buddymaker/:id"
        element={
          <ProtectedRoute>
            <Buddymaker />
          </ProtectedRoute>
        }
      />
      <Route
        path="/about"
        element={
          <ProtectedRoute>
            <About />
          </ProtectedRoute>
        }
      />
      <Route
        path="/contact"
        element={
          <ProtectedRoute>
            <Contact />
          </ProtectedRoute>
        }
      />
      <Route
        path="/buddy"
        element={
          <ProtectedRoute>
            <Buddy />
          </ProtectedRoute>
        }
      />
      <Route
        path="/buddy-resumen"
        element={
          <ProtectedRoute>
            <BuddyResumen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/buddy-chat"
        element={
          <ProtectedRoute>
            <BuddyChat />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recetas-guardadas"
        element={
          <ProtectedRoute>
            <RecetasGuardadas />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recetas-guardadas-todas"
        element={
          <ProtectedRoute>
            <RecetasGuardadasTodas />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recetasemana"
        element={
          <ProtectedRoute>
            <RecetaSemana />
          </ProtectedRoute>
        }
      />
      <Route
        path="/nueva-receta"
        element={
          <ProtectedRoute>
            <NuevaReceta />
          </ProtectedRoute>
        }
      />
      <Route
        path="/borrar-receta/:id"
        element={
          <ProtectedRoute>
            <RecetaBorrar />
          </ProtectedRoute>
        }
      />
      <Route
        path="/editar-receta/:idreceta"
        element={
          <ProtectedRoute>
            <RecetaEdit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/terminos"
        element={
          <ProtectedRoute>
            <TerminosyCondiciones />
          </ProtectedRoute>
        }
      />
      <Route
        path="/p400"
        element={
          <ProtectedRoute>
            <P400 />
          </ProtectedRoute>
        }
      />
      <Route
        path="/paymentmethods"
        element={
          <ProtectedRoute>
            <PaymentMethods />
          </ProtectedRoute>
        }
      />
      <Route
        path="/subscription"
        element={
          <ProtectedRoute>
            <Suscripcion />
          </ProtectedRoute>
        }
      />
      <Route path="/subscription-result" element={<Suscripcion1 />} />
      <Route
        path="/suscripcion2"
        element={
          <ProtectedRoute>
            <Suscripcion2 />
          </ProtectedRoute>
        }
      />
      <Route
        path="/suscripcion3"
        element={
          <ProtectedRoute>
            <Suscripcion3 />
          </ProtectedRoute>
        }
      />
      <Route
        path="/suscripcionfin"
        element={
          <ProtectedRoute>
            <Suscripcionfin />
          </ProtectedRoute>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/perfil/:id"
        element={
          <ProtectedRoute>
            <Perfil />
          </ProtectedRoute>
        }
      />
      <Route
        path="/editar-perfil/:id"
        element={
          <ProtectedRoute>
            <PerfilEdit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/preferencias/:id"
        element={
          <ProtectedRoute>
            <Preferencias />
          </ProtectedRoute>
        }
      />
      <Route
        path="/listas"
        element={
          <ProtectedRoute>
            <ListasDeLaCompra />
          </ProtectedRoute>
        }
      />
      <Route
        path="/lista/:id"
        element={
          <ProtectedRoute>
            <ListaDeLaCompra />
          </ProtectedRoute>
        }
      />
      <Route
        path="/lista-nueva"
        element={
          <ProtectedRoute>
            <ListaDeLaCompraNueva />
          </ProtectedRoute>
        }
      />
      <Route
        path="/compartir"
        element={
          <ProtectedRoute>
            <Compartir />
          </ProtectedRoute>
        }
      />
      <Route
        path="/menus/:id"
        element={
          <ProtectedRoute>
            <Menus />
          </ProtectedRoute>
        }
      />
      <Route
        path="/menu-dia/:id"
        element={
          <ProtectedRoute>
            <MenuDia />
          </ProtectedRoute>
        }
      />
      <Route
        path="/agregar-receta-menu/:id"
        element={
          <ProtectedRoute>
            <AgregarRecetaMenu />
          </ProtectedRoute>
        }
      />
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <P400 />
          </ProtectedRoute>
        }
      />
      <Route path="/payment-bridge" element={<PaymentBridge />} />
    </Routes>
  );
};

export default AppRouter;
