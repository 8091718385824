import React, { createContext, useState, useEffect } from 'react';

export const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
  const [subStatus, setSubStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  // Check sessionStorage every 1 second to update user state
  useEffect(() => {
    if (user) return;

    const interval = setInterval(() => {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      setUser((prevUser) => {
        if (JSON.stringify(prevUser) !== JSON.stringify(storedUser)) {
          return storedUser;
        }
        return prevUser;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!user?.id) {
      setSubStatus(null);
      setLoading(!user); // Loading true if user is null, false otherwise
      return;
    }

    const checkSubscription = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}check-subscription-status?user_id=${user.id}`,
        );
        const data = await response.json();
        setSubStatus(data.status);
      } catch (error) {
        setSubStatus(null);
      } finally {
        setLoading(false);
      }
    };

    checkSubscription();
  }, [user]);

  return (
    <SubscriptionContext.Provider value={{ subStatus, loading }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
