import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import SelectList from './forms/SelectList';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import AgeRange from '../api/services/AgeRange';

const ProfileForm1 = () => {
  const navigate = useNavigate();

  const options = [
    {
      value: 10,
      label: 'Individual',
    },
    {
      value: 5,
      label: 'BuddyMaker',
    },
  ];
  const [buyerTypeId, setBuyerTypeId] = useState(10);

  const handleClick = (e) => {
    var data = JSON.parse(localStorage.getItem('register_data')) || {};
    data.role_id = buyerTypeId;

    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/profileForm8');
  };

  const handleBuyerTypeChange = (value) => {
    setBuyerTypeId(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={'/signUp'} replace={true}>
              <span>Paso 1</span> <span className="text-primary">de 4</span>
            </Toolbar>
            <h2 className="w-full text-white text-xl font-semibold">
              Tipo de Usuario
            </h2>
            <SelectList
              onChange={handleBuyerTypeChange}
              selected={buyerTypeId}
              options={options}
            />

            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick}>
                Siguiente
              </PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm1;
