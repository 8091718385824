import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockFour } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faEarth } from '@fortawesome/free-solid-svg-icons';
import { faSquareCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import buddyia from '../img/buddyiagran.png';
import { getUserInfo } from '../auth';

const Buddy = () => {
  const [usuario, setUsuario] = useState([]);
  const userinfo = getUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      `https://buddymarket.io/dummy/apibm/users/read.php/?id=${userinfo.id}`,
    )
      .then((response) => response.json())
      .then((data) => {
        setUsuario(data.data);
        if (userinfo.id == null) {
          navigate('../');
        }
      })
      .catch((error) => console.error('Error fetching user:', error));
  }, []);

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">BuddyMarket</h2>
      </div>
      <div className="rectangulo centrado">
        <img className="w50" src={buddyia} alt="BuddyMarket" />
      </div>
      <div className="rectangulo round mb20 mt0 relative p0">
        <div className="flex wrap">
          <div className="listacompralista1 w100 mb10 fondodos">
            <Link
              className="flex spacebetween vcentrado textoblanco vcentrado"
              to="../buddy-chat"
            >
              <span className="">Hazme una pregunta</span>
              <span className="simbologran">&gt;</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="rectangulo round mb20 mt0 relative p0">
        <div className="flex wrap spacebetween row gap10">
          <div className="listacompralista1 flex flexstart centrado column w30 mb10 fondouno">
            <Link className="textoblanco" to="../buddy-chat">
              <FontAwesomeIcon icon={faPenToSquare} className="f20" />
              <br />
              <span className="">Hazme la lista de la compra</span>
            </Link>
          </div>
          <div className="listacompralista1 flex flexstart centrado column w30 mb10 fondouno">
            <Link className="textoblanco" to="../buddy-chat">
              <FontAwesomeIcon icon={faEarth} className="f20" />
              <br />
              <span className="textoblanco">
                <br />
                Pregunta 2
              </span>
            </Link>
          </div>
          <div className="listacompralista1 flex flexstart centrado column w30 mb10 fondouno">
            <Link className="textoblanco" to="../buddy-chat">
              <FontAwesomeIcon icon={faSquareCaretRight} className="f20" />
              <br />
              <span className="textoblanco">
                <br />
                Pregunta 3
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="contenedorabajo pr10 pl10 ml0 mr0">
        <div className="contenedor flex spacebetween row nowrap">
          <Link to="../buddy-resumen">
            <button
              className="botoncirculo button fondonaranja textoblanco"
              type="button"
            >
              <FontAwesomeIcon icon={faClockFour} className="f16" />
            </button>
          </Link>
          <Link to="../buddy-chat">
            <button
              className="botoncirculo button fondonaranja textoblanco"
              type="button"
            >
              <FontAwesomeIcon icon={faComment} className="f16" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Buddy;
