import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import Input from './forms/Input';
import PrimaryButton from './ui/PrimaryButton';
import User from '../api/services/User.js';
import { CameraIcon } from '@heroicons/react/24/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const UserProfile = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    phone: '',
    description: '',
    gender: 'Hombre',
    age: '',
    instagram: '',
    linkedin: '',
    youtube: '',
    password: '',
    password_repeat: '',
    accept_terms: false,
    accept_communications: false,
  });
  const [imageURL, setImageURL] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const response = await User.profile();
      setProfile({
        name: response.data.name,
        email: response.data.email,
        age: response.data.age,
        phone: response.data.phone,
        description: response.data.description || '',
        gender: response.data?.gender?.name,
        instagram: response.data.instagram || '',
        linkedin: response.data.linkedin || '',
        youtube: response.data.youtube || '',
        accept_terms: response.data.accept_terms,
        accept_communications: response.data.accept_communications,
      });
      setImageURL(response.data.image_url);
    } catch (error) {
      console.error('Error fetching profile:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveProfile = async () => {
    setIsLoadingSave(true);

    try {
      const user = JSON.parse(localStorage.getItem('user'));
      let genderId = 1;
      switch (profile.gender) {
        case 'Hombre':
          genderId = 1;
          break;
        case 'Mujer':
          genderId = 2;
          break;
        case 'N/A':
          genderId = 3;
          break;
        default:
          genderId = 3;
          break;
      }
      const updatedProfile = {
        ...profile,
        gender_id: genderId,
      };
      const body = new FormData();
      for (const key in updatedProfile) {
        body.append(key, updatedProfile[key]);
      }

      if (imageFile) {
        body.append('image_file', imageFile);
      }
      await User.update(user.id, body);
      const updatedUser = { ...user, name: updatedProfile.name };
      localStorage.setItem('user', JSON.stringify(updatedUser));
      navigate('/sidebarMenu');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Ha ocurrido un error al editar');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProfile({
      ...profile,
      [name]: value,
    });
  };

  const onChangeImageFile = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImageData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onResetImageFile = (e) => {
    setImageFile(null);
    setImageData(null);
  };

  return isLoading ? (
    <div className="flex justify-center items-center h-screen">
      <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
    </div>
  ) : (
    <div className="relative h-full bg-no-repeat bg-cover bg-center overflow-scroll">
      <div className="flex flex-col justify-between min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col items-center gap-4 mt-2">
          <Toolbar to={'/sidebarMenu'}>Edición de perfil</Toolbar>

          <input
            ref={fileInputRef}
            type="file"
            name="image_file"
            onChange={onChangeImageFile}
            className="hidden"
          />
          {imageFile || imageURL ? (
            <div className="flex flex-col justify-center items-center gap-3">
              <h2 className="text-white text-sm font-bold">Foto de perfil</h2>
              <div className="relative flex justify-center items-center w-[123px] h-[123px]">
                <div className="flex justify-center items-center rounded-full w-[123px] h-[123px] bg-black overflow-hidden">
                  <LazyLoadImage
                    className="h-full w-full object-cover object-center"
                    onClick={() => fileInputRef.current.click()}
                    src={imageFile ? imageData : `${imageURL}/?r=${Date.now()}`}
                    effect="blur"
                    width={123}
                    height={123}
                    alt="Foto de perfil"
                  />
                </div>
                {!imageURL && (
                  <button
                    type="button"
                    onClick={onResetImageFile}
                    className="absolute -right-2 -top-2 flex items-center justify-center w-8"
                  >
                    <div className="w-8 h-8 rounded-full bg-buddy-bg-5 flex flex-col justify-center items-center">
                      <FontAwesomeIcon className="text-white" icon={faTimes} />
                    </div>
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="flex justify-center py-4">
              <div className="flex justify-center items-center rounded-full w-52 h-52 bg-buddy-bg-4">
                <button
                  onClick={() => fileInputRef.current.click()}
                  className="flex flex-col justify-center items-center text-buddy-text-1 space-y-2"
                >
                  <CameraIcon className="size-6" />
                </button>
              </div>
            </div>
          )}
          <Input
            name="name"
            labelSize="small"
            type="text"
            placeholder=""
            label={'Nombre Completo'}
            value={profile.name}
            onChange={handleChange}
          />
          <Input
            name="email"
            labelSize="small"
            type="email"
            placeholder=""
            label={'Correo electrónico'}
            value={profile.email}
            disabled={true}
          />
          <h2 className="w-full text-left text-white text-sm font-medium">
            Género
          </h2>
          <div className="flex gap-3 w-full mb-2">
            <div className="flex gap-2 text-white">
              <input
                type="radio"
                name="gender"
                value="Hombre"
                checked={profile.gender === 'Hombre'}
                onChange={handleChange}
                className="shrink-0 border-2 w-5 h-5 mt-1 checked:accent-primary focus:ring-0 focus:shadow"
              />
              <label className="block text-[12px] mt-auto">Masculino</label>
            </div>
            <div className="flex gap-2 text-white">
              <input
                type="radio"
                name="gender"
                value="Mujer"
                checked={profile.gender === 'Mujer'}
                onChange={handleChange}
                className="shrink-0 border-2 w-5 h-5 mt-1 checked:accent-primary focus:ring-0 focus:shadow"
              />
              <label className="block text-[12px] mt-auto">Femenino</label>
            </div>
            <div className="flex gap-2 text-white">
              <input
                type="radio"
                name="gender"
                value="N/A"
                checked={profile.gender === 'N/A'}
                onChange={handleChange}
                className="shrink-0 border-2 w-5 h-5 mt-1 checked:accent-primary focus:ring-0 focus:shadow"
              />
              <label className="block text-[12px] mt-auto">
                Prefiero no decirlo
              </label>
            </div>
          </div>
          <div className="flex w-full flex-col gap-1">
            <Input
              name="age"
              labelSize="small"
              type="number"
              placeholder=""
              label={'Edad'}
              value={profile.age}
              onChange={handleChange}
            />
            <span className="text-white text-[10px] ml-3">Ej: 31 </span>
          </div>
        </div>
        <FooterToolbar>
          <PrimaryButton
            type="button"
            onClick={handleSaveProfile}
            disabled={!(profile.age && profile.age > 0) || isLoadingSave}
            className="flex justify-center items-center"
          >
            {isLoadingSave ? (
              <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
            ) : (
              'Guardar Cambios'
            )}
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default UserProfile;
