import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import DietRestriction from '../api/services/DietRestriction';
import CheckBoxSelect from './forms/CheckBoxSelect';

const ProfileForm7 = () => {
  const navigate = useNavigate();

  const [dietRestrictionIds, setDietRestrictionIds] = useState([]);
  const [dietRestrictionOptions, setDietRestrictionOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (e) => {
    const data = JSON.parse(localStorage.getItem('register_data'));
    data.diet_restriction_ids = dietRestrictionIds;
    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/profileForm9');
  };

  const onSelectionChange = (selection) => {
    setDietRestrictionIds(selection);
  };

  async function loadDietRestrictions() {
    try {
      setIsLoading(true);
      const response = await DietRestriction.list();
      var options = [];
      response.data.forEach((restriction) => {
        options.push({
          value: restriction.id,
          label: restriction.name,
          param: restriction.api_param,
        });
      });
      setDietRestrictionOptions(options);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setDietRestrictionIds(data.diet_restriction_ids ?? []);
    }
    loadDietRestrictions();
  }, []);

  return isLoading ? (
    <div className="flex justify-center items-center h-screen">
      <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
    </div>
  ) : (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="flex flex-col justify-between min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col bg-red items-center gap-3 mb-6">
          <Toolbar to={'/profileForm8'}>
            <span>Paso 3</span> <span className="text-primary">de 4</span>
          </Toolbar>
          <div className="flex flex-col bg-red items-center gap-6">
            <h2 className="w-full text-white text-2xl text-center font-semibold">
              Estilos y Restricciones Dietéticas
            </h2>
            <h3 className="text-body text-center text-sm">
              Personaliza tu experiencia con BuddyMarket según tus necesidades y
              estilo de vidaEvita reacciones comunes a alergias o intolerancias
            </h3>
            <CheckBoxSelect
              name="allergies_ids"
              onChange={onSelectionChange}
              selection={dietRestrictionIds}
              options={dietRestrictionOptions}
            />
          </div>
        </div>
        <div className="flex flex-col mb-8">
          <p className="text-[10px] font-medium text-buddy-text-7 text-center">
            Los datos que proporcionas nos permiten optimizar tu experiencia en
            la app mediante inteligencia artificial, ofreciéndote
            recomendaciones personalizadas y contenidos adaptados a tus
            necesidades.
          </p>
          <FooterToolbar>
            <PrimaryButton type="button" onClick={handleClick}>
              Siguiente
            </PrimaryButton>
          </FooterToolbar>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm7;
