import React, { useState, useEffect } from 'react';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Subscription from '../api/services/Subscription';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import subscriptionImage1 from '../img/subscription1.png';
import subscriptionImage2 from '../img/subscription2.png';
import subscriptionImage3 from '../img/subscription3.png';
import subscriptionImage4 from '../img/subscription4.png';
import americanExpressImg from '../img/american-express.png';
import ConfirmationDialog from './ConfirmationDialog';
import visaImg from '../img/visa.png';
import CancelBuddy from '../img/cancel-subscription-buddy.png';

const stripePromise = loadStripe(
  'pk_test_51OX2IoErxmByqRHB2Ia0mySCGc3fc9OEip9Uzfcx3kl5FFjmJoZYGU1isHsZljj1sKBTA3tN0ssppw1K2E9y7fwP00Wv6r8T5v',
);

const AddCardForm = ({ onSubmit, loading }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setError(null);
    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setError(error.message);
      } else {
        onSubmit(paymentMethod.id);
      }
    } catch (err) {
      setError('An unexpected error occurred');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4 space-y-4">
      <div className="bg-buddy-bg-2 rounded-xl p-4">
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#ffffff',
                '::placeholder': {
                  color: '#a0aec0',
                },
              },
              invalid: {
                color: '#f56565',
              },
            },
          }}
        />
      </div>
      {error && <div className="text-red-500 text-sm">{error}</div>}
      <button
        type="submit"
        disabled={!stripe || loading}
        style={{ backgroundColor: '#394a53' }}
        className="w-full text-primary rounded-lg py-2 px-4 disabled:opacity-50"
      >
        {loading ? 'Procesando...' : 'Suscribirse con nueva tarjeta'}
      </button>
    </form>
  );
};

const Suscripcion = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [validUntil, setValidUntil] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [statusLoaded, setStatusLoaded] = useState(false);
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [savedCards, setSavedCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddCard, setShowAddCard] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const fetchSubscriptionStatus = async () => {
    try {
      const response = await Subscription.status();
      if (response.valid_until) {
        setValidUntil(response.valid_until);
        setSubscriptionDetails(response);
      }
    } catch (error) {
      console.error('Error fetching subscription status:', error);
    } finally {
      setStatusLoaded(true);
    }
  };

  useEffect(() => {
    fetchSubscriptionStatus();
  }, []);

  useEffect(() => {
    if (showPaymentMethods) {
      fetchSavedCards();
    }
  }, [showPaymentMethods]);

  const fetchSavedCards = async () => {
    try {
      const cards = await Subscription.listCards();
      setSavedCards(cards);
    } catch (error) {
      console.error('Error fetching cards:', error);
    }
  };

  const getCardImage = (brand) => {
    switch (brand) {
      case 'amex':
        return americanExpressImg;
      case 'visa':
        return visaImg;
      default:
        return null;
    }
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const handleContinueToPayment = () => {
    if (!selectedOption) {
      alert('Debes seleccionar un plan para continuar');
      return;
    }
    setShowPaymentMethods(true);
  };

  const handleSubscriptionWithSavedCard = async (paymentMethodId) => {
    setLoading(true);
    if (loading) {
      return;
    }
    const months =
      selectedOption === 'one-month'
        ? 1
        : selectedOption === 'three-months'
        ? 3
        : 12;

    try {
      const body = new FormData();
      body.append('months', months);
      body.append('paymentMethodId', paymentMethodId);

      const response = await Subscription.createSubscription(body);
      if (response.success) {
        await fetchSubscriptionStatus();
        setShowPaymentMethods(false);
      }
    } catch (error) {
      console.error('Error creating subscription:', error);
      alert('Error al crear la suscripción. Por favor, inténtalo de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubscriptionWithNewCard = async (paymentMethodId) => {
    setLoading(true);
    if (loading) {
      return;
    }
    try {
      const cardBody = new FormData();
      cardBody.append('paymentMethodId', paymentMethodId);
      await Subscription.addCard(cardBody);

      await handleSubscriptionWithSavedCard(paymentMethodId);
    } catch (error) {
      console.error('Error in subscription process:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    try {
      const response = await Subscription.cancelSubscription();
      if (response.success) {
        alert('Suscripción cancelada exitosamente');
        fetchSubscriptionStatus();
      } else {
        alert(`Error al cancelar la suscripción: ${response.error}`);
      }
      setIsConfirmationDialogOpen(false);
    } catch (error) {
      console.error('Error canceling subscription:', error);
      setIsConfirmationDialogOpen(false);
      alert('Error inesperado al cancelar la suscripción.');
    }
  };

  if (!statusLoaded) {
    return null;
  }

  if (showPaymentMethods) {
    return (
      <div className="relative h-full bg-no-repeat bg-cover bg-center overflow-scroll">
        <div className="h-full flex justify-between flex-col min-h-screen bg-secondary-bg bg-opacity-60 p-6">
          <Toolbar onClick={() => setShowPaymentMethods(false)}>
            Método de pago
          </Toolbar>
          <div className="w-full h-full pt-8">
            <h2 className="w-full text-left text-white text-base font-medium mb-5">
              Tarjetas guardadas
            </h2>
            {savedCards.map((card, index) => (
              <div
                key={index}
                className="w-full flex gap-2 justify-between bg-buddy-bg-2 p-3 rounded-xl py-4 mb-3 cursor-pointer"
                onClick={() => handleSubscriptionWithSavedCard(card.id)}
              >
                <div className="px-2 text-start">
                  <img
                    src={getCardImage(card.card.brand)}
                    width={'42px'}
                    alt={card.card.brand}
                  />
                </div>
                <div className="grow flex justify-between px-2 text-start text-left">
                  <span className="text-buddy-text-2 grow-0">
                    ..{card.card.last4}
                  </span>
                  <span className="text-buddy-text-2 grow-0">
                    {card.card.exp_month}/{card.card.exp_year}
                  </span>
                </div>
              </div>
            ))}

            <button
              onClick={() => setShowAddCard(!showAddCard)}
              style={{ backgroundColor: '#394a53' }}
              className="mt-8 w-full text-primary rounded-lg py-2 px-4"
            >
              Usar nueva tarjeta
            </button>

            {showAddCard && (
              <Elements stripe={stripePromise}>
                <AddCardForm
                  onSubmit={handleSubscriptionWithNewCard}
                  loading={loading}
                />
              </Elements>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative h-full bg-no-repeat bg-cover bg-center overflow-scroll">
      {isConfirmationDialogOpen && (
        <div
          style={{ bottom: 0, position: 'absolute' }}
          className="w-full h-full"
        >
          <ConfirmationDialog
            title="¿Estás seguro de que quieres cancelar tu plan?"
            description="Antes de continuar con la cancelación de tu plan, considera los beneficios y funciones a los que renunciarás. ¿Estás absolutamente seguro de que quieres cancelar tu Plan?"
            handleClick={handleCancel}
          >
            <PrimaryButton
              style={{ color: '#FF7D7D', backgroundColor: '#4E3B3B' }}
              type="button"
              onClick={handleCancel}
            >
              Cancelar Plan
            </PrimaryButton>
            <img
              src={CancelBuddy}
              style={{
                position: 'absolute',
                top: '-66%',
                left: '23%',
                width: '255px',
                height: '255px',
              }}
              alt="Cancelar suscripción"
              className="w-1/2  absolute top-0 right-0 aspect-square"
            />
          </ConfirmationDialog>
        </div>
      )}
      <div className="h-full flex justify-between flex-col min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <Toolbar to={'/sidebarMenu'}>Subscripción</Toolbar>
        {validUntil ? (
          <div className="flex h-full flex-col justify-flex-start gap-4 mt-5">
            <h2 className="w-full text-left text-white text-base font-medium">
              Beneficios
            </h2>
            <div className="w-full flex flex-col gap-2 justify-between bg-buddy-bg-2 p-3 rounded-xl py-4">
              <div className="w-full flex gap-2 justify-between py-2">
                <div className="px-2 text-start">
                  <img src={subscriptionImage1} width={'86px'} alt="" />
                </div>
                <div className="grow flex flex-col justify-between px-2 text-start text-left mt-auto mb-auto">
                  <span className="text-white text-base font-medium grow-0">
                    Plan de pago BuddyMarket
                  </span>
                </div>
              </div>
              <div className="w-full flex gap-2 justify-between py-2">
                <div className="grow flex flex-col justify-between px-2 text-start text-left">
                  <span className="text-buddy-text-2 grow-0 mb-3">
                    Experiencia sin publidad
                  </span>
                  <span className="text-buddy-text-2 grow-0">
                    Soporte prioritario 24/7
                  </span>
                </div>
              </div>
            </div>
            {subscriptionDetails?.months && (
              <div className="w-full flex flex-col gap-2 justify-between bg-buddy-bg-2 p-3 rounded-xl py-4">
                <div className="w-full flex gap-2 justify-between py-2">
                  <div className="px-2 text-start">
                    <img src={subscriptionImage2} width={'48px'} alt="" />
                  </div>
                  <div className="grow flex flex-col justify-between px-2 text-start text-left">
                    <span className="text-buddy-text-2 grow-0">
                      Siguiente fecha de facturación
                    </span>
                    <span className="text-white text-base font-medium grow-0">
                      {subscriptionDetails.next_payment_es}
                    </span>
                  </div>
                </div>
                <div className="w-full flex gap-2 justify-between py-2">
                  <div className="px-2 text-start">
                    <img src={subscriptionImage3} width={'48px'} alt="" />
                  </div>
                  <div className="grow flex flex-col justify-between px-2 text-start text-left">
                    <span className="text-buddy-text-2 grow-0">
                      Frecuencia de pago
                    </span>
                    <span className="text-white text-base font-medium grow-0">
                      {subscriptionDetails.frequency_es}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {/* Payment Method Card */}
            {subscriptionDetails?.payment_method && (
              <div className="w-full flex gap-2 justify-between bg-buddy-bg-2 p-3 rounded-xl py-4">
                <div className="px-2 text-start">
                  <img src={subscriptionImage4} width={'48px'} alt="" />
                </div>
                <div className="grow flex flex-col justify-between px-2 text-start text-left">
                  <span className="text-buddy-text-2 grow-0">
                    Editar o añadir nueva tarjeta
                  </span>
                  <span className="text-white text-base font-medium grow-0">
                    acabada en {subscriptionDetails.payment_method.last4}
                  </span>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <h2 className="w-full text-buddy-text-3 font-semibold mt-6">
              No tienes una suscripción activa. Selecciona una opción para
              suscribirte:
            </h2>
            <div className="h-full flex flex-col justify-end gap-4">
              <div
                onClick={() => handleSelect('one-month')}
                className="w-full cursor-pointer"
              >
                <div
                  className={`w-full flex flex-col gap-3 justify-between bg-buddy-bg-2 p-3 rounded-xl py-4 ${
                    selectedOption === 'one-month'
                      ? 'border-2 border-orange-500'
                      : ''
                  }`}
                >
                  <div className="grow px-2 text-start">
                    <span className="text-primary font-semibold grow-0">
                      Subscripción de un mes
                    </span>
                  </div>
                  <div className="grow px-2 text-start text-left">
                    <span className="text-buddy-text-3 font-semibold grow-0">
                      7.99 € / mes
                    </span>
                  </div>
                </div>
              </div>

              <div
                onClick={() => handleSelect('three-months')}
                className="w-full cursor-pointer"
              >
                <div
                  className={`w-full flex flex-col gap-3 justify-between bg-buddy-bg-2 p-3 rounded-xl py-4 ${
                    selectedOption === 'three-months'
                      ? 'border-2 border-orange-500'
                      : ''
                  }`}
                >
                  <div className="grow px-2 text-start">
                    <span className="text-primary font-semibold grow-0">
                      Subscripción de tres meses
                    </span>
                  </div>
                  <div className="grow px-2 text-right">
                    <span
                      style={{ backgroundColor: '#FF8C00' }}
                      className="py-1 px-2 rounded-md text-black font-semibold grow-0"
                    >
                      Ahorra 10%
                    </span>
                  </div>
                  <div className="grow px-2 text-start">
                    <span className="text-buddy-text-3 font-semibold grow-0">
                      18.89 € / mes
                    </span>
                  </div>
                </div>
              </div>

              <div
                onClick={() => handleSelect('one-year')}
                className="w-full cursor-pointer"
              >
                <div
                  className={`w-full flex flex-col gap-3 justify-between bg-buddy-bg-2 p-3 rounded-xl py-4 ${
                    selectedOption === 'one-year'
                      ? 'border-2 border-orange-500'
                      : ''
                  }`}
                >
                  <div className="grow px-2 text-start">
                    <span className="text-primary font-semibold grow-0">
                      Subscripción de un año
                    </span>
                  </div>
                  <div className="grow px-2 text-right">
                    <span
                      style={{ backgroundColor: '#FF8C00' }}
                      className="py-1 px-2 rounded-md text-black font-semibold grow-0"
                    >
                      Ahorra 20%
                    </span>
                  </div>
                  <div className="grow px-2 text-start">
                    <span className="text-buddy-text-3 font-semibold grow-0">
                      66.89 € / mes
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <FooterToolbar>
          {validUntil ? (
            <PrimaryButton
              style={{ color: '#FF7D7D', backgroundColor: '#4E3B3B' }}
              type="button"
              onClick={() => setIsConfirmationDialogOpen(true)}
            >
              Cancelar Plan
            </PrimaryButton>
          ) : (
            <PrimaryButton type="button" onClick={handleContinueToPayment}>
              Continuar
            </PrimaryButton>
          )}
        </FooterToolbar>
      </div>
    </div>
  );
};

export default Suscripcion;
