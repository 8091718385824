import React from 'react';
import { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import db_create_recipe_icon from '../img/db_create_recipe_icon.png';
import db_buddy_ia_icon from '../img/db_buddy_ia_icon.png';
import db_menu_scheduler_icon from '../img/db_menu_scheduler_icon.png';
import db_my_recipes_icon from '../img/db_my_recipes_icon.png';
import db_recipes_icon from '../img/db_recipes_icon.png';
import db_shopping_list_icon from '../img/db_shopping_list_icon.png';
import toolbar_coin from '../img/toolbar_coin.png';
import { Link } from 'react-router-dom';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import AvatarGenericImg from '../img/avatar-generic.png';
import AdImage from '../img/dashboard-banner.png';
import DashboardItem from './ui/DashboardItem';
import RecipeTile from './ui/RecipeTile';
import Recipe from '../api/services/Recipe';
import Card from './ui/Card';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [search, setSearch] = useState('');
  const [recipes, setRecipes] = useState([]);
  const [showResults, setShowResults] = useState(false);

  document.addEventListener('ionBackButton', (ev) => {
    ev.detail.register(10, () => {
      alert('backbutton');
    });
  });

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUser(user);
    }
  }, []);

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearch(value);

    if (value.trim() === '') {
      setRecipes([]);
      setShowResults(false);
      return;
    }

    try {
      const response = await Recipe.list({
        search: value,
        searchIngredients: true,
        limit: 10,
      });

      setRecipes(response.data);
      setShowResults(response.data.length > 0);
    } catch (error) {
      console.error('Error fetching recipes:', error);
    }
  };

  const handleResetSearch = () => {
    setSearch('');
    setRecipes([]);
    setShowResults(false);
  };

  const UserImageUrl = user?.image_url
    ? `${user?.image_url}/?r=${Date.now()}`
    : AvatarGenericImg;

  return !user?.id ? (
    <div className="flex justify-center items-center h-screen">
      <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
    </div>
  ) : (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="w-full h-12 flex justify-between space-x-4 text-title items-center text-lg mb-9">
          <div className="rounded-full h-10 bg-white bg-opacity-20 flex flex-col justify-center items-center">
            <Link to={'/sidebarMenu'} className="h-12 w-12">
              <div className="relative overflow-hidden h-12 w-12 rounded-full bg-black">
                <LazyLoadImage
                  src={UserImageUrl}
                  alt="User image"
                  className="bg-black h-12 w-12 object-cover object-center"
                  width={48}
                  height={48}
                  effect="blur"
                />
              </div>
            </Link>
          </div>
          <div className="grow text-center">
            <div className="w-full h-12 bg-buddy-bg-2 rounded-xl relative shadow-inner ring-inset ring-buddy-bg-3 ring-1">
              <div className="absolute h-12 flex flex-row items-center">
                <div className="w-8 ps-4">
                  <FontAwesomeIcon
                    className="text-buddy-text-3"
                    icon={faSearch}
                  />
                </div>
              </div>
              <input
                type="text"
                onChange={handleSearchChange}
                value={search}
                autoComplete="off"
                className="appearance-none w-full h-full pb-1 bg-transparent text-white text-sm ps-11 pe-2 placeholder:text-sm rounded-xl !pl-10"
                name="search"
                placeholder="Buscar en BuddyMarket"
              />
              <div className="flex items-center justify-center absolute w-10 h-12 right-0 top-0">
                <button
                  type="button"
                  onClick={handleResetSearch}
                  className="flex items-center justify-center w-8 me-2 h-full"
                >
                  <div className="w-8 h-8 rounded-full bg-buddy-bg-2 flex flex-col justify-center items-center">
                    <FontAwesomeIcon className="text-white" icon={faTimes} />
                  </div>
                </button>
              </div>
            </div>
          </div>
          {false && <img className="w-12" src={toolbar_coin} alt="" />}
        </div>
        {showResults && (
          <div className="absolute top-24 left-1/2 transform -translate-x-1/2 w-[90%] bg-buddy-bg-2 rounded-xl shadow-lg z-50 bg-secondary-bg">
            <div className="grid grid-cols-1 gap-3">
              {recipes.map((recipe, i) => (
                <Card key={i} className="bg-buddy-bg-2">
                  <RecipeTile
                    recipe={recipe}
                    key={recipe.id}
                    to={`/recipe?id=${recipe.id}`}
                  />
                </Card>
              ))}
            </div>
          </div>
        )}

        <div className="flex flex-col gap-8">
          <Link to="#" target="_blank">
            <img src={AdImage} alt="ad" className="w-full" />
          </Link>
          <div className="w-full grid grid-cols-3 bg-red items-start gap-1 gap-y-8 pt-8">
            <DashboardItem
              label={'Lista de la Compra'}
              iconSrc={db_shopping_list_icon}
              to={'/shoppingLists'}
            ></DashboardItem>
            <DashboardItem
              label={'Organizador de Menús'}
              iconSrc={db_menu_scheduler_icon}
              to={'/menuOrganizerList'}
            ></DashboardItem>
            <DashboardItem
              label={'Buddy IA'}
              iconSrc={db_buddy_ia_icon}
              to={'/buddy-chat'}
            ></DashboardItem>
            <DashboardItem
              label={'Biblioteca de Recetas'}
              iconSrc={db_recipes_icon}
              to={'/recipes'}
            ></DashboardItem>
            <DashboardItem
              label={'Mis Recetas'}
              iconSrc={db_my_recipes_icon}
              to={'/recipeList'}
            ></DashboardItem>
            {user != null && user?.role?.id === 5 ? (
              <DashboardItem
                label={'Crear Receta'}
                iconSrc={db_create_recipe_icon}
                to={'/recipeCreate'}
              ></DashboardItem>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
