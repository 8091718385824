import { ChevronRightIcon } from '@heroicons/react/24/outline';


const MenuOptionNavigator = ({onClick, label, children}) => {
    return (
        <div className="mx-0 border-t border-buddy-bg-2 bg-secondary-bg">
            <button
                onClick={onClick}
                className="w-full bg-black flex justify-between items-center px-3 py-2 rounded-2xl space-x-3"
            >
                <div className="pointer-events-none">
                    {children}
                </div>
                <span className="text-buddy-text-1 text-start font-medium text-base grow pointer-events-none">
                    {label}
                </span>
                <ChevronRightIcon className="size-4 pointer-events-none"></ChevronRightIcon>
            </button>
        </div>
    )
}

export default MenuOptionNavigator