import React from 'react';
import Buddy from '../img/buddy-welcome.png';
import PrimaryButton from './ui/PrimaryButton';
import { useNavigate } from 'react-router-dom';

const SuscriptionSuccess = () => {
  const navigate = useNavigate();

  async function goLogin() {
    navigate('/login');
  }

  return (
    <div className="relative min-h-screen">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6 flex flex-col items-center">
        <img src={Buddy} alt="" className="w-1/2 aspect-square" />
        <div
          image={Buddy}
          description=""
          className="flex flex-col gap-7 relative text-white -m-4"
        >
          <div className="flex flex-col gap-7 bg-buddy-bg-2 p-6 rounded-2xl">
            <div className="text-3xl font-bold">
              ¡Enhorabuena! Ya estás suscrito en BuddyMarket!
            </div>
            <div>
              Gracias por actualizar a la Versión BuddyMarket de pago. Ahora
              estás listo para disfrutar de todos los fantásticos beneficios y
              características que lo acompañan. Si tienes alguna pregunta o
              necesitas ayuda, no dudes en comunicarte con nosotros. ¡Que
              disfrutes de la APP!
            </div>
          </div>
          <PrimaryButton type="button" onClick={goLogin}>
            Empezar a usar BuddyMarket
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default SuscriptionSuccess;
