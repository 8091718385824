import { Filesystem, Directory } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import domtoimage from 'dom-to-image';
import { PDFDocument } from 'pdf-lib';

export const generatePDF = async (elementToPrintId, fileName = "document.pdf") => {
  const element = document.getElementById(elementToPrintId);
  if (!element) {
    throw new Error(`Element with id ${elementToPrintId} not found`);
  }

  try {
    const dataUrl = await domtoimage.toPng(element, { bgcolor: "#181A20" });
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([element.offsetWidth, element.offsetHeight]);

    const imageBytes = await fetch(dataUrl).then((res) => res.arrayBuffer());
    const image = await pdfDoc.embedPng(imageBytes);
    page.drawImage(image, {
      x: 0,
      y: 0,
      width: element.offsetWidth,
      height: element.offsetHeight,
    });
    const pdfBytes = await pdfDoc.save();
    const base64Data = arrayBufferToBase64(pdfBytes);
    return share(fileName, base64Data);
  } catch (error) {
    console.error("Error generating or sharing PDF:", error);
  }
};

async function share(fileName, base64Data) {
  try {
    await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Cache
    });
    const uriResult = await Filesystem.getUri({
      directory: Directory.Cache,
      path: fileName
    });
    await Share.share({
      title: fileName,
      text: "Compartiendo PDF generado",
      url: uriResult.uri,
    });
  } catch (error) {
    console.error("Error sharing PDF:", error);
  }
}

function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}
