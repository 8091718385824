import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import AppRouter from './AppRouter';
import './index.css';
import { ModalProvider } from './contexts/modal';
import AppLayout from './AppLayout';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { App as CapacitorApp } from '@capacitor/app';

const root = ReactDOM.createRoot(document.getElementById('root'));

CapacitorApp.addListener('appUrlOpen', (event) => {
  const url = new URL(event.url);

  console.log(url, url.pathname);
  if (url.pathname === '/signup') {
    window.location.href = '/signup';
  }

  if (url.pathname === '/payment-processing') {
    window.location.href = '/payment-processing';
  }
});

CapacitorApp.addListener('backButton', (data) => {
  alert('Backbutton pressed');
});

CapacitorApp.addListener('browserPageLoaded', (info) => {
  if (info.url.startsWith('buddymarket://payment-processing')) {
    Browser.close();
  }
});

root.render(
  <React.StrictMode>
    <App>
      <ModalProvider>
        <Router>
          <AppLayout>
            <AppRouter />
          </AppLayout>
        </Router>
      </ModalProvider>
    </App>
  </React.StrictMode>,
);
