import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faDeleteLeft } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';

const ListaDeLaCompra = () => {
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [listInfo, setListInfo] = useState([]);
  const navigate = useNavigate();
  const placecholderIcono = 'carrito-compra.png';
  const [searchQuery, setSearchQuery] = useState('');
  const [ingredients, setIngredients] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [cadena, setCadena] = useState('');
  const [nombreVisible, setNombreVisible] = useState('');

  const handleNombreClick = (e) => {
    e.preventDefault();
    setNombreVisible(1);
  };

  const handleNombreSave = (e, id, cadena) => {
    setNombreVisible('');
    const requestOptions = {
      method: 'POST',
      redirect: 'manual',
      // headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, cadena }),
    };
    fetch(
      `https://buddymarket.io/dummy/apibm/lists/updatenombre.php`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((data) => {
        setListInfo(data.data);
      })
      .catch((error) => console.error('Error updating ingredients:', error));
  };

  async function manejarClick(lis) {
    return fetch(
      `https://buddymarket.io/dummy/apibm/lists/complete.php/?id=${lis.id}`,
      {
        method: 'GET',
      },
    )
      .then((response) => response.json())
      .then((data) => {
        setList(data.data);
      })
      .catch((error) => console.error('Error updating element:', error));
  }

  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/lists/readlistinfo.php/?id=${id}`)
      .then((response) => response.json())
      .then((data) => setListInfo(data.data))
      .catch((error) => console.error('Error fetching listinfo:', error));
  }, []);

  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/lists/read.php/?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setList(data.data);
      })
      .catch((error) => console.error('Error fetching list:', error));
  }, []);

  useEffect(() => {
    if (!!searchQuery && searchQuery.length > 2) {
      fetchIngredients();
    }
  }, [searchQuery]);

  async function fetchIngredients() {
    if (!!searchQuery) {
      try {
        const response = await fetch(
          `https://buddymarket.io/dummy/apibm/lists/searchingredients.php?query=${searchQuery}`,
        );
        const data = await response.json();
        setIngredients(data.data);
      } catch (error) {
        console.error('Error fetching ingredients:', error);
      }
    }
  }

  async function handleSearchChange(e) {
    e.preventDefault();
    setSearchQuery(e.target.value);
    if (!!searchQuery) {
      await fetchIngredients();
      !!ingredients ? setIsDropdownOpen(true) : setIsDropdownOpen(false);
    }
  }

  const handleAddToList = async (e, id, ingrediente) => {
    e.preventDefault();
    try {
      const requestOptions = {
        method: 'POST',
        redirect: 'manual',
        // headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, ingrediente }),
      };
      fetch(
        `https://buddymarket.io/dummy/apibm/lists/addproduct.php`,
        requestOptions,
      )
        .then((response) => response.json())
        .then((data) => {
          setList(data.data);
          setSearchQuery('');
          setIsDropdownOpen(false);
        })
        .catch((error) => console.error('Error adding ingredient:', error));
    } catch (error) {
      console.error('Error adding ingredient:', error);
      setIsDropdownOpen(false);
    }
  };

  const handleDeleteList = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        redirect: 'manual',
      };
      fetch(
        `https://buddymarket.io/dummy/apibm/lists/delete.php?id=${id}`,
        requestOptions,
      )
        .then((response) => response.json())
        .then((data) => {
          setList([]);
          navigate('../listas');
        })
        .catch((error) => console.error('Error deleting list:', error));
    } catch (error) {
      console.error('Error deleting list:', error);
    }
  };

  const borrarIngrediente = async (ingredient) => {
    try {
      const requestOptions = {
        method: 'POST',
        redirect: 'manual',
      };
      fetch(
        `https://buddymarket.io/dummy/apibm/lists/deleteproduct.php?id=${ingredient.id}`,
        requestOptions,
      )
        .then((response) => response.json())
        .then((data) => {
          const updatedList = list.filter((item) => item.id !== ingredient.id);
          setList(updatedList);
        })
        .catch((error) => console.error('Error deleting ingredient:', error));
    } catch (error) {
      console.error('Error deleting ingredient:', error);
    }
  };

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to="../listas">
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Lista de la compra</h2>
      </div>

      {listInfo?.map((lisi) => (
        <div key={lisi.id} className="input-container w100">
          <h2
            id="nombre"
            onClick={(e) => handleNombreClick(e)}
            className={
              nombreVisible === 1 ? 'oculto' : 'visible input-container w100'
            }
          >
            {lisi.nombre} &nbsp;&nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
          </h2>
          <div
            className={
              nombreVisible === 1 ? 'visible input-container w100' : 'oculto'
            }
          >
            <input
              onChange={(e) => setCadena(e, e.target.value)}
              className="w100 vmiddle mb20"
              type="text"
              id="newnombre"
              autoComplete="off"
              defaultValue={lisi.nombre}
            />
          </div>
          <div
            className={
              nombreVisible === 1 ? 'flex spacebetween gap20' : 'oculto'
            }
          >
            <button
              type="button"
              className="borderojo w50 mb10 h42"
              onClick={handleDeleteList}
            >
              <Link className="textorojo">
                <strong>Borrar</strong>
              </Link>
            </button>

            <button
              type="button"
              className="bordeblanco w50 h42"
              onClick={(e) =>
                handleNombreSave(
                  e,
                  lisi.id,
                  document.getElementById('newnombre').value,
                )
              }
            >
              <Link className="textoblanco">
                <strong>Guardar</strong>
              </Link>
            </button>
          </div>
        </div>
      ))}

      <div
        className={
          nombreVisible === 1 ? 'oculto' : 'rectangulo centrado busqueda'
        }
      >
        <form id="busqueda" action="buscar">
          <div className="input-container flex equiespaciado gap10 h42">
            <FontAwesomeIcon icon={faMagnifyingGlassPlus} className="icon2" />
            <input
              className="w100"
              type="text"
              name="buscar"
              autocomplete="off"
              value={searchQuery}
              onChange={(e) => handleSearchChange(e)}
              onPaste={(e) => handleSearchChange(e)}
              onKeyUp={(e) => {
                if (e.keyCode === 27) {
                  setIsDropdownOpen(false);
                }
              }}
              placeholder="Añadir producto"
            />
            <button
              type="submit"
              className="bordeblanco w20 mb10 f08 h42"
              onClick={(e) => handleAddToList(e, id, searchQuery)}
            >
              <Link className="textoblanco">Añadir</Link>
            </button>
            <br />
            {isDropdownOpen && (
              <div className="dropdown">
                <ul>
                  {ingredients?.map((ingredient) => (
                    <li
                      key={ingredient.id}
                      onClick={(e) =>
                        handleAddToList(e, id, ingredient.ingrediente)
                      }
                    >
                      <img
                        className="iconocomida"
                        src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/iconos/${
                          ingredient.icono || placecholderIcono
                        }`}
                        alt={ingredient.id}
                      />{' '}
                      {ingredient.ingrediente}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </form>
      </div>

      <div className="rectangulo round mb20 mt0 relative p0">
        <div className="vcentrado izquierda">
          {!!list ? null : 'Esta lista está vacía'}
          {list?.map((lis) => (
            <div key={lis.id}>
              <div className={nombreVisible === 1 ? 'oculto' : 'w100'}>
                <div
                  className={
                    lis.completado == 1
                      ? `tachado listacompralista2 flex spacebetween w100 mb10 vcentrado izquierda`
                      : `listacompralista2 flex spacebetween w100 mb10 vcentrado izquierda`
                  }
                >
                  <span className="w100 f12">
                    <img
                      className="icono-lista vcentrado"
                      width="52px"
                      src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/iconos/${
                        lis.icono || placecholderIcono
                      }`}
                    />
                    {lis.cantidad} {lis.cadena}
                  </span>
                  <button
                    type="button"
                    className="botonselectlista"
                    onClick={() => manejarClick(lis)}
                  >
                    <span className="simbologran w20 derecha f20 icono-lista">
                      {lis.completado == 0
                        ? String.fromCharCode(9711)
                        : String.fromCharCode(10004)}
                    </span>
                  </button>
                </div>
              </div>
              <div className={nombreVisible === 1 ? 'w100' : 'oculto'}>
                <div
                  className={
                    lis.completado == 1
                      ? `tachado listacompralista2 flex spacebetween w100 mb10 vcentrado izquierda f08`
                      : `listacompralista2 flex spacebetween w100 mb10 vcentrado izquierda f08`
                  }
                >
                  <span className="w100 f12">
                    <img
                      className="icono-lista vcentrado"
                      width="52px"
                      src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/iconos/${
                        lis.icono || placecholderIcono
                      }`}
                    />
                    {lis.cantidad} {lis.cadena}
                  </span>
                  <button
                    type="button"
                    className="botonselectlista derecha"
                    onClick={() => borrarIngrediente(lis)}
                  >
                    <span className="simbologran w20 derecha f20 textorojo">
                      <FontAwesomeIcon
                        className="textorojo"
                        icon={faDeleteLeft}
                      />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default ListaDeLaCompra;
