import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Select from './forms/Select';
import SelectList from './forms/SelectList';
import Textarea from './forms/Textarea';

const ProfileForm27 = () => {
  const navigate = useNavigate();

  const [useNutriotionalSupplements, setUseNutriotionalSupplements] =
    useState('');

  const options = [
    {
      value: 1,
      label: 'Sí',
    },
    {
      value: 0,
      label: 'No',
    },
  ];

  const handleClick = (e) => {
    if (useNutriotionalSupplements !== '') {
      var data = JSON.parse(localStorage.getItem('register_data'));
      data.use_nutritional_supplements = useNutriotionalSupplements;
      data.nutritional_supplements = document.querySelector(
        '[name="nutritional_supplements"]',
      ).value;
      localStorage.setItem('register_data', JSON.stringify(data));
      navigate('/profileForm28');
    } else {
      alert('Responda a las preguntas no opcionales para continuar.');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setUseNutriotionalSupplements(data.use_nutritional_supplements ?? '');
      const el = document.querySelector('[name="nutritional_supplements"]');
      if (el) {
        el.value = data.nutritional_supplements ?? '';
      }
    }
  }, []);

  const handleOnChangeUseNutriotionalSupplements = (value) => {
    setUseNutriotionalSupplements(value);
  };

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={'/profileForm26'} replace={true}>
              <div className="text-sm">
                <span>Paso 27</span> <span className="text-primary">de 28</span>
              </div>
            </Toolbar>
            <h2 className="w-full text-white text-xl font-semibold">
              ¿Tomas suplementos nutricionales regularmente?
            </h2>
            <SelectList
              onChange={handleOnChangeUseNutriotionalSupplements}
              selected={useNutriotionalSupplements}
              options={options}
            />

            <div className="my-3"></div>
            <h2 className="w-full text-white text-lg font-semibold">
              Si la respuesta es sí, por favor especifica:
            </h2>
            <Textarea name="nutritional_supplements" />
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick}>
                Siguiente
              </PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm27;
