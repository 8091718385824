import React from 'react';
import { Link } from 'react-router-dom';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const UserTile = ({ user, to = '' }) => {
  return (
    <Link to={to} className="relative w-16 mr-5 mb-2 rounded-lg">
      <div className="relative w-16 pointer-events-none">
        <div className="relative overflow-hidden rounded-full bg-black">
          <LazyLoadImage
            src={user.image_url}
            alt="BuddyMaker User"
            className="bg-black h-16 w-16 object-cover object-center"
            width={64}
            height={64}
            effect="blur"
          />
        </div>
        <div className="absolute -right-1 -top-1">
          <CheckBadgeIcon className="size-7 text-primary" />
        </div>
      </div>
      <div className="w-full mt-2 text-center overflow-hidden text-buddy-text-1 text-xs">
        {user.name}
      </div>
    </Link>
  );
};

export default UserTile;
