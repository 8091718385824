import { useEffect } from "react";
import { StatusBar } from "@capacitor/status-bar";
import { Keyboard } from "@capacitor/keyboard";
import { SubscriptionProvider } from "./contexts/subscriptionContext";
import { Capacitor } from "@capacitor/core";

function App({ children }) {
  useEffect(() => {
    if (Capacitor.isPluginAvailable("StatusBar")) {
      StatusBar.setOverlaysWebView({ overlay: false });
    }

    if (Capacitor.isPluginAvailable("Keyboard")) {
      Keyboard.setAccessoryBarVisible({ isVisible: false });
    }
  }, []);

  return (
    <SubscriptionProvider>
      <div className="p-0 max-w-xl mx-auto">{children}</div>
    </SubscriptionProvider>
  );
}

export default App;
