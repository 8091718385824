import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import User from '../api/services/User';
import { Link } from 'react-router-dom';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import AvatarGenericImg from '../img/avatar-generic.png';
import PencilImg from '../img/pencil-icon.png';
import NutritionImg from '../img/edit-nutrition.png';
import CalendarImg from '../img/calendar.png';
import FaqsImg from '../img/faqs.png';
import LogoutImg from '../img/logout.png';
import BuddymakerImg from '../img/milk-bottle.png';
import clsx from 'clsx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SidebarMenu = () => {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const response = await User.profile();
      setUserEmail(response.data.email);
      localStorage.setItem('user', JSON.stringify(response.data));
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const handleLogoutButton = (e) => {
    if (window.confirm('¿Cerrar sesión?') === true) {
      localStorage.setItem('user', null);
      localStorage.setItem('token', null);
      navigate('/login');
    }
  };

  const handlePanel = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}create-portal-session`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: user.id }),
        },
      );
      const data = await response.json();
      if (data.url) {
        window.location.href = data.url;
      } else {
        alert('Error: ' + data.error);
      }
    } catch (error) {
      console.error('Portal error:', error);
    }
  };

  const UserImageUrl = user?.image_url
    ? `${user?.image_url}/?r=${Date.now()}`
    : AvatarGenericImg;

  return !user?.id || !userEmail ? (
    <div className="flex justify-center items-center h-screen">
      <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
    </div>
  ) : (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col bg-red items-center gap-3">
          <Toolbar to={'/'}>Editar Perfil</Toolbar>
          <div className="w-full flex space-x-4 items-center mb-4">
            <div className="relative overflow-hidden h-14 w-14 rounded-full bg-black">
              <LazyLoadImage
                src={UserImageUrl}
                alt=""
                className="bg-black h-14 w-14 object-cover object-center"
                effect="blur"
              />
            </div>
            <div className="flex flex-col align-center justifty-center gap-1">
              <span className="text-primary font-semibold text-lg">
                {user.name}
              </span>
              {userEmail && (
                <span className="text-buddy-text-2 text-base font-medium">
                  {userEmail}
                </span>
              )}
            </div>
          </div>
          <Link className="w-full" to={'/userProfile'}>
            <div className="w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl">
              <img src={PencilImg} alt="pencil" className="w-[24px] h-[24px]" />
              <div className="grow px-2 text-start">
                <span className="text-buddy-text-1 font-semibold grow-0">
                  Editar perfil
                </span>
              </div>
              <ChevronRightIcon className="size-6 text-buddy-text-3 grow-0" />
            </div>
          </Link>
          <Link className="w-full" to={'/dietaryPreferences'}>
            <div className="w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl">
              <img
                src={NutritionImg}
                alt="pencil"
                className="w-[22px] h-[22px]"
              />
              <div className="grow px-2 text-start">
                <span className="text-buddy-text-1 font-semibold grow-0">
                  Editar preferencias nutricionales
                </span>
              </div>
              <ChevronRightIcon className="size-6 text-buddy-text-3 grow-0" />
            </div>
          </Link>
          <div
            className={clsx(
              'w-full flex bg-buddy-bg-2 p-3 rounded-2xl cursor-pointer',
              {
                'justify-center': loading,
                'justify-between': !loading,
              },
            )}
            onClick={handlePanel}
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <div className="w-6 h-6 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
              </div>
            ) : (
              <div className="w-full flex justify-between">
                <img
                  src={CalendarImg}
                  alt="pencil"
                  className="w-[24px] h-[24px]"
                />
                <div className="grow px-2 text-start">
                  <span className="text-buddy-text-1 font-semibold grow-0">
                    Facturación
                  </span>
                </div>
                <ChevronRightIcon className="size-6 text-buddy-text-3 grow-0" />
              </div>
            )}
          </div>

          <Link className="w-full" to={'/userFaqs'}>
            <div className="w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl">
              <img src={FaqsImg} alt="pencil" className="w-[24px] h-[24px]" />
              <div className="grow px-2 text-start">
                <span className="text-buddy-text-1 font-semibold grow-0">
                  Preguntas frecuentes
                </span>
              </div>
              <ChevronRightIcon className="size-6 text-buddy-text-3 grow-0" />
            </div>
          </Link>

          {user.role.id === 5 && (
            <Link className="w-full" to={'/buddyMakerProfile'}>
              <div className="w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl">
                <img
                  src={BuddymakerImg}
                  alt="buddymaker"
                  className="w-[24px] h-[24px]"
                />
                <div className="grow px-2 text-start">
                  <span className="text-buddy-text-1 font-semibold grow-0">
                    Perfil Buddymaker
                  </span>
                </div>
                <ChevronRightIcon className="size-6 text-buddy-text-3 grow-0" />
              </div>
            </Link>
          )}

          <button className="w-full" onClick={handleLogoutButton}>
            <div className="w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl">
              <img src={LogoutImg} alt="pencil" className="w-[24px] h-[24px]" />
              <div className="grow px-2 text-start">
                <span className="text-buddy-text-danger font-semibold grow-0">
                  Cerrar sesión
                </span>
              </div>
              <ChevronRightIcon className="size-6 text-buddy-text-3 grow-0" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
