import React, { useEffect, useState, useCallback } from 'react';
import UserShoppingList from '../api/services/UserShoppingList';
import { Link } from 'react-router-dom';
import ShoppingGenericImg from '../img/shopping-list-item-generic.png';
import MenuOptionNavigator from '../components/MenuOptionNavigator.js';
import PrimaryButton from './ui/PrimaryButton.js';
import { XMarkIcon } from '@heroicons/react/24/outline';
import InputState from './forms/InputState.js';
import clsx from 'clsx';

const ShoppingListItem = ({
  id,
  name,
  numberProducts = 120,
  imageUrl,
  handleDelete,
  handleUpdate,
  handleDuplicate,
}) => {
  const [shoppingListItems, setShoppingListItems] = useState([]);

  const [loading, setLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [newName, setNewName] = useState(name);

  const user = JSON.parse(localStorage.getItem('user'));

  const getShoppingListData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await UserShoppingList.get(user.id, id);
      setShoppingListItems(response.data.items);
    } catch (error) {
      console.error('Error fetching organizer:', error);
    } finally {
      setLoading(false);
    }
  }, [user.id, id]);

  useEffect(() => {
    getShoppingListData();
  }, [getShoppingListData]);

  return !user?.id ? (
    <div className="flex justify-center items-center h-screen">
      <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
    </div>
  ) : (
    <div className="relative">
      <Link key={id} to={'/shoppingListShow?id=' + id}>
        <div className="h-24 relative px-[14px] py-3 flex justify-between items-center space-x-3 rounded-xl text-white overflow-hidden">
          <div
            className="absolute inset-0 w-full h-full bg-cover bg-center blur-[2px] scale-100 opacity-50"
            style={{
              backgroundImage: `url(${
                imageUrl ? imageUrl : ShoppingGenericImg
              })`,
            }}
          ></div>
          <div className="flex justify-between w-full h-full items-center z-10">
            <div className="flex gap-4">
              <img
                className="rounded-full h-16 w-16 object-cover"
                src={imageUrl ? imageUrl : ShoppingGenericImg}
                alt="shoppingList"
              />
              <div className="flex flex-col justify-center">
                <h1 className="font-semibold text-base">{name}</h1>
                <p
                  className={clsx('font-semibold text-xs', {
                    'opacity-0': loading,
                  })}
                >
                  <span className="text-2xl">{shoppingListItems?.length} </span>
                  Products
                </p>
              </div>
            </div>
            <div className="flex items-center">
              {/* Add stopPropagation to prevent Link click */}
              <button
                className="z-50 relative"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setShowMenu((prevMenu) => !prevMenu);
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_543_3313)">
                    <rect x="6" width="4" height="4" rx="2" fill="white" />
                    <rect
                      x="6"
                      y="6"
                      width="4"
                      height="4"
                      rx="2"
                      fill="white"
                    />
                    <rect
                      x="6"
                      y="12"
                      width="4"
                      height="4"
                      rx="2"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_543_3313">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </Link>
      {showMenu && (
        <>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowMenu(false);
            }}
            className="fixed inset-0 z-40 bg-black bg-opacity-60"
          ></div>
          <div className="absolute top-24 left-0 right-0 z-50 border-t flex flex-col space-y-3 border-buddy-bg-2 bg-secondary-bg p-4">
            <MenuOptionNavigator
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(id);
              }}
              label="Eliminar lista"
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_545_3355)">
                  <path
                    d="M22.1667 15.1667H10.5001V12.8333H22.1667V15.1667ZM22.1667 8.16667H14.0001V10.5H22.1667V8.16667ZM10.5001 19.8333H15.1667V17.5H10.5001V19.8333ZM28.0001 3.5V19.1497L19.1497 28H4.66675V12.8333H7.00008V25.6667H17.5001V17.5H25.6667V3.5C25.6667 2.856 25.1441 2.33333 24.5001 2.33333H16.3334V0H24.5001C26.4332 0 28.0001 1.56683 28.0001 3.5ZM19.8334 24.017L24.0171 19.8333H19.8334V24.017Z"
                    fill="#FF8C00"
                  />
                  <g clipPath="url(#clip1_545_3355)">
                    <path
                      d="M11.6337 2.56292L9.43708 0.365833C9.20083 0.13 8.88667 0 8.55333 0H5.44667C5.11292 0 4.79917 0.13 4.56292 0.36625L2.36625 2.56292C2.13 2.79917 2 3.11292 2 3.44667V6.55333C2 6.88708 2.13 7.20083 2.36625 7.43708L4.56292 9.63375C4.79917 9.87 5.11292 10 5.44667 10H8.55333C8.88667 10 9.20083 9.87 9.4375 9.63375L11.6337 7.43708C11.8696 7.20083 12 6.88708 12 6.55333V3.44667C12 3.11292 11.8696 2.79917 11.6337 2.56292ZM11.1667 6.55333C11.1667 6.66292 11.1225 6.77042 11.0446 6.84792L8.84833 9.04417C8.76917 9.12292 8.66417 9.16667 8.55292 9.16667H5.44625C5.33667 9.16667 5.22917 9.12208 5.15167 9.04458L2.955 6.84792C2.8775 6.77 2.83292 6.66292 2.83292 6.55333V3.44667C2.83292 3.33708 2.8775 3.22958 2.955 3.15208L5.15208 0.955417C5.23 0.877917 5.33708 0.833333 5.44667 0.833333H8.55333C8.66458 0.833333 8.76958 0.876667 8.84833 0.955417L11.0446 3.15208C11.1225 3.23 11.1667 3.33708 11.1667 3.44667V6.55333ZM8.84958 3.73958L7.58167 5.0075L8.84958 6.27542L8.26042 6.86458L6.9925 5.59667L5.72458 6.86458L5.13542 6.27542L6.40333 5.0075L5.13542 3.73958L5.72458 3.15042L6.9925 4.41833L8.26042 3.15042L8.84958 3.73958Z"
                      fill="#FF8C00"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_545_3355">
                    <rect width="28" height="28" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_545_3355">
                    <rect
                      width="10"
                      height="10"
                      fill="white"
                      transform="translate(2)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </MenuOptionNavigator>
            <MenuOptionNavigator
              onClick={(e) => {
                e.stopPropagation();
                setShowMenu(false);
                setShowEditForm(true);
              }}
              label="Editar nombre"
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_545_3355)">
                  <path
                    d="M22.1667 15.1667H10.5001V12.8333H22.1667V15.1667ZM22.1667 8.16667H14.0001V10.5H22.1667V8.16667ZM10.5001 19.8333H15.1667V17.5H10.5001V19.8333ZM28.0001 3.5V19.1497L19.1497 28H4.66675V12.8333H7.00008V25.6667H17.5001V17.5H25.6667V3.5C25.6667 2.856 25.1441 2.33333 24.5001 2.33333H16.3334V0H24.5001C26.4332 0 28.0001 1.56683 28.0001 3.5ZM19.8334 24.017L24.0171 19.8333H19.8334V24.017Z"
                    fill="#FF8C00"
                  />
                  <g clipPath="url(#clip1_545_3355)">
                    <path
                      d="M11.6337 2.56292L9.43708 0.365833C9.20083 0.13 8.88667 0 8.55333 0H5.44667C5.11292 0 4.79917 0.13 4.56292 0.36625L2.36625 2.56292C2.13 2.79917 2 3.11292 2 3.44667V6.55333C2 6.88708 2.13 7.20083 2.36625 7.43708L4.56292 9.63375C4.79917 9.87 5.11292 10 5.44667 10H8.55333C8.88667 10 9.20083 9.87 9.4375 9.63375L11.6337 7.43708C11.8696 7.20083 12 6.88708 12 6.55333V3.44667C12 3.11292 11.8696 2.79917 11.6337 2.56292ZM11.1667 6.55333C11.1667 6.66292 11.1225 6.77042 11.0446 6.84792L8.84833 9.04417C8.76917 9.12292 8.66417 9.16667 8.55292 9.16667H5.44625C5.33667 9.16667 5.22917 9.12208 5.15167 9.04458L2.955 6.84792C2.8775 6.77 2.83292 6.66292 2.83292 6.55333V3.44667C2.83292 3.33708 2.8775 3.22958 2.955 3.15208L5.15208 0.955417C5.23 0.877917 5.33708 0.833333 5.44667 0.833333H8.55333C8.66458 0.833333 8.76958 0.876667 8.84833 0.955417L11.0446 3.15208C11.1225 3.23 11.1667 3.33708 11.1667 3.44667V6.55333ZM8.84958 3.73958L7.58167 5.0075L8.84958 6.27542L8.26042 6.86458L6.9925 5.59667L5.72458 6.86458L5.13542 6.27542L6.40333 5.0075L5.13542 3.73958L5.72458 3.15042L6.9925 4.41833L8.26042 3.15042L8.84958 3.73958Z"
                      fill="#FF8C00"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_545_3355">
                    <rect width="28" height="28" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_545_3355">
                    <rect
                      width="10"
                      height="10"
                      fill="white"
                      transform="translate(2)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </MenuOptionNavigator>
            <MenuOptionNavigator
              onClick={(e) => {
                e.stopPropagation();
                handleDuplicate(id);
                setShowMenu(false);
              }}
              label="Duplicar lista"
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_545_3355)">
                  <path
                    d="M22.1667 15.1667H10.5001V12.8333H22.1667V15.1667ZM22.1667 8.16667H14.0001V10.5H22.1667V8.16667ZM10.5001 19.8333H15.1667V17.5H10.5001V19.8333ZM28.0001 3.5V19.1497L19.1497 28H4.66675V12.8333H7.00008V25.6667H17.5001V17.5H25.6667V3.5C25.6667 2.856 25.1441 2.33333 24.5001 2.33333H16.3334V0H24.5001C26.4332 0 28.0001 1.56683 28.0001 3.5ZM19.8334 24.017L24.0171 19.8333H19.8334V24.017Z"
                    fill="#FF8C00"
                  />
                  <g clipPath="url(#clip1_545_3355)">
                    <path
                      d="M11.6337 2.56292L9.43708 0.365833C9.20083 0.13 8.88667 0 8.55333 0H5.44667C5.11292 0 4.79917 0.13 4.56292 0.36625L2.36625 2.56292C2.13 2.79917 2 3.11292 2 3.44667V6.55333C2 6.88708 2.13 7.20083 2.36625 7.43708L4.56292 9.63375C4.79917 9.87 5.11292 10 5.44667 10H8.55333C8.88667 10 9.20083 9.87 9.4375 9.63375L11.6337 7.43708C11.8696 7.20083 12 6.88708 12 6.55333V3.44667C12 3.11292 11.8696 2.79917 11.6337 2.56292ZM11.1667 6.55333C11.1667 6.66292 11.1225 6.77042 11.0446 6.84792L8.84833 9.04417C8.76917 9.12292 8.66417 9.16667 8.55292 9.16667H5.44625C5.33667 9.16667 5.22917 9.12208 5.15167 9.04458L2.955 6.84792C2.8775 6.77 2.83292 6.66292 2.83292 6.55333V3.44667C2.83292 3.33708 2.8775 3.22958 2.955 3.15208L5.15208 0.955417C5.23 0.877917 5.33708 0.833333 5.44667 0.833333H8.55333C8.66458 0.833333 8.76958 0.876667 8.84833 0.955417L11.0446 3.15208C11.1225 3.23 11.1667 3.33708 11.1667 3.44667V6.55333ZM8.84958 3.73958L7.58167 5.0075L8.84958 6.27542L8.26042 6.86458L6.9925 5.59667L5.72458 6.86458L5.13542 6.27542L6.40333 5.0075L5.13542 3.73958L5.72458 3.15042L6.9925 4.41833L8.26042 3.15042L8.84958 3.73958Z"
                      fill="#FF8C00"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_545_3355">
                    <rect width="28" height="28" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_545_3355">
                    <rect
                      width="10"
                      height="10"
                      fill="white"
                      transform="translate(2)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </MenuOptionNavigator>
          </div>
        </>
      )}
      {showEditForm && (
        <div className="absolute top-0 z-20 left-0 w-full min-h-screen text-white bg-black bg-opacity-60">
          <div className="mt-40 mx-8 rounded-lg my-auto  border-t flex flex-col space-y-3 border-buddy-bg-2 bg-secondary-bg p-4">
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setShowEditForm(false);
                }}
                className="p-1"
              >
                <XMarkIcon className="size-5" />
              </button>
            </div>
            <InputState
              name="newName"
              label="Nombre de la lista"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
            <PrimaryButton onClick={() => handleUpdate(id, newName)}>
              Guardar
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShoppingListItem;
