import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

const BuddyRecipeCard = ({ recipe, to = '' }) => {
  return (
    <>
      <Link className="w-full" to={'/recipe?id=' + recipe.id}>
        <div className="w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl">
          <div className="relative w-16 shrink-0">
            <div className="relative">
              <img
                src={recipe.image_url}
                alt=""
                className="h-14 w-14 rounded-md object-cover object-center"
              />
            </div>
          </div>
          <div className="flex flex-col justify-center grow pl-5 text-start">
            <div
              style={{ fontSize: '17px', fontWeight: '500' }}
              className="text-white body-medium"
            >
              {recipe.name}
            </div>
          </div>
          <ChevronRightIcon className="size-6 text-buddy-text-3 grow-0 mt-auto mb-auto" />
        </div>
      </Link>
    </>
  );
};

export default BuddyRecipeCard;
