import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { getUserInfo } from '../auth';
import { Link } from 'react-router-dom';

const MenuDia = () => {
  const { id } = useParams();
  const hoy = id;
  const userinfo = getUserInfo();
  const u = userinfo.id;
  const [recipesByMoment, setRecipesByMoment] = useState({});

  function formatDateSql(dateString) {
    // Split the date string into an array of day, month, and year components
    const [day, month, year] = dateString.split('/');

    // Pad the month and day with leading zeros if necessary (optional)
    const paddedMonth = month.padStart(2, '0');
    const paddedDay = day.padStart(2, '0');

    // Return the formatted date string
    return `${year}-${paddedMonth}-${paddedDay}`;
  }

  const getWeekData = () => {
    // Obtener la fecha actual
    const today = new Date(Date.parse(hoy));
    const todayFormatted = today.toLocaleDateString();
    const todaySql = formatDateSql(todayFormatted);

    // Obtener el número de la semana del año
    const weekNumber = getWeekNumber(today);

    // Obtener las fechas de cada día de la semana
    const weekDates = getWeekDates(today);

    // Formatear las fechas al formato local
    const formattedWeekDates = weekDates.map((date) =>
      date.toLocaleDateString(),
    );

    // Formatear las fechas al formato sql
    const sqlWeekDates = formattedWeekDates.map((date) => formatDateSql(date));

    // Formatear fechas para los links
    const dashRegex = /-/g;
    const linkWeekDates = sqlWeekDates.map((date) =>
      date.replace(dashRegex, ''),
    );

    // Obtener el índice del día actual en la semana
    let todayIndex = formattedWeekDates.indexOf(todayFormatted);
    if (todayIndex == -1) {
      todayIndex = 6;
    }

    // Obtener el nombre del día actual en la semana
    const todayText = getDayOfWeekText(todayIndex);

    // Devolver un objeto con la información
    return {
      weekNumber,
      weekDates: formattedWeekDates,
      todayIndex,
      todayFormatted,
      todaySql,
      sqlWeekDates,
      linkWeekDates,
      todayText,
    };
  };

  // Función para obtener el número de la semana del año
  function getWeekNumber(date) {
    // Obtener el primer día del año
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);

    // Obtener la diferencia en días entre la fecha actual y el primer día del año
    const daysSinceStartOfYear = Math.floor(
      (date - firstDayOfYear) / (1000 * 60 * 60 * 24),
    );

    // Calcular el número de la semana
    const weekNumber = Math.ceil((daysSinceStartOfYear + 1) / 7);

    // Devolver el número de la semana
    return weekNumber;
  }

  // Función para obtener las fechas de cada día de la semana
  function getWeekDates(date) {
    // Obtener el día de la semana de la fecha actual
    const dayOfWeek = date.getDay();

    // Obtener la fecha del lunes de la semana actual
    const mondayDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - dayOfWeek + 1,
    );

    // Crear un array para almacenar las fechas de la semana
    const weekDates = [];

    // Recorrer los días de la semana
    for (let i = 0; i < 7; i++) {
      // Obtener la fecha del día actual
      const dayDate = new Date(
        mondayDate.getFullYear(),
        mondayDate.getMonth(),
        mondayDate.getDate() + i,
      );

      // Agregar la fecha al array
      weekDates.push(dayDate);
    }

    // Devolver el array de fechas
    return weekDates;
  }

  function getDayOfWeekText(dayNumber) {
    // Array con los nombres de los días de la semana
    const daysOfWeek = [
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
      'Domingo',
    ];

    // Si el número de día no está entre 0 y 6, devolver "Día no válido"
    if (dayNumber < 0 || dayNumber > 6) {
      return 'Día no válido';
    }

    // Devolver el nombre del día de la semana
    return daysOfWeek[dayNumber];
  }

  const {
    weekNumber,
    weekDates,
    todayIndex,
    todayFormatted,
    todaySql,
    sqlWeekDates,
    linkWeekDates,
    todayText,
  } = getWeekData();

  useEffect(() => {
    if (!!id) {
      const response = fetch(
        `https://buddymarket.io/dummy/apibm/menus/getmenurecipes.php/?u=${u}&query=${id}`,
      )
        .then((response) => response.json())
        .then((data) => {
          // Agrupar recetas por momento
          const recipesGroupedByMoment = data.data.reduce((acc, recipe) => {
            acc[recipe.momento] = [...(acc[recipe.momento] || []), recipe];
            return acc;
          }, {});

          setRecipesByMoment(recipesGroupedByMoment);
        })
        .catch((error) => console.error('Error fetching recipes:', error));
    }
  }, [id]);

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={`../menus/${todaySql}`}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Menú del {todayText}</h2>
      </div>

      <div className="rectangulo flex equiespaciado vcentrado mt0 p0">
        <div className="w10">
          <Link
            to={`../menu-dia/${sqlWeekDates[todayIndex - 1]}`}
            className={todayIndex == 0 ? 'oculto' : ''}
          >
            <span className="inline simbologran izquierda">
              <FontAwesomeIcon icon={faCircleArrowLeft} className="" />
            </span>
          </Link>
        </div>

        <div className="centrado">
          <span className="textoblanco">{todayText}</span>
          <br />
          <h3 className="textoblanco">{todayFormatted}</h3>
        </div>

        <div className="w10">
          <Link
            to={`../menu-dia/${sqlWeekDates[todayIndex + 1]}`}
            className={todayIndex == 6 || todayIndex == -1 ? 'oculto' : ''}
          >
            <span className="inline simbologran derecha">
              <FontAwesomeIcon icon={faCircleArrowRight} className="" />
            </span>
          </Link>
        </div>
      </div>

      <div className="rectangulo centrado p0">
        <Link to={`../agregar-receta-menu/${todaySql}`}>
          <button className="round fondonaranja textoblanco p10">
            Añadir receta
          </button>
        </Link>
      </div>
      <div className="rectangulo mt0 p0 mb20">
        <div id="desayuno" className="w100 mb10">
          <div className="flex spacebetween vcentrado linkhover p0-10">
            <span className={'textoblanco'}>
              {' '}
              <strong>Desayuno</strong>
            </span>
            <span className="inline simbologran derecha strong">&#43;</span>
          </div>
          <div className="recetas w100 flex wrap left gap10">
            {recipesByMoment['desayuno'] &&
              recipesByMoment['desayuno'].map((recipe) => (
                <div key={recipe.id} className="w30">
                  <Link to={`../receta/${recipe.receta}`}>
                    <img
                      className="w100 round mb10 mt10"
                      src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${recipe.photo}`}
                      alt={recipe.name}
                    />
                    <br />
                    <p className="mb20">{recipe.name}</p>
                  </Link>
                </div>
              ))}
          </div>
        </div>

        <div id="aperitivo" className="w100 mb10">
          <div className="flex spacebetween vcentrado linkhover p0-10">
            <span className={'textoblanco'}>
              {' '}
              <strong>Aperitivo</strong>
            </span>
            <span className="inline simbologran derecha strong">&#43;</span>
          </div>
          <div className="recetas w100 flex wrap left gap10">
            {recipesByMoment['aperitivo'] &&
              recipesByMoment['aperitivo'].map((recipe) => (
                <div key={recipe.id} className="w30">
                  <Link to={`../receta/${recipe.receta}`}>
                    <img
                      className="w100 round mb10 mt10"
                      src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${recipe.photo}`}
                      alt={recipe.name}
                    />
                    <br />
                    <p className="mb20">{recipe.name}</p>
                  </Link>
                </div>
              ))}
          </div>
        </div>

        <div id="almuerzo" className="w100 mb10">
          <div className="flex spacebetween vcentrado linkhover p0-10">
            <span className="textoblanco">
              {' '}
              <strong>Almuerzo</strong>
            </span>
            <span className="inline simbologran derecha strong">&#43;</span>
          </div>
          <div className="recetas w100 flex wrap left gap10">
            {recipesByMoment['almuerzo'] &&
              recipesByMoment['almuerzo'].map((recipe) => (
                <div key={recipe.id} className="w30">
                  <Link to={`../receta/${recipe.receta}`}>
                    <img
                      className="w100 round mb10 mt10"
                      src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${recipe.photo}`}
                      alt={recipe.name}
                    />
                    <br />
                    <p className="mb20">{recipe.name}</p>
                  </Link>
                </div>
              ))}
          </div>
        </div>

        <div id="merienda" className="w100 mb10">
          <div className="flex spacebetween vcentrado linkhover p0-10">
            <span className={'textoblanco'}>
              {' '}
              <strong>Merienda</strong>
            </span>
            <span className="inline simbologran derecha strong">&#43;</span>
          </div>
          <div className="recetas w100 flex wrap left gap10">
            {recipesByMoment['merienda'] &&
              recipesByMoment['merienda'].map((recipe) => (
                <div key={recipe.id} className="w30">
                  <Link to={`../receta/${recipe.receta}`}>
                    <img
                      className="w100 round mb10 mt10"
                      src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${recipe.photo}`}
                      alt={recipe.name}
                    />
                    <br />
                    <p className="mb20">{recipe.name}</p>
                  </Link>
                </div>
              ))}
          </div>
        </div>

        <div id="cena" className="w100 mb10">
          <div className="flex spacebetween vcentrado linkhover p0-10" to={'#'}>
            <span className="textoblanco">
              {' '}
              <strong>Cena</strong>
            </span>
            <span className="inline simbologran derecha strong">&#43;</span>
          </div>
          <div className="recetas w100 flex wrap left gap10">
            {recipesByMoment['cena'] &&
              recipesByMoment['cena'].map((recipe) => (
                <div key={recipe.id} className="w30">
                  <Link to={`../receta/${recipe.receta}`}>
                    <img
                      className="w100 round mb10 mt10"
                      src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${recipe.photo}`}
                      alt={recipe.name}
                    />
                    <br />
                    <p className="mb20">{recipe.name}</p>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuDia;
