import clsx from 'clsx';
import React from 'react';

const PrimaryButton = ({ size = 'md', children, className, ...attributes }) => {
  const sizeClasses =
    size === 'md' ? 'w-full p-3 rounded-xl text-lg' : 'py-2 px-4 rounded-lg';
  const classes =
    'bg-white bg-opacity-20  text-primary font-semibold  ' + sizeClasses;

  return (
    <>
      <button
        className={clsx(
          classes,
          {
            'cursor-not-allowed bg-opacity-5': attributes.disabled,
          },
          className,
        )}
        disabled={attributes.disabled}
        type={attributes.type}
        {...attributes}
      >
        {children}
      </button>
    </>
  );
};
export default PrimaryButton;
