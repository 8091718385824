import React from 'react';
import { useState, useEffect } from 'react';
import FooterMain from './FooterMain';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import anuncio from '../img/anuncio.png';
import buddymaker from '../img/buddymaker.png';
import coreana from '../img/coreana.png';
import japonesa from '../img/japonesa.png';
import italiana from '../img/italiana.png';
import ensaladillaRusa from '../img/ensaladilla-rusa.png';
import ensaladillaGriega from '../img/ensaladilla-griega.png';

const Recetas = () => {
  const navigate = useNavigate();
  const [categoriasprin, setCategoriasprin] = useState([]);
  const [recetas, setRecetas] = useState([]);
  const [recetaspref, setRecetasPref] = useState([]);
  const [buddymakers, setBuddymakers] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [recipes, setRecipes] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    fetch('https://buddymarket.io/dummy/apibm/users/readbuddies.php?limit=4')
      .then((response) => response.json())
      .then((data) => {
        setBuddymakers(data.data);
      })
      .catch((error) => console.error('Error fetching categories:', error));
  }, []);

  useEffect(() => {
    fetch(
      'https://buddymarket.io/dummy/apibm/recipes/readcategories.php?type=all&limit=3',
    )
      .then((response) => response.json())
      .then((data) => {
        setCategoriasprin(data.data);
      })
      .catch((error) => console.error('Error fetching categories:', error));
  }, []);

  useEffect(() => {
    fetch(
      'https://buddymarket.io/dummy/apibm/recipes/readlist.php?category=all&limit=2',
    )
      .then((response) => response.json())
      .then((data) => {
        setRecetas(data.data);
      })
      .catch((error) => console.error('Error fetching recipes:', error));
  }, []);

  useEffect(() => {
    fetch(
      'https://buddymarket.io/dummy/apibm/recipes/readlistpref.php?category=all&limit=2',
    )
      .then((response) => response.json())
      .then((data) => {
        setRecetasPref(data.data);
      })
      .catch((error) => console.error('Error fetching recipes:', error));
  }, []);

  const fetchRecipes = async () => {
    if (!!searchQuery) {
      try {
        const response = await fetch(
          `https://buddymarket.io/dummy/apibm/recipes/searchrecipes.php?query=${searchQuery}`,
        );
        const data = await response.json();
        setRecipes(data.data);
      } catch (error) {
        console.error('Error fetching recipes:', error);
      }
    }
  };

  const handleSearchChange = async (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value.length > 2) {
      await fetchRecipes();
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  };

  const handleRecipeClick = (recipeId) => {
    navigate(`../receta/${recipeId}`);
  };

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Recetas</h2>
      </div>
      <div className="rectangulo centrado busqueda">
        <form id="busqueda" action="buscar">
          <div className="input-container">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon2" />
            <input
              className="w100"
              type="text"
              name="user"
              autoComplete="off"
              placeholder="Buscar en BuddyMarket"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <br />
            {isDropdownOpen && (
              <div className="dropdown">
                <ul>
                  {recipes?.map((recipe) => (
                    <li
                      key={recipe.id}
                      onClick={() => handleRecipeClick(recipe.id)}
                    >
                      <img
                        className="w20"
                        src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${recipe.photo}`}
                        alt={recipe.name}
                      />{' '}
                      <span className="textoblanco">{recipe.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </form>
      </div>
      <div className="rectangulo p0 wrapbotones">
        <div className="scrollbotones flex">
          <a href="categoriafiltros">
            <button className="button-aux-medio fondonaranja">
              Ingredientes
            </button>
          </a>
          <a href="categoriafiltros">
            <button className="button-aux-medio fondonaranja">
              Tipo de Comida
            </button>
          </a>
          <a href="categoriafiltros">
            <button className="button-aux-medio fondonaranja">Dieta</button>
          </a>
          <a href="categoriavalornutricional">
            <button className="button-aux-medio fondonaranja">
              Valor nutricional
            </button>
          </a>
        </div>
      </div>
      <div className="maxrectangulo centrado">
        <img className="anuncio" src={anuncio} alt="anuncio" />
      </div>
      <div className="rectangulo round relative fondouno">
        <h2 className="pequeno mb20">Buddymakers</h2>
        <a href="buddymakers">
          <button className="button-aux-mini botonvermas fondonaranja">
            Ver todo
          </button>
        </a>
        <div className="flex nowrap gap10 buddys">
          {buddymakers.map((buddy) => (
            <a
              key={buddy.id}
              className="centrado block w20"
              href={`buddymaker/${buddy.id}`}
            >
              <img
                className="w100"
                src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/users/${buddy.foto}`}
                alt={buddy.username}
              />
              <br />
              <span className="minititulo">{buddy.username}</span>
            </a>
          ))}
        </div>
      </div>
      <div className="rectangulo round relative fondouno">
        <h2 className="pequeno mb20">Categorías principales</h2>
        <a href="catprincipales">
          <button className="button-aux-mini botonvermas fondonaranja">
            Ver todo
          </button>
        </a>
        <div className="flex nowrap equiespaciado">
          {categoriasprin.map((categoriap) => (
            <a
              key={categoriap.id}
              className="centrado block w30"
              href={`categoria/${categoriap.id}`}
            >
              <img
                className="w100 h80"
                src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/categorias/${categoriap.foto}`}
                alt={categoriap.name}
              />
              <span className="minititulo dentro">{categoriap.name}</span>
            </a>
          ))}
        </div>
      </div>
      <div className="rectangulo round relative fondouno">
        <h2 className="pequeno mb20">En base a tus preferencias</h2>
        <a href="catpreferencias">
          <button className="button-aux-mini botonvermas fondonaranja">
            Ver todo
          </button>
        </a>
        <div className="flex nowrap equiespaciado">
          {recetaspref.map((recetapref) => (
            <div key={recetapref.id} className="w100">
              <div className="containerimage w100">
                <a
                  className="centrado block w100"
                  href={`receta/${recetapref.id}`}
                >
                  <img
                    className="w100 h140"
                    src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${recetapref.photo}`}
                    alt={recetapref.name}
                  />
                </a>
              </div>
              <div className="minititulo centrado dentro">
                {recetapref.name}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="rectangulo round mb20 relative fondouno">
        <h2 className="pequeno mb20">Las últimas recetas</h2>
        <a href="recetas-todas">
          <button className="button-aux-mini botonvermas fondonaranja">
            Ver todo
          </button>
        </a>
        <div className="flex nowrap equiespaciado">
          {recetas.map((receta) => (
            <div key={receta.id} className="w100">
              <div className="containerimage w100">
                <a className="centrado block w100" href={`receta/${receta.id}`}>
                  <img
                    className="w100 h140"
                    src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${receta.photo}`}
                    alt={receta.name}
                  />
                </a>
              </div>
              <div className="minititulo centrado dentro">{receta.name}</div>
            </div>
          ))}
        </div>
      </div>
      <br />
      <br />
      <FooterMain />
    </div>
  );
};

export default Recetas;
