import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FoodCategory from '../api/services/FoodCategory.js';
import { useSearchParams } from 'react-router-dom';
import Recipe from '../api/services/Recipe.js';
import UserFavoriteRecipe from '../api/services/UserFavoriteRecipe.js';
import UserHTile from './ui/UserHTile.js';
import { BookmarkIcon, HeartIcon } from '@heroicons/react/24/outline';
import {
  MinusIcon,
  PlusIcon,
  HeartIcon as HeartIconSolid,
} from '@heroicons/react/24/solid';
import Badge from './ui/Badge.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCutlery } from '@fortawesome/free-solid-svg-icons';
import FoodGenericImg from '../img/food-generic.png';

const RecipeShow = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [foodCategoryId, setFoodCategoryId] = useState('');
  const [foodCategoryOptions, setFoodCategoryOptions] = useState([]);
  const [status, setStatus] = useState(0);
  const [description, setDescription] = useState('');
  const navigate = useNavigate();
  const [recipe, setRecipe] = useState({});
  const [name, setName] = useState('');
  const [updateImage, setUpdateImage] = useState(false);
  const [img, setImg] = useState();
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState(1);
  const [rations, setRations] = useState();
  const [followed, setFollowed] = useState();

  const user = JSON.parse(localStorage.getItem('user'));
  const id = searchParams.get('id') ?? '';

  const statusOptions = [
    {
      value: 1,
      label: 'Publicada',
    },
    {
      value: 0,
      label: 'Borrador',
    },
  ];

  const pageOptions = [
    {
      value: 1,
      label: 'General',
    },
    {
      value: 2,
      label: 'Ingredientes',
    },
    {
      value: 3,
      label: 'Instrucciones',
    },
  ];

  useEffect(() => {
    getRecipe(id);
    getFoodCategories();
  }, []);

  const getRecipe = async (id) => {
    try {
      const response = await Recipe.get(id);
      const recipe = response.data;
      setStatus(recipe?.active ? 1 : 0);
      setRecipe(recipe);
      setRations(recipe.rations);
      setFollowed(recipe.followed);
      setDescription(recipe?.description);
    } catch (error) {
      console.error('Error fetching author:', error);
    }
  };

  const handleChangeTab = (tab) => {
    setTab(tab);
  };

  const handleStore = async () => {
    try {
      var fileInput = document.querySelector('input[type="file"]');
      const body = new FormData();
      body.append('name', document.querySelector('[name="name"]').value);
      if (fileInput) {
        body.append('image_file', fileInput.files[0]);
      }
      body.append('description', description);
      body.append(
        'preparation_time',
        parseInt(document.querySelector('[name="preparation_time"]').value),
      );
      body.append(
        'cook_time',
        parseInt(document.querySelector('[name="cook_time"]').value),
      );
      body.append(
        'rations',
        parseInt(document.querySelector('[name="rations"]').value),
      );
      body.append(
        'food_category_id',
        document.querySelector('[name="food_category_id"]').value,
      );
      body.append('active', status);

      const response = await Recipe.update(id, body);

      navigate(-1);
    } catch (error) {
      alert(
        error.response.payload.message ??
          'Error inesperado. Inténtalo de nuevo.',
      );
      console.error('Error creating recipe:', error);
    }
  };

  const getFoodCategories = async () => {
    try {
      const response = await FoodCategory.list();
      var options = [];
      response.data.map((type) => {
        options.push({
          value: type.id,
          label: type.name,
        });
      });
      setFoodCategoryOptions(options);
    } catch (error) {
      console.error('Error fetching FoodCategory:', error);
    }
  };

  const handleOnChangeFoodCategoryId = (e) => {
    setFoodCategoryId(e.target.value);
  };

  const handleOnChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleOnChangeStatus = (value) => {
    setStatus(value);
  };

  const changeRations = (value) => {
    var newRations = rations + value;
    if (newRations <= 0) {
      newRations = 1;
    }
    setRations(newRations);
  };

  const handleOnChangeImageClick = () => {
    setUpdateImage(!updateImage);
  };

  const handleOnChangePage = (value) => {
    setPage(value);
  };

  const handleFollowClick = async () => {
    try {
      if (followed) {
        await UserFavoriteRecipe.delete(user.id, id);
      } else {
        await UserFavoriteRecipe.create(user.id, id);
      }
      getRecipe(id);
    } catch (error) {
      console.error('Error fetching recipe image:', error);
    }
  };

  return (
    <div className="h-full bg-no-repeat bg-cover bg-center">
      <div className="h-full bg-secondary-bg bg-opacity-60 p-6">
        <div className="w-full h-12  flex justify-start text-title items-center text-lg mb-2">
          <div className="w-10 h-10 rounded-full absolute bg-white bg-opacity-20 flex flex-col justify-center items-center">
            <button
              onClick={(e) => {
                navigate(-1, { replace: true });
              }}
              className="text-title"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          </div>
          <div className="grow text-center ps-8">
            <div className="font-bold text-md">
              <span>{recipe?.name}</span>
            </div>
          </div>
          <div className="shrink">
            <button
              onClick={handleFollowClick}
              className="flex items-center text-primary"
            >
              {followed ? (
                <HeartIconSolid className="size-7" />
              ) : (
                <HeartIcon className="size-7" />
              )}
            </button>
          </div>
        </div>

        <div className="flex flex-col py-2 space-y-5">
          <div className="w-full">
            {recipe && recipe?.image_url && (
              <div className="relative overflow-hidden rounded h-64 flex items-center">
                <img
                  className="w-full rounded"
                  src={recipe?.image_url}
                  alt={recipe?.name}
                />
              </div>
            )}
          </div>

          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-1 text-xs">
              <BookmarkIcon className="size-6 text-primary"></BookmarkIcon>{' '}
              <span className="text-primary">{recipe?.favorites_count}</span>{' '}
              <span className="text-buddy-text-1">Personas guardaron esto</span>
            </div>
            <div>
              {recipe && recipe.user && <UserHTile user={recipe.user} />}
            </div>
          </div>

          <div className="">
            {recipe?.dietRestrictions?.map((restriction) => {
              return (
                <Badge key={restriction.id} style="solid">
                  {restriction.name}
                </Badge>
              );
            })}
            {recipe?.allergies?.map((allergy) => {
              return (
                <Badge key={allergy.id} style="solid">
                  {allergy.name}
                </Badge>
              );
            })}
          </div>

          <div className="w-full">
            <div className="w-full flex gap-0">
              <button
                type="button"
                onClick={(e) => {
                  handleChangeTab(1);
                }}
                className={
                  'w-full flex flex-col justify-center items-center text-center text-sm py-4 px-1 text-md border-b-2 ' +
                  (tab == 1 ? 'border-primary' : 'border-buddy-bg-4')
                }
              >
                <span className="font-semibold text-primary ">
                  {recipe?.recipeIngredients?.length || 0}
                </span>
                <span className="text-buddy-text-1 ">Ingredientes</span>
              </button>
              <button
                type="button"
                onClick={(e) => {
                  handleChangeTab(2);
                }}
                className={
                  'w-full flex flex-col justify-center items-center text-center text-sm py-4 px-1 text-md border-b-2 ' +
                  (tab == 2 ? 'border-primary' : 'border-buddy-bg-4')
                }
              >
                <span className="font-semibold text-primary ">
                  {(recipe?.preparation_time || 0) + (recipe?.cook_time || 0)}{' '}
                  min
                </span>
                <span className="text-buddy-text-1 ">Instrucciones</span>
              </button>
              <button
                type="button"
                onClick={(e) => {
                  handleChangeTab(3);
                }}
                className={
                  'w-full flex flex-col justify-center items-center text-center text-sm py-4 px-1 text-md border-b-2 ' +
                  (tab == 3 ? 'border-primary' : 'border-buddy-bg-4')
                }
              >
                <span className="font-semibold text-primary ">
                  <FontAwesomeIcon icon={faCutlery} />
                </span>
                <span className="text-buddy-text-1 ">
                  Valores nutricionales
                </span>
              </button>
            </div>
          </div>
          {tab == 1 && (
            <div className="text-buddy-text-1">
              <div className="flex space-x-3 mb-4">
                <button
                  onClick={(e) => {
                    changeRations(-1);
                  }}
                  className="ring-1 rounded ring-primary p-1"
                >
                  <MinusIcon className="size-5 text-primary"></MinusIcon>
                </button>
                <div>{rations} raciones</div>
                <button
                  onClick={(e) => {
                    changeRations(1);
                  }}
                  className="ring-1 rounded ring-primary p-1"
                >
                  <PlusIcon className="size-5 text-primary"></PlusIcon>
                </button>
              </div>
              {recipe?.recipeIngredients?.map((recipeIngredient) => (
                <div
                  key={recipeIngredient.id}
                  className="flex justify-between mb-2 items-center"
                >
                  <div className="flex space-x-3 items-center">
                    <img
                      className="w-8 h-8 rounded-full"
                      src={
                        recipeIngredient?.ingredient?.image_url ||
                        FoodGenericImg
                      }
                    ></img>
                    <span className="me-1">
                      {recipeIngredient?.ingredient?.name}
                    </span>
                  </div>
                  <div>
                    <span className="font-semibold">
                      {Number(
                        (recipeIngredient.quantity / recipe.rations) * rations,
                      ).toFixed(1)}{' '}
                      {recipeIngredient.measure.name}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}

          {tab == 2 && (
            <div className="flex flex-col space-y-2">
              {recipe?.recipeSteps.map((step, i) => {
                return (
                  <div key={i}>
                    <div className="text-buddy-text-1 font-semibold text-sm mb-2">
                      Paso {i + 1}
                    </div>
                    <div
                      key={step.id}
                      className="bg-buddy-bg-2 p-2 text-buddy-text-1 rounded-lg"
                    >
                      {step.description}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {tab == 3 && (
            <div className="text-buddy-text-1">
              <p className="text-primary text-sm mb-2">Por ración</p>
              <div className="w-full grid grid-cols-2 gap-y-2">
                <div className="border-b border-buddy-bg-4 p-1">Grasas</div>
                <div className="text-end border-b border-buddy-bg-4 p-1">
                  {Number(recipe?.fats).toFixed(1)} g
                </div>
                <div className="border-b border-buddy-bg-4 p-1">
                  Carbohidratos
                </div>
                <div className="text-end border-b border-buddy-bg-4 p-1">
                  {Number(recipe?.carbohydrates).toFixed(1)} g
                </div>
                <div className="border-b border-buddy-bg-4 p-1">Proteínas</div>
                <div className="text-end border-b border-buddy-bg-4 p-1">
                  {Number(recipe?.proteins).toFixed(1)} g
                </div>
                <div className="border-b border-buddy-bg-4 p-1">Fibra</div>
                <div className="text-end border-b border-buddy-bg-4 p-1">
                  {Number(recipe?.fiber).toFixed(1)} g
                </div>
                <div className="border-b border-buddy-bg-4 p-1">Calorías</div>
                <div className="text-end border-b border-buddy-bg-4 p-1">
                  {recipe?.calories} Kcal
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecipeShow;
