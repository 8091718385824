import React, { useState, useEffect } from 'react';
import Toolbar from './ui/Toolbar';
import { useNavigate } from 'react-router-dom';
import HealthPreference from '../api/services/HealthPreference';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import SelectRounded from './forms/SelectRounded';

const HealthPreferencesPersonalData = () => {
  const navigate = useNavigate();
  const [cookingTimeRanges, setCookingTimeRanges] = useState([]);
  const [data, setData] = useState({
    daily_meals: 1,
    cooking_time_range_id: 1,
    weight: null,
    height: null,
  });

  const daily_mealsOptions = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
  ];

  const handleClick = (e) => {
    const user = JSON.parse(localStorage.getItem('user'));
    user.daily_meals = data.daily_meals;
    user.cooking_time_range_id = data.cooking_time_range_id;
    user.weight = data.weight;
    user.height = data.height;
    localStorage.setItem('user', JSON.stringify(user));
    navigate('/healthPreferencesMedicalCondition');
  };

  const loadCookingTimeRanges = async () => {
    try {
      const response = await HealthPreference.getCookingTimeRanges();
      const adaptedOptions = response.data.map((spendRange) => {
        return {
          value: spendRange.id,
          label: spendRange.name,
        };
      });
      setCookingTimeRanges(adaptedOptions);
    } catch (error) {
      console.log(error);
    }
  };

  const loadUserData = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setData({
        daily_meals: user.daily_meals ?? 1,
        cooking_time_range_id: user.cooking_time_range_id ?? 1,
        weight: user.weight ?? null,
        height: user.height ?? null,
      });
    }
  };

  useEffect(() => {
    loadCookingTimeRanges();
    loadUserData();
  }, []);

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="flex flex-col justify-between min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col bg-red  gap-3 mb-6">
          <Toolbar to={'/healthPreferencesSpending'}>
            <span>Paso 2</span> <span className="text-primary">de 7</span>
          </Toolbar>
          <div className="flex flex-col bg-red items-center gap-6 mb-8">
            <h2 className="w-full text-white text-xl text-center font-semibold">
              ¿Cuántas comidas realizas al día?
            </h2>
            <div className="w-[120px] h-[32px] mb-4">
              <SelectRounded
                name="measure_id"
                includeBorder={false}
                size="small"
                action={true}
                onChange={(e) =>
                  setData({ ...data, daily_meals: parseInt(e.target.value) })
                }
                value={data.daily_meals}
                options={daily_mealsOptions}
              />
            </div>
            <h2 className="w-full text-white text-xl text-center font-semibold">
              ¿Cuánto tiempo quieres dedicar a la preparación de tus comidas?
            </h2>
            <div className="w-[120px] h-[32px]">
              <SelectRounded
                name="measure_id"
                includeBorder={false}
                size="small"
                action={true}
                onChange={(e) =>
                  setData({
                    ...data,
                    cooking_time_range_id: parseInt(e.target.value),
                  })
                }
                value={data.cooking_time_range_id}
                options={cookingTimeRanges}
              />
            </div>
          </div>
          <div className="flex flex-col gap-3 mb-8">
            <label className="text-white font-semibol text-xl">
              {' '}
              Peso actual{' '}
              <span className="text-primary font-normal text-base">
                (en Kilogramos)
              </span>
            </label>
            <div className="w-full shrink-0 grow h-[44px] bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-transparent ring-1">
              <input
                onChange={(e) =>
                  setData({ ...data, weight: parseInt(e.target.value) })
                }
                value={data.weight}
                aria-autocomplete="none"
                list="autocompleteOff"
                className="appearance-none border-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl mb-2"
                name="recipe_step"
                type="number"
                placeholder="Peso actual"
              />
              <span className="text-buddy-text-7 text-xs ml-3">Ej: 74</span>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <label className="text-white font-semibol text-xl">
              Estatura{' '}
              <span className="text-primary font-normal text-base">
                (en centimetros)
              </span>
            </label>
            <div className="w-full shrink-0 grow h-[44px] bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-transparent ring-1">
              <input
                onChange={(e) =>
                  setData({ ...data, height: parseInt(e.target.value) })
                }
                value={data.height}
                type="number"
                aria-autocomplete="none"
                list="autocompleteOff"
                className="appearance-none border-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl mb-2"
                name="recipe_step"
                placeholder="Estatura"
              />
              <span className="text-buddy-text-7 text-xs ml-3">Ej: 177</span>
            </div>
          </div>
        </div>
        <FooterToolbar>
          <PrimaryButton
            type="button"
            onClick={handleClick}
            disabled={!data.weight || !data.height}
          >
            Siguiente
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default HealthPreferencesPersonalData;
