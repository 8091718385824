import client from "../client";

export default {
  profile: () => client.get("profile").json(),
  list: (params) => client.get("users", {searchParams: params}).json(),
  listBuddMakers: () => client.get("users?role_id=5&limit=10").json(),
  get: (id) => client.get(`users/${id}`).json(),
  getAllergies: (id) => client.get(`users/${id}/allergies`).json(),
  getDietRestrictions: (id) => client.get(`users/${id}/dietRestrictions`).json(),
  update: (id, body) => client.post(`users/${id}`, {body: body}).json(),
  updateImage: (id, data) => client.post(`users/${id}/image`, {json: data}).json(),
  updatePassword: (id, data) => client.post(`users/${id}/password`, {json: data}).json(),
  updateEmail: (id, data) => client.post(`users/${id}/email`, {json: data}).json(),
  createAuthToken: (id) => client.post(`users/${id}/authTokens`).json(),
  image: (id) => client.get(`users/${id}/image`),
  delete: (id) => client.delete(`users/${id}`).json(),
  favoriteRecipesList: (id, params) => client.get(`users/${id}/favoriteRecipes`, {searchParams: params}).json(),
  recipeList: (id) => client.get(`users/${id}/recipes`).json(),
  recipeGet: (user, recipe) => client.get(`users/${user}/recipes/${recipe}`).json(),
  updateAllergies: (id, data) => client.post(`users/${id}/allergies/sync`, {json: data}).json(),
  updateDietRestrictions: (id, data) => client.post(`users/${id}/dietRestrictions/sync`, {json: data}).json(),
};