import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Textarea from './forms/Textarea';

const ProfileForm11 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      const el = document.querySelector('[name="medical_conditions"]');
      if (el) {
        el.value = data.medical_conditions ?? '';
      }
    }
  }, []);

  const handleClick = (e) => {
    const data = JSON.parse(localStorage.getItem('register_data'));
    data.medical_conditions = document.querySelector(
      '[name="medical_conditions"]',
    ).value;
    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/profileForm12');
  };

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={'/profileForm10'} replace={true}>
              <div className="text-sm">
                <span>Paso 11</span> <span className="text-primary">de 28</span>
              </div>
            </Toolbar>
            <h2 className="w-full text-white text-xl font-semibold">
              ¿Tienes alguna condición médica que deberíamos conocer?{' '}
              <span className="text-primary">(opcional)</span>
            </h2>
            <Textarea name="medical_conditions" />
            <div className="my-2"></div>
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick}>
                Siguiente
              </PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm11;
