import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Select from './forms/Select';
import SelectList from './forms/SelectList';

const ProfileForm22 = () => {
  const navigate = useNavigate();

  const [sleepRecomendedHours, setSleepRecomendedHours] = useState('');
  const [sleepHours, setSleepHours] = useState('');
  const sleepHoursOptions = [];
  const sleepRecomendedHoursOptions = [
    {
      value: 1,
      label: 'Sí',
    },
    {
      value: 0,
      label: 'No',
    },
  ];

  for (var i = 3; i < 8; i++) {
    sleepHoursOptions.push({
      value: i,
      label: i,
    });
  }

  const handleClick = (e) => {
    if (sleepRecomendedHours !== '' && sleepHours !== '') {
      var data = JSON.parse(localStorage.getItem('register_data'));
      data.sleep_recomended_hours = sleepRecomendedHours;
      data.sleep_hours = sleepHours;
      localStorage.setItem('register_data', JSON.stringify(data));
      navigate('/profileForm23');
    } else {
      alert('Responda a las preguntas no opcionales para continuar.');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setSleepRecomendedHours(data.sleep_recomended_hours ?? '');
      setSleepHours(data.sleep_hours ?? '');
    }
  }, []);

  const handleOnChangeSleepHours = (e) => {
    setSleepHours(e.target.value);
  };

  const handleOnChangeSleepRecomendedHours = (value) => {
    setSleepRecomendedHours(value);
  };

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={'/profileForm21'} replace={true}>
              <div className="text-sm">
                <span>Paso 22</span> <span className="text-primary">de 28</span>
              </div>
            </Toolbar>
            <h2 className="w-full text-white text-xl font-semibold">
              ¿Duermes las horas recomendadas por la noche?
            </h2>
            <SelectList
              onChange={handleOnChangeSleepRecomendedHours}
              selected={sleepRecomendedHours}
              options={sleepRecomendedHoursOptions}
            />
            <div className="my-3"></div>
            <h2 className="w-full text-white text-lg font-semibold">
              Si la respuesta es no, ¿cuántas horas sueles dormir?
            </h2>
            <Select
              placeholder={'Seleccionar'}
              onChange={handleOnChangeSleepHours}
              value={sleepHours}
              key="sleep_hours"
              name="sleep_hours"
              options={sleepHoursOptions}
            />
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick}>
                Siguiente
              </PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm22;
