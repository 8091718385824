import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import FooterMain from './FooterMain';

const RecetasTodas = () => {
  const [recetas, setRecetas] = useState([]);

  useEffect(() => {
    fetch('https://buddymarket.io/dummy/apibm/recipes/readlist.php')
      .then((response) => response.json())
      .then((data) => {
        setRecetas(data.data);
      })
      .catch((error) => console.error('Error fetching recipes:', error));
  }, []);

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Todas las recetas</h2>
      </div>
      <div className="rectangulo centrado busqueda">
        <form id="busqueda" action="buscar">
          <div className="input-container">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon2" />
            <input
              className="w100"
              type="text"
              name="user"
              placeholder="Buscar"
            />
            <br />
          </div>
        </form>
      </div>

      {recetas?.map((receta) => (
        <div key={receta.id} className="rectangulo round relative fondoblanco">
          <div className="flex wrap izquierda rgap20 vtop">
            <div className="w40">
              <a href={`receta/${receta.id}`}>
                <img
                  className="w100"
                  src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${receta.photo}`}
                />
              </a>
            </div>
            <div className="w60 pl20">
              <a href={`receta/${receta.id}`}>
                <h2 className="textooscuro cuerpotexto">{receta.name}</h2>
              </a>
              <a className="w70" href={`buddymaker/${receta.author}`}>
                <span className="textooscuro negrita">
                  por {receta.username}
                </span>
              </a>
            </div>
          </div>
        </div>
      ))}

      <br />
      <br />
      <FooterMain />
    </div>
  );
};

export default RecetasTodas;
