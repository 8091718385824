import React from 'react';

const PaymentInputField = ({
  label,
  value,
  onChange,
  placeHolder,
  type = 'number',
  ...props
}) => {
  return (
    <div className="flex flex-col gap-4">
      {label && <label>{label}</label>}
      <input
        className="rounded-md h-14 bg-black text-white ps-4 border border-new-border text-sm outline-none"
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeHolder}
        {...props}
      />
    </div>
  );
};

export default PaymentInputField;
