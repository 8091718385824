import React from 'react';
import { Link } from 'react-router-dom';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';

const UserHTile = ({ user, to = '' }) => {
  return (
    <>
      <div className="relative pointer-events-none">
        <Link
          to={to}
          className="relative flex space-x-3 items-center rounded-lg"
        >
          <div className="relative w-16 shrink-0">
            <div className="relative">
              <img
                src={user.image_url}
                alt=""
                className="h-16 w-16  rounded-full object-cover object-center"
              />
              <div className="absolute -right-1 -top-1">
                <CheckBadgeIcon className="size-7 text-primary" />
              </div>
            </div>
          </div>
          <div className="w-fulltext-center w-20 text-buddy-text-1 text-xs">
            {user.name}
          </div>
        </Link>
      </div>
    </>
  );
};

export default UserHTile;
