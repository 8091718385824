import React from 'react';
import { Link } from 'react-router-dom';
import FoodGenericImg from '../../img/food-generic.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const FoodCategoryTile = ({
  foodCategory,
  to = '',
  width = '100px',
  height = '100px',
}) => {
  return (
    <>
      <Link
        to={to}
        style={{ height, width }}
        className="relative flex-none mr-3 mb-4 rounded-lg overflow-hidden"
      >
        <div className="relative h-full">
          <div
            className="w-full overflow-hidden rounded-lg bg-black"
            style={{ height, width }}
          >
            <LazyLoadImage
              src={
                foodCategory.image_url ? foodCategory.image_url : FoodGenericImg
              }
              alt="Imagen de categoría"
              className="bg-black brightness-50 object-cover object-center"
              style={{ height, width }}
              effect="blur"
            />
          </div>
        </div>
        <div className="absolute bottom-3 w-full text-center text-buddy-text-1 text-xs font-semibold z-50">
          {foodCategory.name}
        </div>
        <div
          className="h-1/3 bg-gradient-to-t from-black to-transparent
         absolute bottom-0 left-0 right-0"
        />
      </Link>
    </>
  );
};

export default FoodCategoryTile;
