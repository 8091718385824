import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Select from './forms/Select';
import SelectList from './forms/SelectList';
import Textarea from './forms/Textarea';
import StressLevel from '../api/services/StressLevel';

const ProfileForm23 = () => {
  const navigate = useNavigate();

  const [stressLevelId, setStressLevelId] = useState('');
  const [stressLevelOptions, setStressLevelOptions] = useState([]);
  const [useMetabolismMedication, setUseMetabolismMedication] = useState('');

  const options = [
    {
      value: 1,
      label: 'Sí',
    },
    {
      value: 0,
      label: 'No',
    },
  ];

  const handleClick = (e) => {
    if (stressLevelId !== '' && useMetabolismMedication !== '') {
      var data = JSON.parse(localStorage.getItem('register_data'));
      data.stress_level_id = stressLevelId;
      data.use_metabolism_medication = useMetabolismMedication;
      data.metabolism_medication = document.querySelector(
        '[name="metabolism_medication"]',
      ).value;
      localStorage.setItem('register_data', JSON.stringify(data));
      navigate('/profileForm24');
    } else {
      alert('Responda a las preguntas no opcionales para continuar.');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadStressLevelOptions();
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setStressLevelId(data.stress_level_id ?? '');
      setUseMetabolismMedication(data.use_metabolism_medication ?? '');
      const el = document.querySelector('[name="metabolism_medication"]');
      if (el) {
        el.value = data.metabolism_medication ?? '';
      }
    }
  }, []);

  async function loadStressLevelOptions() {
    try {
      const response = await StressLevel.list();
      var options = [];
      response.data.map((level) => {
        options.push({
          value: level.id,
          label: level.name,
        });
      });
      setStressLevelOptions(options);
    } catch (error) {
      console.error(error);
    }
  }

  const handleOnChangeStressLevel = (e) => {
    setStressLevelId(e.target.value);
  };

  const handleOnChangeUseMetabolismMedication = (value) => {
    setUseMetabolismMedication(value);
  };

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={'/profileForm22'} replace={true}>
              <div className="text-sm">
                <span>Paso 23</span> <span className="text-primary">de 28</span>
              </div>
            </Toolbar>
            <h2 className="w-full text-white text-xl font-semibold">
              Nivel de estrés percibido
            </h2>
            <Select
              placeholder={'Seleccionar'}
              placeholderValue=""
              onChange={handleOnChangeStressLevel}
              value={stressLevelId}
              key="stress_level_id"
              name="stress_level_id"
              options={stressLevelOptions}
            />

            <h2 className="w-full text-white text-xl font-semibold">
              ¿Consumes regularmente algún medicamento que pueda afectar tu peso
              o metabolismo?
            </h2>
            <SelectList
              onChange={handleOnChangeUseMetabolismMedication}
              selected={useMetabolismMedication}
              options={options}
            />

            <div className="my-3"></div>
            <h2 className="w-full text-white text-lg font-semibold">
              Si la respuesta es sí, por favor lista los medicamentos
            </h2>
            <Textarea name="metabolism_medication" />

            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick}>
                Siguiente
              </PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm23;
