import React, { useRef } from 'react';

const Textarea = ({
  name,
  value = '',
  rows = 8,
  label = '',
  placeholder = '',
  helpText = '',
  onChange = null,
  disabled = false,
}) => {
  const inputReference = useRef(null);

  return (
    <div className="w-full">
      {label !== '' ? (
        <label className="text-white font-semibold text-md" htmlFor="email">
          {label}
        </label>
      ) : (
        ''
      )}
      <div className="w-full bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1 mt-2">
        <textarea
          disabled={disabled}
          defaultValue={value}
          onChange={onChange}
          ref={inputReference}
          autoComplete="off"
          className="appearance-none w-full h-full bg-transparent text-white p-2 placeholder:font-light rounded-xl body-small-muted"
          name={name}
          rows={rows}
          placeholder={placeholder}
        ></textarea>
      </div>
      <span className="text-body text-xs">{helpText}</span>
    </div>
  );
};

export default Textarea;
