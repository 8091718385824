import React, { useRef } from 'react';

const InputState = ({
  value,
  name,
  label = null,
  onChange,
  type = 'text',
  placeholder = '',
  helpText = '',
  readOnly = false,
}) => {
  const inputReference = useRef(null);

  const inputClasses = readOnly
    ? 'w-full h-12 rounded-xl bg-buddy-bg-3 relative shadow-inner focus:ring-transparent'
    : 'w-full h-12 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1 bg-black bg-opacity-40';

  return (
    <div className="w-full">
      {label != null ? (
        <p className="text-white font-semibold text-md mb-2" htmlFor="email">
          {label}
        </p>
      ) : (
        ''
      )}
      <div className={inputClasses}>
        <input
          readOnly={readOnly}
          disabled={readOnly}
          defaultValue={value}
          ref={inputReference}
          onChange={onChange}
          autoComplete="new-password"
          aria-autocomplete="none"
          list="autocompleteOff"
          className="appearance-none w-full h-full bg-transparent text-white ps-4 pe-16 placeholder:font-light rounded-xl"
          name={name}
          type={type}
          placeholder={placeholder}
        />
      </div>
      <span className="text-body text-xs">{helpText}</span>
    </div>
  );
};

export default InputState;
