import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Select from './forms/Select';
import SelectList from './forms/SelectList';
import Textarea from './forms/Textarea';

const ProfileForm26 = () => {
  const navigate = useNavigate();

  const [hasMedialDiet, setHasMedicalDiet] = useState('');

  const options = [
    {
      value: 1,
      label: 'Sí',
    },
    {
      value: 0,
      label: 'No',
    },
  ];

  const handleClick = (e) => {
    if (hasMedialDiet !== '') {
      var data = JSON.parse(localStorage.getItem('register_data'));
      data.has_medical_diet = hasMedialDiet;
      data.medical_diet = document.querySelector('[name="medical_diet"]').value;
      localStorage.setItem('register_data', JSON.stringify(data));
      navigate('/profileForm27');
    } else {
      alert('Responda a las preguntas no opcionales para continuar.');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setHasMedicalDiet(data.has_medical_diet ?? '');
      const el = document.querySelector('[name="medical_diet"]');
      if (el) {
        el.value = data.medical_diet ?? '';
      }
    }
  }, []);

  const handleOnChangeHasMedicalDiet = (value) => {
    setHasMedicalDiet(value);
  };

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={'/profileForm25'} replace={true}>
              <div className="text-sm">
                <span>Paso 26</span> <span className="text-primary">de 28</span>
              </div>
            </Toolbar>
            <h2 className="w-full text-white text-xl font-semibold">
              ¿Te encuentras actualmente bajo alguna dieta especifica por
              recomendación médica?
            </h2>
            <SelectList
              onChange={handleOnChangeHasMedicalDiet}
              selected={hasMedialDiet}
              options={options}
            />

            <div className="my-3"></div>
            <h2 className="w-full text-white text-lg font-semibold">
              Si la respuesta es sí, por favor especifica:
            </h2>
            <Textarea name="medical_diet" />
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick}>
                Siguiente
              </PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm26;
