import client from "../client";

export default {
  list: (menuOrganizerDayPart) =>
    client.get(`menu_organizer_day_parts/${menuOrganizerDayPart}/complements`).json(),

  create: (menuOrganizerDayPart, complementText) => {
    const payload = { 
      menu_organizer_day_part_id: menuOrganizerDayPart, 
      complement_text: complementText 
    };
    
    return client.post(`menu_organizer_day_parts/${menuOrganizerDayPart}/complements`, {
      json: payload, 
    }).json();
  },

  delete: (menuOrganizerDayPart, complement) => 
    client.delete(`menu_organizer_day_parts/${menuOrganizerDayPart}/complements/${complement}`)
};




