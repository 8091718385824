import React, { useState, useEffect } from 'react';
import Toolbar from './ui/Toolbar';
import { useNavigate } from 'react-router-dom';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';

const HealthPreferencesRestTime = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    has_medical_conditions: 0,
    medical_conditions: null,
    has_medical_diet: 0,
    medical_diet: null,
  });

  const handleClick = (e) => {
    const user = JSON.parse(localStorage.getItem('user'));
    user.has_medical_conditions = data.has_medical_conditions;
    user.medical_conditions = data.medical_conditions;
    user.has_medical_diet = data.has_medical_diet;
    user.medical_diet = data.medical_diet;
    localStorage.setItem('user', JSON.stringify(user));
    navigate('/healthPreferencesRestTime');
  };

  const loadUserData = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setData({
        has_medical_conditions: user.has_medical_conditions ?? 0,
        medical_conditions: user.medical_conditions ?? null,
        has_medical_diet: user.has_medical_diet ?? 0,
        medical_diet: user.medical_diet ?? null,
      });
    }
  };

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="flex flex-col justify-between min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col bg-red gap-4 mb-6">
          <Toolbar to={'/healthPreferencesPersonalData'}>
            <span>Paso 3</span> <span className="text-primary">de 7</span>
          </Toolbar>
          <div className="flex flex-col bg-red items-center gap-6 mb-8">
            <h2 className="w-full text-white text-xl text-center font-semibold">
              ¿Sufres alguna condición médica que afecte a tu alimentación o
              actividad física?
            </h2>
            <div className="flex justify-between items-center w-2/3 gap-8">
              <button
                type="button"
                onClick={() => setData({ ...data, has_medical_conditions: 1 })}
                className={`w-full text-center px-4 text-md rounded-xl font-semibold
                            w-[80px] h-[59px] text-xl
                            ${
                              data.has_medical_conditions === 1
                                ? 'bg-primary'
                                : 'bg-white'
                            }
                            ${
                              data.has_medical_conditions === 1
                                ? 'text-white'
                                : 'text-back'
                            }
                        `}
              >
                Sí
              </button>
              <button
                type="button"
                onClick={() => setData({ ...data, has_medical_conditions: 0 })}
                className={`w-full text-center px-4 text-md rounded-xl font-semibold
                            w-[80px] h-[59px] text-xl
                            ${
                              data.has_medical_conditions === 0
                                ? 'bg-primary'
                                : 'bg-white'
                            }
                            ${
                              data.has_medical_conditions === 0
                                ? 'text-white'
                                : 'text-back'
                            }
                        `}
              >
                No
              </button>
            </div>
            <div className="flex flex-col gap-1 mb-3">
              <h3 className="text-md text-white text-center mb-1">
                Si la respuesta es “sí”, por favor especifica:
              </h3>
              <div className="w-full shrink-0 grow h-[44px] bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-transparent ring-1">
                <input
                  onChange={(e) =>
                    setData({ ...data, medical_conditions: e.target.value })
                  }
                  value={data.medical_conditions}
                  type="text"
                  aria-autocomplete="none"
                  list="autocompleteOff"
                  className="appearance-none border-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl"
                  name="recipe_step"
                  placeholder=""
                />
              </div>
            </div>

            <h2 className="w-full text-white text-xl text-center font-semibold">
              ¿Te encuentras actualmente bajo alguna dieta específica por
              recomendación médica?
            </h2>
            <div className="flex justify-between items-center w-2/3 gap-8">
              <button
                type="button"
                onClick={() => setData({ ...data, has_medical_diet: 1 })}
                className={`w-full text-center px-4 text-md rounded-xl font-semibold
                            w-[80px] h-[59px] text-xl
                            ${
                              data.has_medical_diet === 1
                                ? 'bg-primary'
                                : 'bg-white'
                            }
                            ${
                              data.has_medical_diet === 1
                                ? 'text-white'
                                : 'text-back'
                            }
                        `}
              >
                Sí
              </button>
              <button
                type="button"
                onClick={() => setData({ ...data, has_medical_diet: 0 })}
                className={`w-full text-center px-4 text-md rounded-xl font-semibold
                            w-[80px] h-[59px] text-xl
                            ${
                              data.has_medical_diet === 0
                                ? 'bg-primary'
                                : 'bg-white'
                            }
                            ${
                              data.has_medical_diet === 0
                                ? 'text-white'
                                : 'text-back'
                            }
                        `}
              >
                No
              </button>
            </div>
            <div className="flex flex-col gap-1">
              <h3 className="text-md text-white text-center mb-1">
                Si la respuesta es “sí”, por favor especifica:
              </h3>
              <div className="w-full shrink-0 grow h-[44px] bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-transparent ring-1">
                <input
                  onChange={(e) =>
                    setData({ ...data, medical_diet: e.target.value })
                  }
                  value={data.medical_diet}
                  type="text"
                  aria-autocomplete="none"
                  list="autocompleteOff"
                  className="appearance-none border-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl"
                  name="recipe_step"
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </div>
        <FooterToolbar>
          <PrimaryButton type="button" onClick={handleClick}>
            Siguiente
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default HealthPreferencesRestTime;
