import React from 'react';
import { useEffect } from 'react';
import Recipe from '../api/services/Recipe.js';
import { useSearchParams } from 'react-router-dom';
import Toolbar from './ui/Toolbar.js';
import { Input } from 'postcss';

const RecipeSteps = () => {
  const [searchParams] = useSearchParams();

  const id = searchParams.get('id') ?? '';

  useEffect(() => {
    const getRecipe = async (id) => {
      try {
        await Recipe.get(id);
      } catch (error) {
        console.error('Error fetching author:', error);
      }
    };

    getRecipe(id);
  }, [id]);

  return (
    <div className="h-full bg-no-repeat bg-cover bg-center">
      <div className="h-full bg-secondary-bg bg-opacity-60 p-6">
        <Toolbar to={-1}>
          <div className="text-md">
            <span>Editar receta: pasos</span>
          </div>
        </Toolbar>

        <div className="flex flex-col py-4 space-y-3">
          <Input name="name" label={'Nombre de la receta'} />
        </div>
      </div>
    </div>
  );
};

export default RecipeSteps;
