import React from 'react';
import { Link } from 'react-router-dom';
import { ClockIcon } from '@heroicons/react/24/outline';
import FoodGenericImg from '../../img/food-generic.png';
import Badge from './Badge';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const RecipeTile = ({
  recipe,
  to = null,
  showStatus = false,
  showAuthor = true,
}) => {
  return (
    <Link to={to} className="relative w-full grid grid-cols-3 gap-4">
      <div className="relative">
        <div className="w-full rounded-lg">
          <LazyLoadImage
            src={
              recipe.image_url
                ? `${recipe.image_url}/?r=${Date.now()}`
                : FoodGenericImg
            }
            alt=""
            className="h-28 w-28 object-cover object-center rounded-lg"
            effect="blur"
          />
        </div>
      </div>
      <div className="bottom-3 col-span-2 text-buddy-text-1 ">
        <h2 className="text-md font-semibold">{recipe.name}</h2>
        {recipe.user && showAuthor ? (
          <h3 className="text-sm">Autor: {recipe.user.name}</h3>
        ) : (
          ''
        )}
        <div className="flex text-xs space-x-1 mt-1">
          <ClockIcon className="size-4" />{' '}
          <span>Preparación: {recipe.preparation_time} min</span>
        </div>
        <div className="flex text-xs space-x-1 mt-1">
          <ClockIcon className="size-4" />{' '}
          <span>Cocción: {recipe.cook_time} min</span>
        </div>
      </div>
      <div className="absolute bottom-0 right-0">
        {showStatus ? (
          recipe.active ? (
            <Badge type="primary">Pública</Badge>
          ) : (
            <Badge>Borrador</Badge>
          )
        ) : (
          ''
        )}
      </div>
    </Link>
  );
};

export default RecipeTile;
