import React, { useState, useEffect } from 'react';
import Toolbar from './ui/Toolbar';
import { useNavigate } from 'react-router-dom';
import HealthPreference from '../api/services/HealthPreference';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';

const HealthPreferencesShopping = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    purchase_decision_factors: '',
    food_waste_per_week: '',
    importance_of_nutritional_info: '',
    preference_fresh_or_processed: '',
  });

  function flattenObject(obj) {
    const result = {};
    for (const category in obj) {
      const categoryData = obj[category];
      for (const key in categoryData) {
        result[key] = categoryData[key];
      }
    }
    return result;
  }

  const handleClick = async (e) => {
    const userParsed = JSON.parse(localStorage.getItem('user'));
    userParsed.purchase_decision_factors = data.purchase_decision_factors;
    userParsed.food_waste_per_week = data.food_waste_per_week;
    userParsed.importance_of_nutritional_info =
      data.importance_of_nutritional_info;
    userParsed.preference_fresh_or_processed =
      data.preference_fresh_or_processed;

    localStorage.setItem('user', JSON.stringify(userParsed));
    const user = JSON.parse(localStorage.getItem('user'));
    const formData = new FormData();

    console.log(user.sleep_hours, typeof user.sleep_hours);

    formData.append('purchase_frequency', user.purchase_frequency);
    formData.append('purchase_location', user.purchase_location);
    formData.append('average_shopping_time', user.average_shopping_time);
    formData.append(
      'preference_fresh_or_processed',
      user.preference_fresh_or_processed,
    );
    formData.append(
      'purchase_decision_factors',
      user.purchase_decision_factors,
    );
    formData.append('food_waste_per_week', user.food_waste_per_week);
    formData.append(
      'importance_of_nutritional_info',
      user.importance_of_nutritional_info,
    );
    formData.append('cook_in_large_quantities', user.cook_in_large_quantities);
    formData.append(
      'application_use_main_goals_id',
      user.application_use_main_goals_id,
    );
    formData.append(
      'monthly_spending_range_id',
      user.monthly_spending_range_id,
    );
    formData.append(
      'practice_sports_or_physical_activity',
      user.practice_sports_or_physical_activity,
    );
    formData.append('daily_meals', user.daily_meals);
    formData.append('cooking_time_range_id', user.cooking_time_range_id);
    formData.append('weight', user.weight);
    formData.append('height', user.height);
    formData.append('has_medical_conditions', user.has_medical_conditions);
    formData.append('medical_conditions', user.medical_conditions);
    formData.append('medical_diet', user.medical_diet);
    formData.append('has_medical_diet', user.has_medical_diet);
    if (user.sleep_hours) {
      formData.append('sleep_hours', user.sleep_hours);
    }
    formData.append('sleep_recomended_hours', user.sleep_recomended_hours);
    formData.append('nutritional_supplements', user.nutritional_supplements);
    formData.append('hydration_level_id', user.hydration_level_id);
    formData.append(
      'meal_planning_frequency_id',
      user.meal_planning_frequency_id,
    );
    formData.append(
      'use_nutritional_supplements',
      user.use_nutritional_supplements,
    );

    console.log(formData);
    await HealthPreference.create(formData, user.id);
    navigate('/dietaryPreferences');
  };

  const loadUserAllergies = async () => {
    // TODO: Load user allergies
    let dietaryPreferences = localStorage.getItem('healthPreferences');

    if (!dietaryPreferences) {
      dietaryPreferences = localStorage.setItem(
        'healthPreferences',
        JSON.stringify({}),
      );
      return;
    }
    const parsedDietaryPreferences = JSON.parse(dietaryPreferences);
  };

  const loadUserData = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setData({
        purchase_decision_factors: user.purchase_decision_factors ?? '',
        food_waste_per_week: user.food_waste_per_week ?? '',
        importance_of_nutritional_info:
          user.importance_of_nutritional_info ?? '',
        preference_fresh_or_processed: user.preference_fresh_or_processed ?? '',
      });
    }
  };

  useEffect(() => {
    // TODO: Load user allergies
    loadUserAllergies();
    loadUserData();
  }, []);

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="flex flex-col justify-between min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col bg-red  gap-3 mb-6">
          <Toolbar to={'/healthPreferencesShopping'}>
            <span>Paso 7</span> <span className="text-primary">de 7</span>
          </Toolbar>
          <div className="flex flex-col bg-red items-center gap-6 mb-8">
            <h2 className="w-full text-white text-xl text-center font-semibold">
              ¿Qué factores influyen más en tu decisión de compra? (precio,
              calidad, marca, origen, etc.)
            </h2>
            <div className="w-full shrink-0 grow h-[44px] bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-transparent ring-1 mb-8">
              <input
                onChange={(e) =>
                  setData({
                    ...data,
                    purchase_decision_factors: e.target.value,
                  })
                }
                value={data.purchase_decision_factors}
                aria-autocomplete="none"
                list="autocompleteOff"
                className="appearance-none border-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl mb-2"
                name="recipe_step"
                type="text"
                placeholder="Factores"
              />
              <span className="text-buddy-text-7 text-xs ml-3">Ej: Precio</span>
            </div>
            <h2 className="w-full text-white text-xl text-center font-semibold">
              ¿Cuánta comida sueles desperdiciar en una semana?
            </h2>
            <div className="w-full shrink-0 grow h-[44px] bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-transparent ring-1 mb-8">
              <input
                onChange={(e) =>
                  setData({ ...data, food_waste_per_week: e.target.value })
                }
                value={data.food_waste_per_week}
                aria-autocomplete="none"
                list="autocompleteOff"
                className="appearance-none border-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl mb-2"
                name="recipe_step"
                type="text"
                placeholder="Comida desperdiciada"
              />
              <span className="text-buddy-text-7 text-xs ml-3">
                Ej: Muy poca
              </span>
            </div>
            <h2 className="w-full text-white text-xl text-center font-semibold">
              ¿Qué importancia le das a la información nutricional en los
              productos que compras?
            </h2>
            <div className="w-full shrink-0 grow h-[44px] bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-transparent ring-1 mb-8">
              <input
                onChange={(e) =>
                  setData({
                    ...data,
                    importance_of_nutritional_info: e.target.value,
                  })
                }
                value={data.importance_of_nutritional_info}
                aria-autocomplete="none"
                list="autocompleteOff"
                className="appearance-none border-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl mb-2"
                name="recipe_step"
                type="text"
                placeholder="Importancia"
              />
              <span className="text-buddy-text-7 text-xs ml-3">Ej: Poca</span>
            </div>
            <h2 className="w-full text-white text-xl text-center font-semibold">
              ¿Prefieres comprar productos frescos o procesados?
            </h2>
            <div className="w-full shrink-0 grow h-[44px] bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-transparent ring-1 mb-8">
              <input
                onChange={(e) =>
                  setData({
                    ...data,
                    preference_fresh_or_processed: e.target.value,
                  })
                }
                value={data.preference_fresh_or_processed}
                aria-autocomplete="none"
                list="autocompleteOff"
                className="appearance-none border-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl mb-2"
                name="recipe_step"
                type="text"
                placeholder="Cantidad"
              />
              <span className="text-buddy-text-7 text-xs ml-3">
                Ej: Productos frescos
              </span>
            </div>
          </div>
        </div>
        <FooterToolbar>
          <PrimaryButton type="button" onClick={handleClick}>
            Guardar
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default HealthPreferencesShopping;
