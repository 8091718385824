import React, { useState, useEffect } from 'react';
import Toolbar from './ui/Toolbar';
import { useNavigate } from 'react-router-dom';
import CheckBoxSelect from './forms/CheckBoxSelect';
import FooterToolbar from './ui/FooterToolbar';
import Allergy from '../api/services/Allergy';
import User from '../api/services/User';
import PrimaryButton from './ui/PrimaryButton';

const DietaryPreferencesAllergies = () => {
  const navigate = useNavigate();

  const [allergiesIds, setAllergiesIds] = useState([]);
  const [allergiesOptions, setAllergiesOptions] = useState([]);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (e) => {
    try {
      setIsSaveLoading(true);
      const user = JSON.parse(localStorage.getItem('user'));
      await User.updateAllergies(user.id, { allergies_ids: allergiesIds });
      setIsSaveLoading(false);
    } catch (error) {
      setIsSaveLoading(false);
      console.log(error);
    }
    navigate('/dietaryPreferencesRestrictions');
  };

  const onSelectionChange = (selection) => {
    setAllergiesIds(selection);
  };

  const loadAllergies = async () => {
    try {
      setIsLoading(true);
      const response = await Allergy.list();
      var options = [];
      response.data.forEach((allergy) => {
        options.push({
          value: allergy.id,
          label: allergy.name,
          param: allergy.api_param,
        });
      });
      setAllergiesOptions(options);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const loadUserAllergies = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const response = await User.getAllergies(user.id);
      const allergiesIds = response.data.map((allergy) => allergy.id);
      setAllergiesIds(allergiesIds);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadAllergies();
    loadUserAllergies();
  }, []);

  return isLoading ? (
    <div className="flex justify-center items-center h-screen">
      <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
    </div>
  ) : (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="flex flex-col justify-between min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col bg-red items-center gap-3 mb-6">
          <Toolbar to={'/sidebarMenu'}>
            <span>Paso 1</span> <span className="text-primary">de 2</span>
          </Toolbar>
          <div className="flex flex-col bg-red items-center gap-6">
            <h2 className="w-full text-white text-2xl text-center font-semibold">
              Alergias alimentarias
            </h2>
            <h3 className="text-body text-center text-sm">
              Evita reacciones comunes a alergias o intolerancias
            </h3>
            <CheckBoxSelect
              name="allergies_ids"
              onChange={onSelectionChange}
              selection={allergiesIds}
              options={allergiesOptions}
            />
          </div>
        </div>
        <div className="flex flex-col mb-8">
          <p className="text-[10px] font-medium text-buddy-text-7 text-center">
            Los datos que proporcionas nos permiten optimizar tu experiencia en
            la app mediante inteligencia artificial, ofreciéndote
            recomendaciones personalizadas y contenidos adaptados a tus
            necesidades.
          </p>
          <FooterToolbar>
            <PrimaryButton type="button" onClick={handleClick}>
              {isSaveLoading ? (
                <div className="w-full flex justify-center items-center">
                  <div className="w-6 h-6 text-center rounded-full border-2 border-t-white border-r-white border-b-gray-500 border-l-gray-500 animate-spin"></div>
                </div>
              ) : (
                'Siguiente'
              )}
            </PrimaryButton>
          </FooterToolbar>
        </div>
      </div>
    </div>
  );
};

export default DietaryPreferencesAllergies;
