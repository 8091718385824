import React from 'react';

const SecondaryOutLineButton = ({ size = 'md', children, ...attributes }) => {
  return (
    <button
      className="flex items-center bg-buddy-bg-4 bg-opacity-20 py-2 px-3 rounded-lg text-sm text-buddy-text-1"
      {...attributes}
    >
      {children}
    </button>
  );
};
export default SecondaryOutLineButton;
