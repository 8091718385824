import React, { useState } from 'react';
import Toolbar from './ui/Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/fontawesome-free-regular';
import spainIcon from '../icons/spain.png';
import ukIcon from '../icons/uk.png';

const UserLanguageSelect = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(1);

  const languages = [
    { value: 1, label: 'Español', icon: spainIcon },
    { value: 2, label: 'Inglés', icon: ukIcon },
  ];

  const handleLanguageSelect = (value) => {
    setSelectedLanguage(value);
  };

  return (
    <div className="relative h-full bg-no-repeat bg-cover bg-center overflow-scroll">
      <div className="h-full flex justify-between flex-col min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <Toolbar to={'/sidebarMenu'}>Idioma</Toolbar>
        <form className="h-full w-full mt-10">
          <div className="flex justify-between gap-1">
            {languages.map((language) => (
              <div
                key={language.value}
                onClick={() => handleLanguageSelect(language.value)}
                className="w-full flex justify-evenly bg-buddy-bg-2 py-2 px-3 rounded-2xl"
              >
                {selectedLanguage === language.value ? (
                  <FontAwesomeIcon
                    className="text-primary mt-auto mb-auto"
                    icon={faCircleCheck}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="text-white mt-auto mb-auto"
                    icon={faCircle}
                  />
                )}
                <div className="w-full flex justify-end gap-3">
                  <span className="text-buddy-text-1 mt-auto mb-auto font-semibold">
                    {language.label}
                  </span>
                  <span className="pointer-events-none">
                    <img src={language.icon} alt="" />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserLanguageSelect;
