import React from 'react';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

const ConditionsCheckbox = ({ name, value, onChange }) => {
  const openExternalUrl = async (url) => {
    if (Capacitor.isNativePlatform()) {
      await Browser.open({
        url,
        presentationStyle: 'popover',
      });
    } else {
      window.open(url, '_blank');
    }
  };

  const handleTermsClick = (e) => {
    e.preventDefault();
    openExternalUrl('https://www.buddymarket.io/tc-b2c/');
  };

  const handlePrivacyClick = (e) => {
    e.preventDefault();
    openExternalUrl('https://www.buddymarket.io/politica-de-privacidad/');
  };

  return (
    <>
      <div className="flex gap-2 text-white">
        <input
          name={name}
          value={value}
          checked={value}
          onChange={onChange}
          type="checkbox"
          className="shrink-0 rounded-sm border-2 w-5 h-5 mt-1 checked:accent-primary focus:ring-0 focus:shadow "
        />
        <div className="">
          <label className="block text-sm font-semibold">
            He leído y acepto los{' '}
            <a className="text-primary" href="#" onClick={handleTermsClick}>
              Términos y Condiciones
            </a>{' '}
            y la{' '}
            <a className="text-primary" href="#" onClick={handlePrivacyClick}>
              Política de Privacidad
            </a>{' '}
            de BuddyMarket. *
          </label>
        </div>
      </div>
    </>
  );
};

export default ConditionsCheckbox;
