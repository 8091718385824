import { useEffect } from 'react';

const PaymentBridge = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('session_id');
    const userId = params.get('user_id');

    const processPayment = `http://localhost:3000/payment-processing?session_id=${sessionId}&user_id=${userId}`;
    const redirectToApp = async () => {
      window.location.href = processPayment;
    };

    redirectToApp();
  }, []);

  return <div>Redirigiendo a la app...</div>;
};

export default PaymentBridge;
