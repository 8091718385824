import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import PrimaryButton from './ui/PrimaryButton';
import PasswordWithValidationInput from './forms/PasswordWithValidationInput';
import Input from './forms/Input';
import ConditionsCheckbox from './forms/ConditionsCheckbox';
import NewsletterCheckbox from './forms/NewsletterCheckbox';
import FooterToolbar from './ui/FooterToolbar';

const SignUp = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    password_repeat: '',
    accept_terms: false,
    accept_communications: false,
    gender_id: null,
    age: null,
  });
  const [isDisabled, setIsDisabled] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'age' || name === 'gender_id') {
      setData((prevData) => ({
        ...prevData,
        [name]: value ? parseInt(value, 10) : null,
      }));
    } else {
      setData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleClick = () => {
    if (isDisabled) return;
    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/profileForm1');
  };

  useEffect(() => {
    const isBasicDataEmpty = () =>
      !data.name ||
      !data.email ||
      !data.password ||
      !data.password_repeat ||
      !data.accept_terms;
    const arePasswordsNotEqual = () =>
      data.password !== data.password_repeat ||
      !data.password ||
      !data.password_repeat;

    setIsDisabled(isBasicDataEmpty() || arePasswordsNotEqual());
  }, [data]);

  return (
    <div className="relative h-full bg-no-repeat bg-cover bg-center overflow-scroll">
      <div className="h-full bg-secondary-bg bg-opacity-60 p-6">
        <form className="w-full">
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to="/login">Registro</Toolbar>
            <Input
              name="name"
              label="Nombre completo *"
              placeholder=""
              value={data.name}
              onChange={handleChange}
            />
            <Input
              name="email"
              type="email"
              label="Correo electrónico *"
              value={data.email}
              onChange={handleChange}
              placeholder=""
            />
            <PasswordWithValidationInput
              name="password"
              label="Contraseña *"
              placeholder=""
              value={data.password}
              onChange={(value) =>
                setData((prevData) => ({ ...prevData, password: value }))
              }
              withValidation={true}
            ></PasswordWithValidationInput>
            <div className="w-full -mt-4">
              <Input
                name="password_repeat"
                type="password"
                label="Repetir contraseña *"
                value={data.password_repeat}
                onChange={handleChange}
                placeholder=""
              ></Input>
            </div>
            {false && (
              <Input
                name="phone"
                type="phone"
                label="Número de teléfono (opcional)"
                placeholder=""
                value={data.phone}
                onChange={handleChange}
                helpText="Ej: +34 684 93 49 34"
              ></Input>
            )}
            <h2 className="w-full text-left text-white text-sm font-medium">
              Género{data.gender_id}
            </h2>
            <div className="flex gap-3 w-full mb-2">
              <div className="flex gap-2 text-white">
                <input
                  type="radio"
                  name="gender_id"
                  value="1"
                  checked={data.gender_id === 1}
                  onChange={handleChange}
                  className="shrink-0 border-2 w-5 h-5 mt-1 checked:accent-primary focus:ring-0 focus:shadow"
                />
                <label className="block text-[12px] mt-auto">Masculino</label>
              </div>
              <div className="flex gap-2 text-white">
                <input
                  type="radio"
                  name="gender_id"
                  value="2"
                  checked={data.gender_id === 2}
                  onChange={handleChange}
                  className="shrink-0 border-2 w-5 h-5 mt-1 checked:accent-primary focus:ring-0 focus:shadow"
                />
                <label className="block text-[12px] mt-auto">Femenino</label>
              </div>
              <div className="flex gap-2 text-white">
                <input
                  type="radio"
                  name="gender_id"
                  value="3"
                  checked={data.gender_id === 3}
                  onChange={handleChange}
                  className="shrink-0 border-2 w-5 h-5 mt-1 checked:accent-primary focus:ring-0 focus:shadow"
                />
                <label className="block text-[12px] mt-auto">
                  Prefiero no decirlo
                </label>
              </div>
            </div>
            <div className="flex w-full flex-col gap-1">
              <Input
                name="age"
                labelSize="small"
                type="number"
                placeholder=""
                label={'Edad'}
                value={data.age}
                onChange={handleChange}
              />
              <span className="text-white text-[10px] ml-3">Ej: 31 </span>
            </div>
            <ConditionsCheckbox
              name="accept_terms"
              value={data.accept_terms}
              onChange={(e) =>
                setData((prevData) => ({
                  ...prevData,
                  accept_terms: e.target.checked,
                }))
              }
            />
            <NewsletterCheckbox
              name="accept_communications"
              value={data.accept_communications}
              onChange={(e) =>
                setData((prevData) => ({
                  ...prevData,
                  accept_communications: e.target.checked,
                }))
              }
            />
          </div>
        </form>
        <FooterToolbar>
          <PrimaryButton
            type="button"
            onClick={handleClick}
            disabled={isDisabled || !(data.age && data.age > 0)}
          >
            Siguiente
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default SignUp;
