import React, { useState, useRef } from 'react';

const MeasureInput = ({
  name,
  value,
  label = null,
  onChange = null,
  type = 'text',
  placeholder = '',
  helpText = '',
}) => {
  const [inputValue, setInputValue] = useState(value);
  const inputReference = useRef(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <>
      <div className="w-full">
        {label != null ? (
          <p className="text-white font-semibold text-md mb-2" htmlFor="email">
            {label}
          </p>
        ) : (
          ''
        )}
        <div className="w-full h-20 bg-buddy-bg-1 bg-opacity-40 text-center rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
          <input
            className="appearance-none w-full h-full bg-transparent text-center text-3xl font-bold text-buddy-text-2 px-2 placeholder:font-light rounded-xl"
            value={value}
            onChange={handleInputChange}
            ref={inputReference}
            autoComplete="new-password"
            aria-autocomplete="none"
            list="autocompleteOff"
            name={name}
            type={type}
            placeholder={placeholder}
          />
        </div>
        <span className="text-body text-xs">{helpText}</span>
      </div>
    </>
  );
};

export default MeasureInput;
