import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import RecipeIngredient from '../api/services/RecipeIngredient.js';
import Measure from '../api/services/Measure.js';
import Ingredient from '../api/services/Ingredient.js';
import Toolbar from './ui/Toolbar.js';
import FooterToolbar from './ui/FooterToolbar.js';
import PrimaryButton from './ui/PrimaryButton.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Select from './forms/Select.js';
import InputState from './forms/InputState.js';

const RecipeIngredientCreate = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get('recipe_id') ?? '';
  const [measureId, setMeasureId] = useState();
  const [measureOptions, setMeasureOptions] = useState([]);
  const [search, setSearch] = useState('');

  const [ingredientId, setIngredientId] = useState(null);
  const [ingredientName, setIngredientName] = useState(null);

  const [ingredients, setIngredients] = useState([]);
  const [searchEnabled, setSearchEnabled] = useState(true);

  useEffect(() => {
    getMeasures();
  }, []);

  const getMeasures = async () => {
    try {
      const response = await Measure.list();
      var options = [];
      response.data.map((type) => {
        options.push({
          value: type.id,
          label: type.name,
        });
      });
      setMeasureOptions(options);
      setMeasureId(options[0].value);
    } catch (error) {
      console.error('Error fetching FoodCategory:', error);
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await RecipeIngredient.create(recipeId, {
        ingredient_id: ingredientId,
        quantity: document.querySelector('[name="quantity"]').value,
        measure_id: measureId,
      });
      navigate(-1);
    } catch (error) {
      alert(
        error.response.payload.message ??
          'Error inesperado. Inténtalo de nuevo.',
      );
      console.error('Error creating ingredient:', error);
    }
  };

  const getIngredients = async (search) => {
    try {
      const response = await Ingredient.list({
        search: search,
        limit: 10,
      });
      setIngredients(response.data);
    } catch (error) {
      console.error('Error fetching FoodCategory:', error);
    }
  };

  const handleOnChangeSearch = (e) => {
    const search = e.target.value;
    if (search.length < 3) {
      setIngredients([]);
    } else {
      getIngredients(e.target.value);
    }
    setSearch(search);
  };

  const handleOnBlurSearch = (e) => {};

  const handleOnFocusSearch = (e) => {
    if (searchEnabled) {
      const search = e.target.value;
      if (search.length < 3) {
        setIngredients([]);
      } else {
        getIngredients(e.target.value);
      }
    }
  };

  const handleOnChangeMeasureId = (e) => {
    setMeasureId(e.target.value);
  };

  const handleResetIngredient = () => {
    setSearch('');
    document.querySelector('[name="search"]').value = '';
    setIngredients([]);
    setIngredientId(null);
    setIngredientName(null);
    setSearchEnabled(true);
  };

  const handleOnIngredientSelected = (id, name) => {
    setIngredientId(id);
    setIngredientName(name);
    document.querySelector('[name="search"]').value = name;
    setSearch(name);
    setIngredients([]);
    setSearchEnabled(false);
  };

  return (
    <div className="h-full bg-no-repeat bg-cover bg-center">
      <div className="h-full bg-secondary-bg bg-opacity-60 p-6">
        <Toolbar to={-1} replace={true}>
          <div className="text-md">
            <span>Añadir ingrediente</span>
          </div>
        </Toolbar>
        <div className="flex flex-col py-2 space-y-3">
          <div className="w-full relative">
            <p
              className="text-white font-semibold text-md mb-2"
              htmlFor="email"
            >
              Ingrediente
            </p>
            <div className="w-full h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
              {search.length > 0 ? (
                <div className="absolute right-0 bottom-0 top-0">
                  <button
                    type="button"
                    onClick={handleResetIngredient}
                    className="flex items-center justify-center w-8 me-2 h-full"
                  >
                    <div className="w-8 h-8 rounded-full bg-form-button-bg flex flex-col justify-center items-center">
                      <FontAwesomeIcon className="text-white" icon={faTimes} />
                    </div>
                  </button>
                </div>
              ) : (
                ''
              )}
              <input
                readOnly={!searchEnabled}
                onBlur={handleOnBlurSearch}
                onFocus={handleOnFocusSearch}
                onChange={handleOnChangeSearch}
                autoComplete="new-password"
                aria-autocomplete="none"
                list="autocompleteOff"
                className="appearance-none w-full h-full bg-transparent text-white ps-4 pe-16 placeholder:font-light rounded-xl"
                name="search"
                type="text"
              />
            </div>

            <div
              id="ingredientsList"
              className="w-full z-10 absolute flex flex-col bg-opacity-90 bg-buddy-bg-5 rounded-lg overflow-hidden"
            >
              {ingredients.map((ingredient) => {
                return (
                  <button
                    key={ingredient.id}
                    onClick={(e) => {
                      handleOnIngredientSelected(
                        ingredient.id,
                        ingredient.name,
                      );
                    }}
                    className="w-full text-start text-buddy-text-2 py-2 px-3 "
                  >
                    {ingredient.name}
                  </button>
                );
              })}
            </div>
          </div>

          <Select
            selected={measureId}
            name="measure_id"
            label="Medida"
            onChange={handleOnChangeMeasureId}
            options={measureOptions}
          />

          <InputState name="quantity" label={'Cantidad'} type="number" />
        </div>

        <FooterToolbar>
          <PrimaryButton type="button" onClick={handleUpdate}>
            Guardar
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default RecipeIngredientCreate;
