import React, { useRef, useCallback } from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Recipe from '../api/services/Recipe.js';
import RecipeTile from './ui/RecipeTile.js';
import Card from './ui/Card.js';
import { useSearchParams } from 'react-router-dom';
import User from '../api/services/User.js';
import FoodCategory from '../api/services/FoodCategory.js';

const LIMIT = 7;

const RecipesSearch = () => {
  const [searchParams] = useSearchParams();
  const observerRef = useRef(null);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [recipes, setRecipes] = useState([]);
  const [search, setSearch] = useState('');
  const [noResults, setNoResults] = useState(false);

  const userId = searchParams.get('user_id') ?? '';
  const foodCategoryId = searchParams.get('food_category_id') ?? '';

  const [author, setAuthor] = useState(null);
  const [foodCategory, setFoodCategory] = useState(null);

  useEffect(() => {
    if (userId !== '') {
      getUser(userId);
    }
    if (foodCategoryId !== '') {
      getFoodCategory(foodCategoryId);
    }
    setSearch(searchParams.get('search') ?? '');
  }, [foodCategoryId, searchParams, userId]);

  const getUser = async (userId) => {
    try {
      const response = await User.get(userId);
      setAuthor(response.data);
    } catch (error) {
      console.error('Error fetching author:', error);
    }
  };

  const getFoodCategory = async (foodCategoryId) => {
    try {
      const response = await FoodCategory.get(foodCategoryId);
      setFoodCategory(response.data);
    } catch (error) {
      console.error('Error fetching FoodCategory:', error);
    }
  };

  const getRecipes = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await Recipe.list({
        search,
        user_id: userId,
        food_category_id: foodCategoryId,
        limit: LIMIT,
        offset,
      });

      if (response.data.length === 0 && offset === 0) {
        setNoResults(true);
      }

      setRecipes((prev) => [...prev, ...response.data]);
      setOffset((prev) => prev + response.data.length);
      setHasMore(response.data.length === LIMIT);
    } catch (error) {
      console.error('Error fetching recipes:', error);
    }
    setLoading(false);
  }, [search, userId, foodCategoryId, offset, loading, hasMore]);

  useEffect(() => {
    getRecipes();
  }, [getRecipes]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          getRecipes();
        }
      },
      { threshold: 1.0 },
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => observer.disconnect();
  }, [getRecipes]);

  const handleResetSearch = (e) => {
    document.querySelector('[name="search"]').value = '';
    setSearch('');
    setNoResults(false);
  };

  return (
    <div className="h-full bg-no-repeat bg-cover bg-center">
      <div className="h-full bg-secondary-bg bg-opacity-60 p-6">
        <div className="w-full h-12 flex justify-between space-x-4 text-title items-center text-lg mb-6">
          <div>
            <div className="w-10 h-10 rounded-full bg-white bg-opacity-20 flex flex-col justify-center items-center">
              <Link to={-1} className="text-title">
                <FontAwesomeIcon icon={faChevronLeft} />
              </Link>
            </div>
          </div>
          <div className="grow text-center">
            <div className="w-full h-12 bg-buddy-bg-2 rounded-xl relative shadow-inner ring-inset ring-buddy-bg-3 ring-1">
              <div className="absolute h-12 flex flex-row items-center">
                <div type="button" className="w-8 ps-4">
                  <FontAwesomeIcon
                    className="text-buddy-text-3"
                    icon={faSearch}
                  />
                </div>
              </div>
              <input
                type="text"
                onSubmit={(e) => alert('submit')}
                onChange={(e) => setSearch(e.target.value)}
                autoComplete="off"
                autoFocus="off"
                className="appearance-none w-full h-full pb-1 bg-transparent text-white text-sm ps-11 pe-2 placeholder:text-sm rounded-xl !pl-10"
                name="search"
                placeholder="Buscador de recetas"
              />
              {search.length > 0 && (
                <div className="flex items-center justify-center absolute w-10 h-12 right-0 top-0">
                  <button
                    type="button"
                    onClick={handleResetSearch}
                    className="flex items-center justify-center w-8 me-2 h-full"
                  >
                    <div className="w-8 h-8 rounded-full bg-buddy-bg-2 flex flex-col justify-center items-center">
                      <FontAwesomeIcon className="text-white" icon={faTimes} />
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-3">
          {author ? (
            <p className="text-buddy-text-1">
              Mostrando recetas de{' '}
              <span className="font-semibold">'{author.name}'</span>
            </p>
          ) : (
            ''
          )}
          {foodCategory ? (
            <p className="text-buddy-text-1">
              Mostrando categoría{' '}
              <span className="font-semibold">'{foodCategory.name}'</span>
            </p>
          ) : (
            ''
          )}

          {noResults && search.length > 0 && (
            <p className="text-buddy-text-1">
              No se encontraron recetas que cumplan con los criterios de
              búsqueda.
            </p>
          )}

          <div className="mx-auto w-full">
            <div className="grid grid-cols-1 gap-3 mt-2">
              {recipes.map((recipe) => {
                return (
                  <Card key={recipe.id} className="bg-buddy-bg-2">
                    <RecipeTile
                      recipe={recipe}
                      key={recipe.id}
                      to={'/recipe?id=' + recipe.id}
                    />
                  </Card>
                );
              })}
            </div>
            <div ref={observerRef} className="py-4 text-center">
              {loading ? 'Cargando más...' : hasMore && ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipesSearch;
