import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import SelectList from './forms/SelectList';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';

const ProfileForm19 = () => {
  const navigate = useNavigate();

  const [imc, setImc] = useState('--');

  const handleClick = (e) => {
    navigate('/profileForm20');
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const data = JSON.parse(localStorage.getItem('register_data'));
    if (
      data != null &&
      data.weight &&
      data.height &&
      data.user_weight_type_id &&
      data.user_height_type_id
    ) {
      var imc = '--';
      if (data.user_weight_type_id == 1 && data.user_height_type_id == 1) {
        // métrico
        imc = Number(data.weight / Math.pow(data.height / 100, 2)).toFixed(1);
      } else {
        // inglés
        imc = Number((data.weight / Math.pow(data.height, 2)) * 703).toFixed(1);
      }
      setImc(imc);
    }
  }, []);

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={'/profileForm18'} replace={true}>
              <div className="text-sm">
                <span>Paso 19</span> <span className="text-primary">de 28</span>
              </div>
            </Toolbar>
            <h2 className="w-full text-white text-xl font-semibold text-center">
              Índice de Masa Corporal (IMC)
            </h2>
            <span className="text-primary text-xl font-semibold text-center">
              (calculado automáticamente si se proporciona peso y estatura en
              las preguntas anteriores)
            </span>
            <div className="my-2"></div>
            <div className="grid grid-cols-2 items-center gap-6 w-full px-12">
              <div className="text-2xl text-buddy-text-2 font-bold bg-buddy-bg-3 rounded-xl p-4 text-center">
                {imc}
              </div>
              <span className="text-2xl text-buddy-text-2 font-bold">IMC</span>
            </div>

            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick}>
                Siguiente
              </PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm19;
