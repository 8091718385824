import React from 'react';
import gracias from '../img/gracias.png';

const Suscripcion = () => {
  return (
    <div className="contenedor">
      <div className="rectangulo centrado h60"></div>
      <div className="rectangulo centrado fondouno round">
        <img className="gracias" src={gracias} alt="Gracias por registrarte" />
      </div>
      <div className="rectangulo centrado round mb20">
        <h1 className="naranja">
          Aquí va el apartado de suscripciones y pagos
        </h1>
        <p>Dentro de nada estará disponible...</p>
      </div>
      <form action="signupgracias">
        <div className="">
          <button className="w100 submit fondonaranja" type="submit">
            Continuar hasta el final
          </button>
        </div>
      </form>
    </div>
  );
};

export default Suscripcion;
