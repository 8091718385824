import React from 'react';
import { useState, useEffect } from 'react';
import RecipeTile from './ui/RecipeTile.js';
import Card from './ui/Card.js';
import User from '../api/services/User.js';
import SmallToggle from './forms/SmallToggle.js';
import Toolbar from './ui/Toolbar.js';

const RecipeList = () => {
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(true);

  const user = JSON.parse(localStorage.getItem('user'));

  const [searchMode, setSearchMode] = useState(1);

  const searchModeOptions = [
    {
      value: 1,
      label: 'Favoritas',
    },
    {
      value: 2,
      label: 'Creadas por mí',
    },
  ];

  useEffect(() => {}, []);

  useEffect(() => {
    const getRecipes = async () => {
      setRecipes([]);
      if (searchMode === 1) {
        try {
          const response = await User.favoriteRecipesList(user.id);
          setRecipes(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching recipes:', error);
          setLoading(false);
        }
      } else {
        try {
          const response = await User.recipeList(user.id);
          setRecipes(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching recipes:', error);
          setLoading(false);
        }
      }
    };

    const to = setTimeout(() => {
      getRecipes();
    }, 500);
    return () => clearTimeout(to);
  }, [searchMode, user.id]);

  const handleOnChangeSearchMode = (value) => {
    setRecipes([]);
    setSearchMode(value);
  };

  return !user?.id || loading ? (
    <div className="flex justify-center items-center h-screen">
      <div className="w-8 h-8 border-4 border-t-white border-r-white border-b-neutral-500 border-l-neutral-500 animate-spin rounded-full" />
    </div>
  ) : (
    <div className="h-full bg-no-repeat bg-cover bg-center">
      <div className="h-full bg-secondary-bg bg-opacity-60 p-6">
        <Toolbar to={-1}>
          <div className="text-md">
            <span>Mis recetas</span>
          </div>
        </Toolbar>

        {user?.role?.id === 5 ? (
          <SmallToggle
            selected={searchMode}
            onChange={handleOnChangeSearchMode}
            options={searchModeOptions}
          />
        ) : (
          ''
        )}

        <div className="mx-auto w-full mt-4">
          <div className="grid grid-cols-1 gap-3 mt-2">
            {recipes.map((recipe) => {
              return (
                <Card className="bg-buddy-bg-2" key={recipe.id}>
                  <RecipeTile
                    showStatus={searchMode === 2}
                    showAuthor={searchMode === 1}
                    to={
                      recipe.user_id === user.id
                        ? '/recipeEdit?id=' + recipe.id
                        : '/recipe?id=' + recipe.id
                    }
                    recipe={recipe}
                    key={recipe.id}
                  />
                </Card>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeList;
