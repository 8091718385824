import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import ApplicationUseMainGoal from '../api/services/ApplicationUseMainGoal';
import SelectList from './forms/SelectList';
import SelectListMultiple from './forms/SelectListMultiple';

const ProfileForm10 = () => {
  const navigate = useNavigate();

  const [mainGoalIds, setMainGoalIds] = useState([]);
  const [mainGoalOptions, setMainGoalOptions] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setMainGoalIds(data.application_use_main_goal_ids ?? []);
    }

    loadMainGoals();
  }, []);

  const handleClick = (e) => {
    if (mainGoalIds.length > 0) {
      const data = JSON.parse(localStorage.getItem('register_data'));
      data.application_use_main_goal_ids = mainGoalIds;
      localStorage.setItem('register_data', JSON.stringify(data));
      navigate('/profileForm12');
    } else {
      alert('Responda a las preguntas no opcionales para continuar.');
    }
  };

  const handleOnItemSelected = (value) => {
    var currentSelection = mainGoalIds.slice();
    if (!currentSelection.includes(value)) {
      currentSelection.push(value);
    } else {
      currentSelection.splice(currentSelection.indexOf(value), 1);
    }
    setMainGoalIds(currentSelection);
  };

  async function loadMainGoals() {
    try {
      const response = await ApplicationUseMainGoal.list();
      var options = [];
      response.data.map((goal) => {
        options.push({
          value: goal.id,
          label: goal.name,
        });
      });
      setMainGoalOptions(options);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={'/profileForm9'} replace={true}>
              <div className="text-sm">
                <span>Paso 6</span> <span className="text-primary">de 7</span>
              </div>
            </Toolbar>
            <h2 className="w-full text-white text-xl font-semibold">
              ¿Cuál es tu objetivo principal al utilizar esta aplicación?
            </h2>
            <SelectListMultiple
              onItemSelected={handleOnItemSelected}
              selected={mainGoalIds}
              options={mainGoalOptions}
            />
            <div className="my-2"></div>
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick}>
                Siguiente
              </PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm10;
