import React from 'react';

const BasicInputField = ({
  label,
  value,
  onChange,
  placeHolder,
  type = 'number',
  ...props
}) => {
  return (
    <div className="flex flex-col gap-4 w-full">
      {label && <label>{label}</label>}
      <input
        className="h-14 bg-input-dark text-white ps-4 border border-transparent rounded-2xl"
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeHolder}
        {...props}
      />
    </div>
  );
};

export default BasicInputField;
