import React, { useState, useEffect } from 'react';
import Toolbar from './ui/Toolbar';
import { useNavigate } from 'react-router-dom';
import FooterToolbar from './ui/FooterToolbar';
import SelectList from './forms/SelectList';
import HealthPreference from '../api/services/HealthPreference';
import PrimaryButton from './ui/PrimaryButton';
import SelectRounded from './forms/SelectRounded';

const HealthPreferencesSpending = () => {
  const navigate = useNavigate();
  const [spendOptions, setSpendOptions] = useState([]);
  const [goalsOptions, setGoalsOptions] = useState([]);
  const [data, setData] = useState({
    application_use_main_goals_id: null,
    practice_sports_or_physical_activity: null,
    monthly_spending_range_id: null,
  });

  const activityOptions = [
    {
      value: 1,
      label: 'Sí, practico deporte/actividad física regularmente.',
    },
    {
      value: 0,
      label: 'No, no practico deporte/actividad física regularmente.',
    },
  ];

  const handleClick = (e) => {
    const user = JSON.parse(localStorage.getItem('user'));
    user.application_use_main_goals_id = data.application_use_main_goals_id;
    user.practice_sports_or_physical_activity =
      data.practice_sports_or_physical_activity;
    user.monthly_spending_range_id = data.monthly_spending_range_id;
    localStorage.setItem('user', JSON.stringify(user));
    navigate('/healthPreferencesPersonalData');
  };

  const fetchMonthlySpendingRanges = async () => {
    try {
      const response = await HealthPreference.getMonthlySpendingRanges();
      const adaptedOptions = response.data.map((spendRange) => {
        return {
          value: spendRange.id,
          label: spendRange.name,
        };
      });
      setSpendOptions(adaptedOptions);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchApplicationUseMainGoals = async () => {
    try {
      const response = await HealthPreference.getApplicationUseMainGoals();
      const adaptedOptions = response.data.map((spendRange) => {
        return {
          value: spendRange.id,
          label: spendRange.name,
        };
      });
      setGoalsOptions(adaptedOptions);
    } catch (error) {
      console.log(error);
    }
  };

  const loadUserData = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setData({
        application_use_main_goals_id:
          user.application_use_main_goals_id ?? null,
        practice_sports_or_physical_activity:
          user.practice_sports_or_physical_activity ?? null,
        monthly_spending_range_id: user.monthly_spending_range_id ?? null,
      });
    }
  };

  useEffect(() => {
    fetchMonthlySpendingRanges();
    fetchApplicationUseMainGoals();
    loadUserData();
  }, []);

  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-center">
      <div className="flex flex-col justify-between min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col bg-red items-center gap-3 mb-6">
          <Toolbar to={'/dietaryPreferences'}>
            <span>Paso 1</span> <span className="text-primary">de 7</span>
          </Toolbar>
          <div className="flex flex-col bg-red items-center gap-6">
            <h2 className="w-full text-white text-xl text-center font-semibold">
              ¿Cuál es tu rango de gasto promedio en compras mensuales?
            </h2>
            <div className="w-[120px] h-[32px]">
              <SelectRounded
                name="measure_id"
                includeBorder={false}
                size="small"
                action={true}
                onChange={(e) => {
                  setData({
                    ...data,
                    monthly_spending_range_id: parseInt(e.target.value),
                  });
                }}
                value={data.monthly_spending_range_id}
                options={spendOptions}
              />
            </div>
            <h3 className="text-white text-center text-xl">
              ¿Cuál es tu objetivo principal al utilizar esta aplicación?
            </h3>
            <SelectList
              buttonClassName="flex flex-row justify-center items-center text-md font-semibold h-[55px]"
              options={goalsOptions}
              selected={data.application_use_main_goals_id}
              onChange={(value) =>
                setData({ ...data, application_use_main_goals_id: value })
              }
            />
            <h3 className="text-white text-center text-xl">
              ¿Practicas algún deporte o actividad física regularmente?
            </h3>
            <SelectList
              buttonClassName="flex flex-row justify-center items-center text-md font-semibold h-[55px]"
              options={activityOptions}
              selected={data.practice_sports_or_physical_activity}
              onChange={(value) =>
                setData({
                  ...data,
                  practice_sports_or_physical_activity: value,
                })
              }
            />
          </div>
        </div>
        <FooterToolbar>
          <PrimaryButton type="button" onClick={handleClick}>
            Siguiente
          </PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default HealthPreferencesSpending;
