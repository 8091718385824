import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { PlusIcon } from '@heroicons/react/24/solid';
import americanExpressImg from '../img/american-express.png';
import visaImg from '../img/visa.png';
import Subscription from '../api/services/Subscription';

const stripePromise = loadStripe(
  'pk_test_51OX2IoErxmByqRHB2Ia0mySCGc3fc9OEip9Uzfcx3kl5FFjmJoZYGU1isHsZljj1sKBTA3tN0ssppw1K2E9y7fwP00Wv6r8T5v',
);

const AddCardForm = ({ onCardAdded }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setLoading(true);
    setError(null);

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) return;

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setError(error.message || 'An error occurred');
      } else {
        const body = new FormData();
        body.append('paymentMethodId', paymentMethod.id);
        const response = await Subscription.addCard(body);
        if (response.error) {
          setError(response.error);
        } else {
          onCardAdded();
        }
      }
    } catch (err) {
      setError('An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4 space-y-4">
      <div className="bg-buddy-bg-2 rounded-xl p-4">
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#ffffff',
                '::placeholder': {
                  color: '#a0aec0',
                },
              },
              invalid: {
                color: '#f56565',
              },
            },
          }}
        />
      </div>
      {error && <div className="text-red-500 text-sm">{error}</div>}
      <button
        type="submit"
        disabled={!stripe || loading}
        style={{ backgroundColor: '#394a53' }}
        className="w-full text-primary rounded-lg py-2 px-4 disabled:opacity-50"
      >
        {loading ? 'Añadiendo...' : 'Añadir tarjeta'}
      </button>
    </form>
  );
};

const PaymentMethods = () => {
  const navigate = useNavigate();
  const [showAddCard, setShowAddCard] = useState(false);
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);

  const getCardImage = (brand) => {
    switch (brand) {
      case 'amex':
        return americanExpressImg;
      case 'visa':
        return visaImg;
      default:
        return null;
    }
  };

  const fetchCards = async () => {
    try {
      const response = await Subscription.listCards();
      if (response) {
        setCards(response);
      } else {
        console.error('Error fetching cards:');
      }
    } catch (error) {
      console.error('Error fetching cards:', error);
    }
  };

  useEffect(() => {
    fetchCards();
  }, []);

  const handleDeleteCard = async (cardToDelete) => {
    try {
      const result = await Subscription.deleteCard(cardToDelete.id);
      if (result.success) {
        setCards(cards.filter((card) => card.id !== cardToDelete.id));
        setSelectedCard(null);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error('Error deleting card:', error);
    }
  };

  const reloadCards = () => {
    fetchCards();
    setShowAddCard(false);
  };

  if (selectedCard) {
    return (
      <div className="relative h-full bg-no-repeat bg-cover bg-center overflow-scroll">
        <div className="h-full flex justify-between flex-col min-h-screen bg-secondary-bg bg-opacity-60 p-6">
          <Toolbar onClick={() => setSelectedCard(null)}>
            Tarjeta acabada en {selectedCard.card.last4}
          </Toolbar>
          <div className="w-full h-full pt-8 flex flex-col items-center">
            <div className="w-48 h-32 mb-8">
              <img
                src={getCardImage(selectedCard.card.brand)}
                alt={selectedCard.card.brand}
                className="w-full h-full object-contain"
              />
            </div>
            <div className="w-full space-y-4">
              <h2 className="w-full text-left text-white text-base font-medium mb-5">
                Datos de la tarjeta
              </h2>
              <div className="bg-buddy-bg-2 p-4 rounded-xl">
                <p className="text-white text-base font-medium">
                  Fecha de caducidad: {selectedCard.card.exp_month}/
                  {selectedCard.card.exp_year}
                </p>
              </div>
              <div className="bg-buddy-bg-2 p-4 rounded-xl">
                <p className="text-white text-base font-medium">
                  Acabada en: {selectedCard.card.last4}
                </p>
              </div>
              <button
                className="w-full text-red-500 mt-8 py-2"
                onClick={() => handleDeleteCard(selectedCard)}
              >
                Eliminar tarjeta
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="relative h-full bg-no-repeat bg-cover bg-center overflow-scroll">
        <div className="h-full flex justify-between flex-col min-h-screen bg-secondary-bg bg-opacity-60 p-6">
          <Toolbar to="/sidebarMenu">Métodos de pago</Toolbar>
          <div className="w-full h-full pt-8">
            <h2 className="w-full text-left text-white text-base font-medium mb-5">
              Tarjetas
            </h2>
            {cards.map((card, index) => (
              <div
                key={index}
                className="w-full flex gap-2 justify-between bg-buddy-bg-2 p-3 rounded-xl py-4 mb-3 cursor-pointer"
                onClick={() => setSelectedCard(card)}
              >
                <div className="px-2 text-start">
                  <img
                    src={getCardImage(card.card.brand)}
                    width={'42px'}
                    alt={card.card.brand}
                  />
                </div>
                <div className="grow flex justify-between px-2 text-start text-left">
                  <span className="text-buddy-text-2 grow-0">
                    ..{card.card.last4}
                  </span>
                  <span className="text-buddy-text-2 grow-0">
                    Fecha de caducidad: {card.card.exp_month}/
                    {card.card.exp_year}
                  </span>
                </div>
              </div>
            ))}
            <button
              onClick={() => setShowAddCard(!showAddCard)}
              style={{ backgroundColor: '#394a53' }}
              className="mt-8 text-primary rounded-lg inline-flex items-center space-x-2 py-1 px-3"
            >
              <PlusIcon className="size-4" /> <span>Añadir tarjeta</span>
            </button>
            {showAddCard && (
              <div className="mt-6">
                <Elements stripe={stripePromise}>
                  <AddCardForm onCardAdded={reloadCards} />
                </Elements>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default PaymentMethods;
