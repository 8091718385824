import React from 'react';
import Toolbar from './ui/Toolbar';
import { Link } from 'react-router-dom';
import {
  ChevronRightIcon,
} from '@heroicons/react/24/solid';
import MilkBottleImg from "../img/milk-bottle.png"
import HealthImg from "../img/health.png"

const DietaryPreferences = () => {
  return (
    <div className="min-h-screen flex flex-col bg-no-repeat bg-cover bg-center">
      <div className="min-h-screen bg-secondary-bg bg-opacity-60 p-6">
        <div className="flex flex-col bg-red items-center gap-3 mb-6">
          <Toolbar to={'/sidebarMenu'}>Preferencias nutricionales</Toolbar>
          <Link className="w-full" to={'/dietaryPreferencesAllergies'}>
            <div className="w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl">
              <img src={MilkBottleImg} alt="pencil" className='w-[24px] h-[24px]' />
              <div className="grow px-2 text-start">
                <span className="text-buddy-text-1 font-semibold grow-0">
                  Restricciones alimentarias
                </span>
              </div>
              <ChevronRightIcon className="size-6 text-buddy-text-3 grow-0" />
            </div>
          </Link>
          <Link className="w-full" to={'/healthPreferencesSpending'}>
            <div className="w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl">
              <img src={HealthImg} alt="pencil" className='w-[24px] h-[24px]' />
              <div className="grow px-2 text-start">
                <span className="text-buddy-text-1 font-semibold grow-0">
                    Perfil Nutricional y Hábitos
                </span>
              </div>
              <ChevronRightIcon className="size-6 text-buddy-text-3 grow-0" />
            </div>
          </Link>
        </div>
        <p className='text-[10px] font-medium text-buddy-text-7 text-center'>
            Los datos que proporcionas nos permiten optimizar tu experiencia en la app mediante inteligencia artificial, 
            ofreciéndote recomendaciones personalizadas y contenidos adaptados a tus necesidades.
        </p>
      </div>
    </div>
  );
};

export default DietaryPreferences;
